<div class="mobile-view-wrapper">
  <img
    src="assets/images/breeze-parkeasy.png"
    alt="breeze logo"
    class="breeze-logo"
  />
  <img
    src="assets/images/mobile-redirect-image.png"
    alt="breeze graphic"
    class="breeze-graphic"
  />
  <p>
    For the best experience, please switch to desktop view. Our website is
    optimised for larger screens.
  </p>
</div>
