import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationStatus } from '../../directives/confirm-proceed.directive';

export interface ConfirmProceedData {
  header?: string;
  message?: string[];
  rejectLabel?: string;
  confirmLabel?: string;
  cancelLabel?: string;
  styleClass?: string;
}

@Component({
  selector: 'app-confirm-proceed',
  templateUrl: './confirm-proceed.component.html',
  styleUrls: ['./confirm-proceed.component.scss'],
})
export class ConfirmProceedComponent implements OnInit {
  header = 'Confirm';
  rejectLabel = 'Reject and proceed';
  confirmLabel = 'Confirm and proceed';
  cancelLabel = 'Cancel';
  message = 'Please confirm, reject or cancel';
  ConfirmationStatus = ConfirmationStatus;
  styleClass: string;

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    const data: ConfirmProceedData = this.config.data || {};
    data.header && (this.header = data.header);
    // data.message && (this.message = data.message);
    data.rejectLabel && (this.rejectLabel = data.rejectLabel);
    data.confirmLabel && (this.confirmLabel = data.confirmLabel);
    data.cancelLabel && (this.cancelLabel = data.cancelLabel);
    data.styleClass && (this.styleClass = data.styleClass);
  }

  confirm() {
    this.ref.close(ConfirmationStatus.CONFIRM_PROCEED);
  }

  reject() {
    this.ref.close(ConfirmationStatus.REJECT_PROCEED);
  }

  cancel() {
    this.ref.close(ConfirmationStatus.CANCEL);
  }
}
