<div class="carpark-entry-wrapper">
  <p-panel
    [toggleable]="true"
    expandIcon="pi pi-angle-right"
    collapseIcon="pi pi-angle-down"
    styleClass="carpark-entry-panel"
  >
    <ng-template pTemplate="header">
      <div class="price-entry-header" [formGroup]="rateFG">
        <p><strong class="select">Chargeable day(s)</strong></p>
        <section class="days-selection">
          <div class="days-selection--container">
            <button
              (click)="onDaySelection(day.key)"
              class="selectable-day"
              [class.selected]="isDaySelected(day.key)"
              *ngFor="let day of days; let i = index"
              [disabled]="isDayDisabled(day.key)"
            >
              <span>{{ day.display }}</span>
            </button>
          </div>
          <div class="error-text" *ngIf="!rateFG.get('day')?.value?.length">
            Select chargeable days
          </div>
        </section>
      </div>
    </ng-template>
    <form [formGroup]="rateFG">
      <section
        class="time-slot"
        [formGroup]="pricing_slot"
        *ngFor="
          let pricing_slot of getFromFGAsArray(rateFG, 'pricing_slots');
          let pIndex = index
        "
      >
        <div class="form-header">
          <div>
            <ng-container *ngIf="pIndex === 0">
              <h3>Parking charge</h3>
              <span>
                Fill in the charging type, rates and duration for the days
                selected above
              </span>
            </ng-container>
          </div>
          <button (click)="deletePricingSlot(pIndex)">
            Delete parking charge
          </button>
        </div>
        <div class="timeslot-blk">
          <div class="row">
            <div class="field-label">Charging type</div>
            <div class="fields">
              <div class="form-field">
                <label for="">How will it be charged?</label>
                <p-dropdown
                  [options]="newChargingType"
                  optionLabel="label"
                  optionValue="value"
                  formControlName="charging_type"
                  placeholder="Select a charging type"
                  styleClass="carpark-rates-dropdown flex-field"
                  (onChange)="
                    handleChargingTypeChange(
                      pIndex,
                      pricing_slot.value.charging_type
                    )
                  "
                ></p-dropdown>
              </div>
            </div>
          </div>
          <ng-container [ngSwitch]="pricing_slot.value.charging_type">
            <ng-container *ngSwitchCase="'subsequent_charge'">
              <div class="row">
                <div class="field-label">Parking rate</div>
                <div class="fields-section">
                  <ng-container
                    *ngFor="
                      let slot_variable of fUtils.getFromFGAsFA(
                        pricing_slot,
                        'slot_variables'
                      );
                      let sIndex = index
                    "
                  >
                    <div
                      class="fields"
                      *ngIf="
                        sIndex <
                        fUtils.getFromFGAsFA(pricing_slot, 'slot_variables')
                          .length -
                          1
                      "
                      [formGroup]="slot_variable"
                    >
                      <div
                        class="form-field"
                        [class.error]="
                          slot_variable?.get('min_rate')?.touched &&
                          slot_variable?.get('min_rate')?.errors
                        "
                      >
                        <label for=""
                          >{{
                            commonUtils.getOrdinalSuffixedNumbers(sIndex + 1)
                          }}
                          block of fixed charging rate
                          <!-- {{ sIndex===0 ? 1 : 2 }} -->
                          <span class="help-popover" *ngIf="sIndex == 0"
                            >?
                            <app-help-popover
                              [helpTextArray]="[
                                'If your carpark rate is charged at $1.20 for first 2 hours, this field should be $1.20.',
                                'Add an additional block of fixed charging rate if you have more fixed rates to fill in.',
                                'Having problems with filling out this section? <a href=/how-to#parking-charges target=_blank class=link-popup> Here’s a quick guide </a>'
                              ]"
                            >
                            </app-help-popover>
                          </span>
                        </label>
                        <span data-prefix="$" class="include-prefix">
                          <input
                            type="number"
                            pInputText
                            formControlName="min_rate"
                            (keypress)="
                              commonUtils.enablePositiveDecimalOnKeyup($event)
                            "
                            placeholder="0.00"
                          />
                        </span>
                        <span class="error-text">Enter minimum rate</span>
                      </div>
                      <div
                        class="form-field"
                        [class.error]="
                          slot_variable?.get('min_duration')?.touched &&
                          slot_variable?.get('min_duration')?.errors
                        "
                      >
                        <label for=""
                          >Duration of charge
                          <span class="help-popover" *ngIf="sIndex == 0"
                            >?
                            <app-help-popover
                              [helpTextArray]="[
                                'If your carpark rate is charged at $1.20 for first 2 hours, this field should be 120min.'
                              ]"
                            >
                            </app-help-popover>
                          </span>
                        </label>
                        <span
                          class="include-suffix include-prefix"
                          data-suffix="min"
                          data-prefix="Per"
                          [class.sub-slot-duration]="sIndex > 0"
                        >
                          <input
                            type="number"
                            pInputText
                            placeholder="0"
                            formControlName="min_duration"
                            (keypress)="
                              commonUtils.enablePositiveDecimalOnKeyup($event)
                            "
                            style="padding-left: 40px"
                          />
                          <button
                            class="sub-slot-delete-btn"
                            *ngIf="sIndex > 0"
                            (click)="deleteSubSlot(pricing_slot, sIndex)"
                          >
                            <i class="pi pi-times"></i>
                          </button>
                        </span>
                        <span class="error-text">Enter minimum duration</span>
                      </div>
                    </div>
                  </ng-container>
                  <div class="fields">
                    <button
                      class="subsequent-slot-plus"
                      (click)="addSubSlot(pricing_slot)"
                    >
                      <span>+</span> Add additional block of fixed charging rate
                    </button>
                  </div>
                  <div
                    class="fields"
                    [formGroup]="
                      fUtils.getFromFGAsFA(pricing_slot, 'slot_variables')[
                        fUtils.getFromFGAsFA(pricing_slot, 'slot_variables')
                          .length - 1
                      ]
                    "
                  >
                    <div
                      class="form-field"
                      [class.error]="
                        fUtils
                          .getFromFGAsFA(pricing_slot, 'slot_variables')
                          [
                            fUtils.getFromFGAsFA(pricing_slot, 'slot_variables')
                              .length - 1
                          ].get('min_rate')?.touched &&
                        fUtils
                          .getFromFGAsFA(pricing_slot, 'slot_variables')
                          [
                            fUtils.getFromFGAsFA(pricing_slot, 'slot_variables')
                              .length - 1
                          ]?.get('min_rate')?.errors
                      "
                    >
                      <label for="">Subsequent charges or part thereof</label>
                      <span class="include-prefix" data-prefix="$">
                        <input
                          type="number"
                          pInputText
                          placeholder="0.00"
                          formControlName="min_rate"
                          (keypress)="
                            commonUtils.enablePositiveDecimalOnKeyup($event)
                          "
                        />
                      </span>
                      <span class="error-text">Enter subsequent charges</span>
                    </div>
                    <div
                      class="form-field"
                      [class.error]="
                        fUtils
                          .getFromFGAsFA(pricing_slot, 'slot_variables')
                          [
                            fUtils.getFromFGAsFA(pricing_slot, 'slot_variables')
                              .length - 1
                          ].get('min_duration')?.touched &&
                        fUtils
                          .getFromFGAsFA(pricing_slot, 'slot_variables')
                          [
                            fUtils.getFromFGAsFA(pricing_slot, 'slot_variables')
                              .length - 1
                          ]?.get('min_duration')?.errors
                      "
                    >
                      <label for="">Duration of charge</label>
                      <span
                        class="include-suffix include-prefix"
                        data-suffix="min"
                        data-prefix="Per"
                      >
                        <input
                          type="number"
                          pInputText
                          placeholder="0"
                          formControlName="min_duration"
                          (keypress)="
                            commonUtils.enablePositiveDecimalOnKeyup($event)
                          "
                          style="padding-left: 40px"
                        />
                      </span>
                      <span class="error-text"
                        >Enter duration of subsequent charge</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'minute'" [formGroup]="pricing_slot">
              <div class="row">
                <div class="field-label">Parking rate</div>
                <div class="fields">
                  <div
                    class="form-field"
                    [class.error]="
                      pricing_slot?.get('display_charge_rate')?.touched &&
                      pricing_slot?.get('display_charge_rate')?.errors
                    "
                  >
                    <label for="">Charging rate</label>
                    <span data-prefix="$" class="include-prefix">
                      <input
                        type="number"
                        pInputText
                        placeholder="0.00"
                        formControlName="display_charge_rate"
                        (keypress)="
                          commonUtils.enablePositiveDecimalOnKeyup($event)
                        "
                      />
                      <span class="error-text">
                        Enter display charge rate
                      </span>
                    </span>
                  </div>
                  <div
                    class="form-field"
                    [class.error]="
                      pricing_slot?.get('display_charge_rate')?.touched &&
                      pricing_slot?.get('display_charge_rate')?.errors
                    "
                  >
                    <label for="">Duration of charge</label>
                    <span
                      class="include-suffix include-prefix"
                      data-suffix="min"
                      data-prefix="Every"
                    >
                      <input
                        type="number"
                        pInputText
                        placeholder="0"
                        formControlName="display_charge_duration"
                        (keypress)="
                          commonUtils.enablePositiveDecimalOnKeyup($event)
                        "
                        style="padding-left: '60px'"
                      /> </span
                    ><span class="error-text">
                      Enter display charge duration
                    </span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="field-label">
                  Per minute charge
                  <span class="help-popover"
                    >?
                    <app-help-popover
                      [helpTextArray]="[
                        'If Yes is selected, the carpark charge will be charged by the minute.',
                        'If No is selected, the carpark charge during that time period is based on the duration of charge filled above.',
                        'e.g. if duration of charge is 60 minutes, even if user parks part thereof, the full charging rate is charged.'
                      ]"
                    >
                    </app-help-popover>
                  </span>
                </div>
                <div class="fields" style="align-items: flex-start">
                  <div class="form-field" style="flex-direction: row">
                    <p-radioButton
                      name="{{ tabName + '-per_minute_enabled' }}"
                      [value]="true"
                      label="Yes"
                      [formControl]="
                        fUtils.getFromFGAsFC(pricing_slot, 'per_minute_enabled')
                      "
                      [ngStyle]="{
                        'margin-right': '30px'
                      }"
                    ></p-radioButton>
                    <p-radioButton
                      name="{{ tabName + '-per_minute_enabled' }}"
                      [value]="false"
                      label="No"
                      [formControl]="
                        fUtils.getFromFGAsFC(pricing_slot, 'per_minute_enabled')
                      "
                      [ngStyle]="{
                        'margin-right': '30px'
                      }"
                    ></p-radioButton>
                  </div>
                  <div class="form-field">
                    <ng-container
                      *ngIf="
                        fUtils.getFromFGAsFC(pricing_slot, 'per_minute_enabled')
                          ?.value === true
                      "
                    >
                      <label for="">Rate per minute</label>
                      <span data-prefix="$" class="include-prefix">
                        <input
                          type="number"
                          pInputText
                          formControlName="min_rate"
                          (keypress)="
                            commonUtils.enablePositiveDecimalOnKeyup($event)
                          "
                          placeholder="0.00"
                        />
                      </span>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'fixed'">
              <div class="row">
                <div class="field-label">Parking rate</div>
                <div class="fields">
                  <div
                    class="form-field"
                    [class.error]="
                      fUtils.getFromFGAsFC(pricing_slot, 'entry_price')
                        .touched &&
                      fUtils.getFromFGAsFC(pricing_slot, 'entry_price').errors
                    "
                  >
                    <label for="">Charging rate per entry</label>
                    <span data-prefix="$" class="include-prefix">
                      <input
                        type="text"
                        pInputText
                        formControlName="entry_price"
                        placeholder="0.00"
                        (keypress)="
                          commonUtils.enablePositiveDecimalOnKeyup($event)
                        "
                      />
                    </span>
                    <span class="error-text"> Enter per entry rate </span>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'free'"></ng-container>
          </ng-container>
          <div
            class="row"
            *ngIf="pricing_slot.value.charging_type !== ChargingType.FREE"
          >
            <div class="field-label">Grace period</div>
            <div class="fields-section">
              <div class="fields">
                <div class="form-field">
                  <label for="">What is the duration of grace period?</label>
                  <p-dropdown
                    [options]="gracePeriodOptions"
                    optionLabel="label"
                    optionValue="value"
                    formControlName="grace_period_type"
                    placeholder="Select a charging type"
                    styleClass="carpark-graceperiod-dropdown flex-field"
                    (onChange)="handleGracePeriodChange(pricing_slot)"
                  ></p-dropdown>
                </div>
              </div>
              <div
                class="fields"
                *ngIf="pricing_slot.value.grace_period_type === 'other'"
              >
                <div
                  class="form-field"
                  [class.error]="
                    pricing_slot.get('grace_period')?.touched &&
                    pricing_slot.get('grace_period')?.errors
                  "
                >
                  <label for="">Grace period duration</label>
                  <span class="include-suffix" data-suffix="min">
                    <input
                      type="number"
                      formControlName="grace_period"
                      (keypress)="
                        commonUtils.enablePositiveDecimalOnKeyup($event)
                      "
                      pInputText
                      class="flex-field"
                    />
                  </span>
                  <span class="error-text">Enter grace period duration</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="field-label">
              Effective duration
              <span class="help-popover"
                >?
                <app-help-popover
                  [helpTextArray]="[
                    'If a 24-hour rate applies, for example, 7am to next day 7am, you may fill in 07:00 for Start time and 07:00 for End time.'
                  ]"
                >
                </app-help-popover>
              </span>
            </div>
            <div class="fields">
              <div
                class="form-field"
                [class.error]="
                  pricing_slot.get('start_time')?.touched &&
                  pricing_slot.get('start_time')?.errors
                "
              >
                <label for="">Start time</label>
                <div style="position: relative">
                  <p-calendar
                    formControlName="start_time"
                    [timeOnly]="true"
                    inputId="timeonly"
                    [readonlyInput]="true"
                    [dataType]="'string'"
                    styleClass="carpark-time-selection flex-field"
                    [inputStyleClass]="
                      pricing_slot.get('start_time')?.touched &&
                      pricing_slot.get('start_time')?.errors
                        ? 'time-error'
                        : ''
                    "
                    [placeholder]="'Select time'"
                    [defaultDate]="defaultTime"
                  ></p-calendar>
                  <i class="icon icon-clock"
                    ><img src="assets/images/icons/Clock.svg"
                  /></i>
                </div>
                <span class="error-text">Enter start time</span>
              </div>
              <div
                class="form-field"
                [class.error]="
                  pricing_slot.get('end_time')?.touched &&
                  pricing_slot.get('end_time')?.errors
                "
              >
                <label for="">End time</label>
                <div style="position: relative">
                  <p-calendar
                    formControlName="end_time"
                    [timeOnly]="true"
                    [readonlyInput]="true"
                    inputId="timeonly"
                    [dataType]="'string'"
                    [placeholder]="'Select time'"
                    [inputStyleClass]="
                      pricing_slot.get('start_time')?.touched &&
                      pricing_slot.get('start_time')?.errors
                        ? 'time-error'
                        : ''
                    "
                    styleClass="carpark-time-selection flex-field"
                    [defaultDate]="defaultTime"
                  ></p-calendar>
                  <i class="icon icon-clock"
                    ><img src="assets/images/icons/Clock.svg"
                  /></i>
                </div>
                <span class="error-text">Enter start time</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="field-label remarks">
              <span class="title">Remarks</span>
              <span class="optional">(optional)</span>
            </div>
            <div class="fields">
              <div class="form-field">
                <textarea
                  [rows]="3"
                  [cols]="30"
                  placeholder="Add details"
                  pInputTextarea
                  [autoResize]="false"
                  formControlName="remarks"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
    <div class="btnspbro">
      <button class="add-another-button" (click)="addTimeSlot()">
        + Add another parking charge
      </button>
    </div>
    <div class="capped-charge-section" [formGroup]="cappedRateFG">
      <div class="row">
        <div class="field-label">
          Capped charge type
          <span class="help-popover"
            >?
            <app-help-popover
              [helpTextArray]="[
                '<strong>Per day</strong> capped charge is the maximum amount you`ll pay for parking until the end of that day.',
                '<strong>24-hour</strong> capped charge is the maximum amount you`ll pay for parking within a 24-hour window'
              ]"
            >
            </app-help-popover>
          </span>
        </div>
        <div class="fields">
          <div class="form-field">
            <label>How will they be charged?</label>
            <p-dropdown
              [options]="cappedChargeTypes"
              optionLabel="label"
              optionValue="value"
              formControlName="type"
              placeholder="Select a capped charge type"
              styleClass="flex-field"
            ></p-dropdown>
          </div>
        </div>
      </div>
      <div
        class="row"
        *ngIf="this.cappedRateFG?.value.type === 'timeslot'"
        [formGroup]="
          fUtils.getAsFG(fUtils.getAsFA(this.cappedRateFG?.get('slots')!).at(0))
        "
      >
        <div class="field-label">Charge duration</div>
        <div class="fields">
          <div
            class="form-field"
            [class.error]="
              fUtils
                .getAsFG(fUtils.getAsFA(this.cappedRateFG?.get('slots')!).at(0))
                ?.get('start')?.touched &&
              fUtils
                .getAsFG(fUtils.getAsFA(this.cappedRateFG?.get('slots')!).at(0))
                ?.get('start')?.errors
            "
          >
            <label>Start time</label>
            <p-calendar
              formControlName="start"
              [timeOnly]="true"
              inputId="timeonly"
              [readonlyInput]="true"
              styleClass="flex-field"
              [dataType]="'string'"
              placeholder="Select time"
              [defaultDate]="defaultTime"
            ></p-calendar>
            <span class="error-text">Enter start time</span>
          </div>
          <div
            class="form-field"
            [class.error]="
              fUtils
                .getAsFG(fUtils.getAsFA(this.cappedRateFG?.get('slots')!).at(0))
                ?.get('end')?.touched &&
              fUtils
                .getAsFG(fUtils.getAsFA(this.cappedRateFG?.get('slots')!).at(0))
                ?.get('end')?.errors
            "
          >
            <label>End time</label>
            <p-calendar
              formControlName="end"
              [timeOnly]="true"
              inputId="timeonly"
              [readonlyInput]="true"
              styleClass="flex-field"
              [dataType]="'string'"
              placeholder="Select time"
              [defaultDate]="defaultTime"
            ></p-calendar>
            <span class="error-text">Enter end time</span>
          </div>
        </div>
      </div>
      <div
        class="row"
        *ngIf="this.cappedRateFG?.value.type !== 'none'"
        [formGroup]="
          fUtils.getAsFG(fUtils.getAsFA(this.cappedRateFG?.get('slots')!).at(0))
        "
      >
        <div class="field-label">Capped charge rate</div>
        <div class="fields">
          <div
            class="form-field"
            [class.error]="
              fUtils
                .getAsFG(fUtils.getAsFA(this.cappedRateFG?.get('slots')!).at(0))
                ?.get('charge')?.touched &&
              fUtils
                .getAsFG(fUtils.getAsFA(this.cappedRateFG?.get('slots')!).at(0))
                ?.get('charge')?.errors
            "
          >
            <label
              >Capped charge based on
              {{ getCappedChargeTypeDisplayLabel() }}</label
            >
            <span data-prefix="$" class="include-prefix">
              <input
                type="number"
                pInputText
                formControlName="charge"
                (keypress)="commonUtils.enablePositiveDecimalOnKeyup($event)"
              />
              <span class="error-text"
                >Enter capped charge based on
                {{ getCappedChargeTypeDisplayLabel() }}</span
              >
            </span>
          </div>
        </div>
      </div>
    </div>
  </p-panel>
</div>
