import { Component, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  CustomToastbarService,
} from './custom-toastbar.service';

@Component({
  selector: 'app-custom-toastbar',
  templateUrl: './custom-toastbar.component.html',
  styleUrls: ['./custom-toastbar.component.scss'],
})
export class CustomToastBarComponent implements OnInit {
  subscription: Subscription;
  @Input('isShow')
  isShow = false;
  @Input('content')
  content = '';

  constructor(private toastService: CustomToastbarService) {
    this.subscription = this.toastService.requireConfirmation$.subscribe(
      this.initConfirmValues.bind(this)
    );
  }

  initConfirmValues() {
    this.isShow = true;
    setTimeout(() => {
      this.isShow = false;
    }, 5000);
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onClose() {
    this.isShow= false
  }
}
