import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { FormUtilsService } from '../../../services/form-utils.service';

export interface CustomMultiSelectOption {
  label: string;
  value: string;
  type?: string;
}

@Component({
  selector: 'app-custom-multiselect',
  templateUrl: './custom-multiselect.component.html',
  styleUrls: ['./custom-multiselect.component.scss'],
})
export class CustomMultiselectComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input('showAll') showAll = true;
  @Input('options') options: CustomMultiSelectOption[] = [];
  @Input('multiselectName') multiselectName = 'customMultiselect';
  @Input('multiSelectFA') multiSelectFA: FormArray;
  @Input('closeOutsideSelector') closeOutsideSelector =
    '.custom-multiselect-container';
  @Output() close = new EventEmitter();
  eventListeners: EventListenerOrEventListenerObject[];
  clickUnListener: () => void;

  showAllFC = new FormControl(false);
  constructor(
    private fb: FormBuilder,
    public fu: FormUtilsService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    if (!this.multiSelectFA.controls.length)
      this.options.forEach((option) => {
        this.multiSelectFA.push(this.fb.control(false), { emitEvent: false });
      });

    this.showAllFC.valueChanges.subscribe(this.handleShowAllChange.bind(this));
    this.multiSelectFA.valueChanges.subscribe(
      this.handleMultiSelectFAChange.bind(this)
    );
  }

  handleMultiSelectFAChange(selectStatuses: boolean[]) {
    const isAllSelected = selectStatuses.every((status) => status);
    this.showAllFC.setValue(isAllSelected, {
      emitEvent: false,
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.clickUnListener = this.renderer.listen(
        document,
        'click',
        this.closeOnOutsideClick.bind(this)
      );
    });
  }

  closeOnOutsideClick(event: MouseEvent) {
    const node: any = event.target;
    const isInside = !!node.closest(this.closeOutsideSelector);
    if (!isInside) this.close.emit();
  }

  ngOnDestroy() {
    this.clickUnListener();
  }

  handleShowAllChange(showAll: boolean) {
    this.multiSelectFA.controls.forEach((control) => control.setValue(showAll));
  }
}
