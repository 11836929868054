<div class="revision-message">
  <span class="name-section">
    <span class="initial">{{
      reviewMessage.createdByName?.slice(0, 1) || "A"
    }}</span>
    <span class="name-text">{{ reviewMessage.createdByName }}</span>
    <i
      class="pi pi-check-circle"
      style="font-size: 1rem"
      (click)="toggleReviewMessageStatus(reviewMessage.reviewState)"
      *ngIf="accountService.isAdminUser && !isStatic"
      [class.checked]="reviewMessage.reviewState === 'approved'"
    >
      <app-help-popover
        [helpTextArray]="[
          'Mark as ' +
            (reviewMessage.reviewState === 'approved'
              ? 'unresolved'
              : 'resolved')
        ]"
        [displayType]="'title-display'"
      >
      </app-help-popover>
    </i>
  </span>
  <span class="message-text">
    {{ reviewMessage.message }}
  </span>
  <span class="date">
    {{ reviewMessage.createdAt | date : "dd/MM/yyyy HH:mm" }}
  </span>
</div>
