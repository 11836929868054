import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService } from 'primeng/dynamicdialog';
import { CharacterCountComponent } from './components/character-count/character-count.component';
import { ConfirmImageComponent } from './components/confirm-image/confirm-image.component';
import { ConfirmPopupComponent } from './components/confirm-popup/confirm-popup.component';
import { ConfirmProceedComponent } from './components/confirm-proceed/confirm-proceed.component';
import { CustomCheckboxComponent } from './components/custom-checkbox/custom-checkbox.component';
import { CustomConfirmComponent } from './components/custom-confirm/custom-confirm.component';
import { CustomMultiselectComponent } from './components/custom-multiselect/custom-multiselect.component';
import { CustomToastBarComponent } from './components/custom-toastbar/custom-toastbar.component';
import { HelpPopoverComponent } from './components/help-popover/help-popover.component';
import { LoginPopupComponent } from './components/login-popup/login-popup.component';
import { RevisionMessagePopupComponent } from './components/revision-message-popup/revision-message-popup.component';
import { RevisionMessageComponent } from './components/revision-message/revision-message.component';
import { RevisionMessagesPopupComponent } from './components/revision-messages-popup/revision-messages-popup.component';
import { ToastComponent } from './components/toast/toast.component';
import { TableHeaderWrapperComponent } from './components/ui/table-header-wrapper/table-header-wrapper.component';
import { TableNocontentComponent } from './components/ui/table-nocontent/table-nocontent.component';
import { TableRowWrapperComponent } from './components/ui/table-row-wrapper/table-row-wrapper.component';
import { ConfirmProceedDirective } from './directives/confirm-proceed.directive';

@NgModule({
  declarations: [
    ConfirmProceedDirective,
    ConfirmProceedComponent,
    LoginPopupComponent,
    ConfirmPopupComponent,
    ToastComponent,
    CustomToastBarComponent,
    ConfirmImageComponent,
    RevisionMessagesPopupComponent,
    RevisionMessageComponent,
    RevisionMessagePopupComponent,
    HelpPopoverComponent,
    CustomConfirmComponent,
    CharacterCountComponent,
    TableHeaderWrapperComponent,
    TableRowWrapperComponent,
    CustomMultiselectComponent,
    TableNocontentComponent,
    CustomCheckboxComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    DialogModule,
    ConfirmDialogModule,
    CalendarModule,
    DropdownModule,
    RouterModule,
    ReactiveFormsModule,
  ],
  exports: [
    ConfirmProceedDirective,
    ConfirmProceedComponent,
    ConfirmPopupComponent,
    ToastComponent,
    CustomToastBarComponent,
    ConfirmImageComponent,
    RevisionMessagesPopupComponent,
    RevisionMessageComponent,
    RevisionMessagePopupComponent,
    HelpPopoverComponent,
    CustomConfirmComponent,
    CharacterCountComponent,
    TableHeaderWrapperComponent,
    TableRowWrapperComponent,
    CustomMultiselectComponent,
    TableNocontentComponent,
    CustomCheckboxComponent,
  ],
  providers: [DialogService],
  // note: entryComponents is deprecated with Ivy, even though its shown in
  // primeng documentation for dynamic dialog
  // entryComponents: [
  //   ConfirmProceedComponent
  // ]
})
export class SharedModule {}
