import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormUtilsService } from '../../../../../services/form-utils.service';
import { SeasonalDetails } from '../../../carpark.model';

export const SeasonParkingDurationOptions = [
  { display: 'Monthly', value: 'M' },
  { display: 'Quarterly', value: 'Q' },
  { display: 'Bi-annually', value: 'H' },
  { display: 'Annually', value: 'Y' },
  { display: 'Others', value: 'other' },
];

@Component({
  selector: 'app-season-parking-panel',
  templateUrl: './season-parking-panel.component.html',
  styleUrls: ['./season-parking-panel.component.scss'],
})
export class SeasonParkingPanelComponent implements OnInit {
  @Input('seasonalDetailsFG') detailsFG: FormGroup;
  @Input('id') id: string = '';
  durationOptions = SeasonParkingDurationOptions;
  constructor(public fUtils: FormUtilsService) {}

  ngOnInit(): void {
    /* PrimeNg issue with radio button groups;
    appling the solution mentioned in github issue: 
    https://github.com/primefaces/primeng/issues/9162#issuecomment-686370453
    */
    const isEnabledFC = this.detailsFG?.get('isEnabled') as FormControl;
    if (isEnabledFC)
      isEnabledFC.valueChanges.subscribe((e) => {
        isEnabledFC.setValue(e, { emitEvent: false });
      });

    if (isEnabledFC.value) {
      this.fUtils.toggleValidation(this.detailsFG.get('charges'), true);
    } else {
      this.fUtils.toggleValidation(this.detailsFG.get('charges'), false);
    }

    const durationUnitFC = this.detailsFG?.get('unit') as FormControl;

    durationUnitFC.valueChanges.subscribe(
      (durationUnit: SeasonalDetails['unit']) => {
        if (durationUnit === 'other') {
          this.fUtils.toggleValidation(
            this.detailsFG.get('durationDetails'),
            true
          );
        } else {
          this.fUtils.toggleValidation(
            this.detailsFG.get('durationDetails'),
            false
          );
        }
      }
    );

    isEnabledFC.valueChanges.subscribe((isEnabled) => {
      if (!isEnabled) {
        this.fUtils.toggleValidation(this.detailsFG.get('charges'), false);
        this.fUtils.toggleValidation(
          this.detailsFG.get('durationDetails'),
          false
        );
      } else {
        this.fUtils.toggleValidation(this.detailsFG.get('charges'), true);
        this.detailsFG.patchValue({
          duration: 1,
          unit: 'M',
          charges: '',
          durationDetails: '',
          remarks: '',
        });
        this.detailsFG.markAsUntouched();
      }
    });
  }
}
