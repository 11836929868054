<div class="login-rft">
  <h2>Sign in</h2>
  <p>Token refresh failed. Please sign in again to continue:</p>
  <form [formGroup]="loginForm" (ngSubmit)="login($event)">
    <p *ngIf="loginErrors?.invalidCredentials">Invalid password. Try again.</p>
    <div class="logininput">
      <input type="email" formControlName="email" placeholder="Email" />
      <img src="assets/images/envelop.svg" alt="" />
    </div>
    <div class="logininput">
      <input
        [type]="isPasswordVisible ? 'text' : 'password'"
        formControlName="password"
        placeholder="Password"
      />
      <img
        [src]="
          isPasswordVisible
            ? 'assets/images/eye-cross.svg'
            : 'assets/images/eye.svg'
        "
        alt=""
        (click)="isPasswordVisible = !isPasswordVisible"
      />
    </div>
    <div class="actions">
      <button type="button" (click)="cancel()">Cancel</button>
      <button type="submit">Login</button>
    </div>
    <p class="repassword">
      <a
        (click)="ref.close(false)"
        [routerLink]="['/account', 'forgot-password', 'email']"
      >
        I don’t remember the password
      </a>
    </p>
  </form>
</div>
