<section class="carpark-preview-web-wrapper">
  <div class="perviewsave">
    <button
      class="all-rev-msg-btn"
      *ngIf="accountService.isAdminUser"
      (click)="toggleAllRevisionMessagesPopup()"
    >
      <img src="../../../../../assets/images/icons/revision-message-icon.png" />
      <span>All revision messages</span>
    </button>
  </div>
  <div class="header-block">
    <h2>
      {{ carparkDetails?.name }}
      <div
        class="carpark-status"
        [ngClass]="carparkDetails?.publishStatus || 'imported'"
        [ngStyle]="{
          color: cpSrv.getCarparkStatusAttributes(carparkDetails?.publishStatus)
            ?.color,
          'background-color': cpSrv.getCarparkStatusAttributes(
            carparkDetails?.publishStatus
          )?.backgroundColor
        }"
      >
        {{
          cpSrv.getCarparkStatusAttributes(carparkDetails?.publishStatus)
            ?.displayText
        }}
      </div>
      <!-- <div class="closure-status" *ngIf="carparkDetails?.closureStatus">
        {{ cpSrv.getCarparkClosureStatusText(carparkDetails?.closureStatus) }}
      </div>
      <button
        class="prkesy-theme cp-optn-btn"
        (click)="showClosurePeriodPopup = true"
        *ngIf="!isPermanentlyClosed"
      >
        <ng-container *ngIf="!carparkDetails?.closureStatus">
          <span>Carpark operation</span>
          <strong class="help-popover"
            >i
            <app-help-popover
              [helpTextArray]="['Mark as temporarily or permanently closed']"
            >
            </app-help-popover
          ></strong>
        </ng-container>
        <ng-container *ngIf="carparkDetails?.closureStatus">
          <span>Edit closure period</span>
        </ng-container>
      </button> -->
    </h2>
    <p class="carpark-id">Carpark ID: {{ carparkDetails?.id }}</p>
    <p
      class="info-text"
      *ngIf="
        carparkDetails?.liveInBreeze &&
        carparkDetails?.publishStatus === 'verifying'
      "
    >
      <i class="pi pi-info-circle"></i>
      <span
        >This carpark is currently under review by the administrator. The
        previously approved carpark information will still be shown in Breeze
        app.</span
      >
    </p>
  </div>
  <section class="main-content">
    <div class="preview-block-main">
      <div class="preview-bl-lft">
        <section
          class="preview-section"
          *ngIf="accountService.isAdminUser && organisationName"
        >
          <div class="header">
            <h3>Organisation Name</h3>
          </div>
          <div class="content">
            <p>{{ organisationName }}</p>
          </div>
        </section>
        <section
          class="preview-section"
          *ngIf="accountService.isAdminUser && operatorName"
        >
          <div class="header">
            <h3>Carpark operator name</h3>
          </div>
          <div class="content">
            <p>{{ operatorName }}</p>
          </div>
        </section>
        <section class="preview-section">
          <div class="header">
            <h3>Address</h3>
            <span
              (click)="edit('location')"
              class="edit-link"
              *ngIf="!isPermanentlyClosed"
              >Edit</span
            >
          </div>
          <div class="content">
            <p>
              <span>{{ carparkDetails?.address }} </span>
              <span class="latlong"
                >(Lat: {{ carparkDetails?.lat | number : "1.4-4" }}; Long:
                {{ carparkDetails?.long | number : "1.4-4" }})</span
              >
            </p>
            <div class="address-map" id="ezpark-location-map">Map here</div>
          </div>
        </section>

        <section class="preview-section">
          <div class="header">
            <h3>Location Pins</h3>
            <span
              (click)="edit('entrances')"
              class="edit-link"
              *ngIf="!isPermanentlyClosed"
              >Edit</span
            >
            <button
              class="revision-message-button"
              (click)="edit('entrances')"
              *ngIf="
                accountService.isAdminUser ||
                (!accountService.isAdminUser &&
                  areReviewsPendingIn(groupedRevisionMessages.locationpin))
              "
            >
              <img
                src="../../../../../assets/images/icons/{{
                  accountService.isAdminUser
                    ? 'revision-message-icon.png'
                    : 'comment-text.svg'
                }}"
              />
            </button>
          </div>
          <div
            class="content"
            *ngFor="let marker of activeMarkers; let i = index"
          >
            <p class="title">{{ marker.displayValue }}</p>
            <p class="description-row">
              <span class="description">{{ marker.description }} </span>
              <span class="latlong"
                >(Lat: {{ marker.lat | number : "1.4-4" }}; Long:
                {{ marker.lng | number : "1.4-4" }})</span
              >
            </p>
          </div>
          <div class="entrace-map" id="ezpark-entrance-map"></div>
        </section>
      </div>
      <div class="preview-bl-rft">
        <section class="preview-section prices">
          <div class="header" style="margin-bottom: 30px">
            <h3 class="preview-header">Parking Charges</h3>
            <span
              (click)="edit('prices')"
              class="edit-link"
              *ngIf="!isPriceEditAndPreviewDisabled && !isPermanentlyClosed"
              >Edit</span
            >
            <ng-container *ngIf="!isPriceEditAndPreviewDisabled">
              <button
                class="revision-message-button"
                (click)="edit('prices')"
                *ngIf="
                  accountService.isAdminUser ||
                  (!accountService.isAdminUser &&
                    areReviewsPendinForChargesSection())
                "
              >
                <img
                  src="../../../../../assets/images/icons/{{
                    accountService.isAdminUser
                      ? 'revision-message-icon.png'
                      : 'comment-text.svg'
                  }}"
                />
              </button>
            </ng-container>
          </div>
          <ng-container *ngIf="!isPriceEditAndPreviewDisabled">
            <p class="opentopublic-info">
              <span>The prices are inclusive of GST</span
              ><span>{{ isGSTIncluded?.value === true ? "Yes" : "No" }}</span>
            </p>
            <p class="opentopublic-info">
              <span>Carpark opens to public</span
              ><span>{{ isOpenToPublic?.value === true ? "Yes" : "No" }}</span>
            </p>
            <app-carpark-price-preview
              *ngIf="combinedRatesDetails"
              [ratesDetails]="combinedRatesDetails"
              [isOpenToPublic]="isOpenToPublic"
              [tabIndex]="pricePreviewTabIndex"
            ></app-carpark-price-preview>
          </ng-container>
          <ng-container *ngIf="isPriceEditAndPreviewDisabled">
            <p>
              Price edit is disabled for Partial Season Parking or Season
              Parking Carparks
            </p>
          </ng-container>
        </section>
      </div>
    </div>

    <!---preview sec 2-->

    <section class="preview-section features">
      <div class="header">
        <h3>Amenities</h3>
        <span
          (click)="edit('features')"
          class="edit-link"
          *ngIf="!isPermanentlyClosed"
          >Edit</span
        >
      </div>
      <app-features-sidebar
        *ngIf="isLoaded && features"
        [features]="features"
        [gridDisplay]="true"
      ></app-features-sidebar>
    </section>
    <section class="preview-section images">
      <div class="header">
        <h3>Carpark Images</h3>
        <span
          (click)="edit('features')"
          class="edit-link"
          *ngIf="!isPermanentlyClosed"
          >Edit</span
        >
      </div>
      <div class="content">
        <h4
          *ngIf="
            uploadedFileDetails?.entrance?.length &&
            uploadedFileDetails?.exit?.length &&
            uploadedFileDetails?.pickup?.length &&
            uploadedFileDetails?.amenities?.length &&
            uploadedFileDetails?.other?.length
          "
        >
          No Images uploaded Yet
        </h4>
        <app-uploaded-files-preview
          *ngIf="uploadedFileDetails"
          [uploadedFileDetails]="uploadedFileDetails"
          [publishStatus]="publishStatus"
        >
        </app-uploaded-files-preview>
      </div>
    </section>
  </section>
  <div class="previewbtn-blk" *ngIf="!isPriceEditAndPreviewDisabled">
    <button class="preview-btn" (click)="showPreview()">
      Breeze App Preview
    </button>
  </div>

  <section class="footerbtnbkl">
    <button (click)="goBack()" class="greybtn" *ngIf="isCreateMode">
      Back
    </button>
    <div
      class="box-btn-save"
      *ngIf="!accountService.isAdminUser && isCreateMode"
    >
      <button
        type="button"
        class="save-exit-btn"
        *ngIf="carparkDetails?.publishStatus"
        appConfirmProceed
        (confirm)="cpSrv.handleSaveExitClick($event)"
        [confirmProceedOptions]="cpSrv.SaveExitConfirmOptions"
        [confirmationTriggerOn]="false"
      >
        Save &amp; Exit
      </button>
      <button
        *ngIf="carparkDetails?.publishStatus"
        appConfirmProceed
        (confirm)="onContinue($event)"
        [confirmProceedOptions]="confirmProceedOptions"
        [confirmationTriggerOn]="false"
      >
        Continue
      </button>
    </div>
    <div
      class="box-btn-save accept-reject-actions"
      *ngIf="
        accountService.isAdminUser &&
        carparkDetails?.publishStatus !== 'archived'
      "
    >
      <button
        type="button"
        class="save-exit-btn"
        [disabled]="carparkDetails?.publishStatus == PublishStatuses.REJECTED"
        (click)="reject()"
      >
        Reject
      </button>
      <button
        type="button"
        [disabled]="carparkDetails?.publishStatus == PublishStatuses.PUBLISHED"
        (click)="publish()"
      >
        Approve
      </button>
    </div>
  </section>

  <ng-container *ngIf="showPreviewModal">
    <app-carpark-preview-mobile
      [carparkDetails]="carparkDetails"
      (close)="showPreviewModal = false"
    >
    </app-carpark-preview-mobile>
  </ng-container>

  <p-dialog
    class="main-dialog"
    [(visible)]="showSuccessDialog"
    [closable]="false"
    [modal]="true"
  >
    <div class="popup-success">
      <span class="closebtns" (click)="onCancel()"
        ><img src="assets/images/icons/button-delete.png" class="clsbtn"
      /></span>
      <img
        src="assets/images/success-grapich.svg"
        alt="car image"
        class="sucimg"
      />
      <h3>Success</h3>
      <p>
        Your carpark has been
        {{
          this.publishStatus == PublishStatuses.PUBLISHED ||
          this.publishStatus == PublishStatuses.VERIFYING
            ? "updated"
            : "created"
        }}!<br />
        Parking information will be published after our confirmation.
      </p>
      <button type="button" pButton (click)="onConfirm()" class="cp-list-btn">
        View Carpark List
      </button>
    </div>
  </p-dialog>
</section>
<app-revision-messages-popup
  [groupedRevisionMessages]="groupedRevisionMessages"
  [carparkRates]="carparkDetails.rates"
  [carparkSeasonRates]="carparkDetails.seasonParking"
  *ngIf="showAllRevisionMessagePopup"
  (onToggleRevisionMessageBlock)="toggleAllRevisionMessagesPopup($event)"
  [isCenter]="true"
  [grouped]="true"
>
</app-revision-messages-popup>

<p-dialog
  [(visible)]="showClosurePeriodPopup"
  [style]="{
    width: '820px',
    background: '#FFFFFF',
    'box-shadow': '0px 4px 4px rgba(0, 0, 0, 0.25)',
    'border-radius': '15px'
  }"
  [modal]="true"
  [styleClass]="'closure-period-p-dialog'"
  [draggable]="false"
  [closable]="false"
>
  <app-closure-period-popup
    (close)="handleCLosurePopupClose()"
    *ngIf="showClosurePeriodPopup"
    [carparkDetails]="carparkDetails"
  ></app-closure-period-popup>
</p-dialog>
