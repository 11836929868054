<div class="revision-message-popup">
  <div class="header">
    <h3>Revision message</h3>
    <i
      class="pi pi-check-circle"
      style="font-size: 1rem"
      (click)="onToggleRevisionMessageBlock.emit(false)"
    ></i>
    <i class="pi pi-times" style="font-size: 1rem"></i>
  </div>
  <div class="content-wrapper">
    <app-revision-message
      [reviewMessage]="revisionMessage"
      [isAdmin]="false"
    ></app-revision-message>
  </div>
</div>
