import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import {
  Parking,
  PublishStatus,
} from '../../carpark/carpark-create-main/carpark.model';
import { AccountService } from '../../services/account.service';
import { ApiService } from '../../services/api.service';
import {
  CarparkService,
  InprogressStatuses,
} from '../../services/carpark.service';
import {
  LS_IS_CAR_CREATE_MODE,
  LS_IS_MAIN_NAV_ENABLE_FORCED,
  LS_SELECTED_CARPARK_ID,
  LocalStorageService,
} from '../../services/local-storage.service';
import { CustomConfirmService } from '../../shared/components/custom-confirm/custom-confirm.service';
import { CustomMultiSelectOption } from '../../shared/components/custom-multiselect/custom-multiselect.component';

const caparkInProgressAttributeMap = {
  color: 'rgba(154, 160, 165, 1)',
  backgroundColor: 'rgba(214, 217, 219, 0.3)',
  displayText: 'In progress',
};
export const CarparkStatusAttributesMap: {
  [key in PublishStatus]: {
    color: string;
    backgroundColor: string;
    displayText: string;
  };
} = {
  [PublishStatus.SET_LOCATION]: caparkInProgressAttributeMap,
  [PublishStatus.SET_ENTRANCES]: caparkInProgressAttributeMap,
  [PublishStatus.SET_PRICES]: caparkInProgressAttributeMap,
  [PublishStatus.SET_FEATURES]: caparkInProgressAttributeMap,
  [PublishStatus.PREVIEW]: caparkInProgressAttributeMap,
  [PublishStatus.IMPORTED]: {
    ...caparkInProgressAttributeMap,
    displayText: 'Imported',
  },
  [PublishStatus.VERIFYING]: {
    color: 'rgba(0, 193, 205, 1)',
    backgroundColor: 'rgba(48, 202, 211, 0.2)',
    displayText: 'Verifying',
  },
  [PublishStatus.ARCHIVED]: {
    ...caparkInProgressAttributeMap,
    displayText: 'Archived',
  },
  [PublishStatus.PUBLISHED]: {
    color: 'rgba(75, 200, 17, 1)',
    backgroundColor: 'rgba(71, 221, 0, 0.2)',
    displayText: 'Published',
  },
  [PublishStatus.REJECTED]: {
    color: 'rgba(255, 170, 44, 1)',
    backgroundColor: 'rgba(255, 183, 74, 0.2)',
    displayText: 'To revise',
  },
};

const PublishStatusRouteMap: any = {
  [PublishStatus.SET_LOCATION]: ['/carpark', 'create', 'location'],
  [PublishStatus.SET_ENTRANCES]: ['/carpark', 'create', 'location'],
  [PublishStatus.SET_PRICES]: ['/carpark', 'create', 'prices'],
  [PublishStatus.SET_FEATURES]: ['/carpark', 'create', 'features'],
  [PublishStatus.PREVIEW]: ['/carpark', 'create', 'preview'],
  [PublishStatus.VERIFYING]: ['/carpark', 'create', 'preview'],
  [PublishStatus.PUBLISHED]: ['/carpark', 'create', 'preview'],
  [PublishStatus.REJECTED]: ['/carpark', 'create', 'preview'],
  [PublishStatus.IMPORTED]: ['/carpark', 'create', 'preview'],
};

type CarparkListModel = Pick<
  Parking,
  | 'breezeId'
  | 'name'
  | 'address'
  | 'publishStatus'
  | 'liveInBreeze'
  | 'parkingType'
  | 'id'
  | 'closureStatus'
>;

interface QueryModel {
  filters?: any;
  limit: number;
  page: number;
}

@Component({
  selector: 'app-new-carpark-list',
  templateUrl: './new-carpark-list.component.html',
  styleUrls: ['./new-carpark-list.component.scss'],
})
export class NewCarparkListComponent implements OnInit {
  readonly ITEMS_PER_PAGE = 10;

  // query observables
  searchTextControl = new FormControl();
  currentPage$ = new BehaviorSubject<number>(1);

  statusFilterFA = new FormArray([]);
  statusSelectVisible = false;
  statusFilterActive: boolean;

  totalRecords = 0;
  partialQuery$: Observable<{
    currentPage: number;
    searchText: string;
    statusFilter: string[];
  }>;
  currentPageData$: Observable<CarparkListModel[]>;
  statusOptions: CustomMultiSelectOption[] = [];

  @ViewChild('paginator', { static: true }) paginator: Paginator;

  constructor(
    private apiService: ApiService,
    private lsSrv: LocalStorageService,
    private router: Router,
    private confirmationService: CustomConfirmService,
    private messageService: MessageService,
    public cpSrv: CarparkService,
    public accountService: AccountService
  ) {
    this.setStatusOptions();
  }

  setStatusOptions() {
    Object.values(PublishStatus).forEach((status) => {
      if (
        !this.cpSrv.isInProgressStatus(status) &&
        status !== PublishStatus.ARCHIVED
      )
        this.statusOptions.push({
          label: this.cpSrv.getCarparkStatusAttributes(status).displayText,
          value: status,
          type: 'publishStatus',
        });
    });
    this.statusOptions.push({
      label: this.cpSrv.getCarparkStatusAttributes(PublishStatus.PREVIEW)
        .displayText,
      value: PublishStatus.PREVIEW,
      type: 'publishStatus',
    });
    this.statusOptions.sort((sOptionA, sOptionB) =>
      sOptionA.label.localeCompare(sOptionB.label)
    );
  }

  ngOnInit(): void {
    this.initialize();
  }

  initialize() {
    this.partialQuery$ = this.createPartialQueryStream();
    this.currentPageData$ = this.createCurrentPageDataStream();
    setTimeout(() => {
      this.searchTextControl.setValue('');
      this.statusFilterFA.reset([]);
    });
  }

  transformStatusFilter() {
    return this.statusFilterFA.valueChanges.pipe(
      map((value) =>
        value.reduce(
          (combinedStatusArray: string[], val: boolean, i: number) => {
            if (!val) return combinedStatusArray;

            if (this.statusOptions[i].value === PublishStatus.PREVIEW)
              combinedStatusArray =
                combinedStatusArray.concat(InprogressStatuses);
            else combinedStatusArray.push(this.statusOptions[i].value);

            return combinedStatusArray;
          },
          []
        )
      ),
      tap((value) => (this.statusFilterActive = !!value.length))
    );
  }

  createPartialQueryStream() {
    const status$ = this.transformStatusFilter();
    return combineLatest([
      this.currentPage$.asObservable(),
      this.searchTextControl.valueChanges.pipe(
        debounceTime(300),
        tap((e) => {
          // fetch page 1 again if searchText changes
          this.currentPage$.next(1);
          // for setting current page in paginator; solution taken from =>
          // https://stackoverflow.com/questions/62119097/primeng-paginator-set-page
          setTimeout(() => this.paginator.changePage(0)); // page index starts at 0..
        })
      ),
      status$,
    ]).pipe(
      map(([a, b, c]) => ({
        currentPage: a,
        searchText: b,
        statusFilter: c,
      }))
    );
  }

  createCurrentPageDataStream() {
    return this.partialQuery$.pipe(
      debounceTime(50), // since page will be reset to 1 immediately by the searchText change, dont want to call the api twice so quick
      map(({ currentPage, searchText, statusFilter }) => {
        const query: any = {
          page: currentPage,
          limit: this.ITEMS_PER_PAGE,
        };
        if (statusFilter?.length) {
          query.statusFilter = statusFilter;
        }
        if (searchText) {
          query.filters = {
            $or: [
              { name: { $regex: searchText, $options: 'i' } },
              { id: { $regex: searchText, $options: 'i' } },
            ],
          };
        }
        return query;
      }),
      switchMap((query) =>
        this.apiService.newGetAllCarparks(query, false, true)
      ),
      tap((res) => {
        this.totalRecords = res.data.total;
      }),
      map((res) =>
        res?.data?.result?.map((cp: Parking) => ({
          id: cp.id,
          breezeId: cp.breezeId,
          name: cp.name,
          address: cp.address,
          publishStatus: cp.publishStatus,
          liveInBreeze: cp.liveInBreeze,
          closureStatus: cp.closureStatus,
        }))
      )
    );
  }

  onPageChange({ page }: any) {
    this.currentPage$.next(page + 1);
  }

  createNewCarpark() {
    this.lsSrv.removeItem(LS_SELECTED_CARPARK_ID);
    this.lsSrv.setItem(LS_IS_CAR_CREATE_MODE, '1');
    this.lsSrv.removeItem(LS_IS_MAIN_NAV_ENABLE_FORCED);
    this.router.navigate(['/carpark', 'create']);
  }

  navigateOnRowClick(carpark: CarparkListModel) {
    this.lsSrv.setItem(LS_SELECTED_CARPARK_ID, carpark.id || '');

    const { publishStatus = PublishStatus.PREVIEW } = carpark;
    const navigationRoute = PublishStatusRouteMap[publishStatus];
    const isInprogress = this.cpSrv.isInProgressStatus(publishStatus);
    if (isInprogress) {
      this.lsSrv.setItem(LS_IS_CAR_CREATE_MODE, '1');
    } else {
      this.lsSrv.removeItem(LS_IS_CAR_CREATE_MODE);
    }
    this.router.navigate(navigationRoute, {
      queryParams: {
        edit: isInprogress ? 0 : 1,
        ...(publishStatus == PublishStatus.SET_ENTRANCES && {
          configureEntrance: true,
        }),
      },
    });
  }

  openCreateBroadcast(event: any, cp: CarparkListModel) {
    event.stopPropagation();
    if (!cp.liveInBreeze) return;
    this.router.navigate(['/broadcast', 'create'], {
      queryParams: {
        forCarparkId: cp.id,
      },
    });
  }

  deleteCarpark(event: any, cp: CarparkListModel) {
    event.stopPropagation();
    this.confirmationService.confirm({
      header: 'Delete carpark',
      message: [`Are you sure you want to delete <b>${cp.name}</b>?`],
      acceptLabel: 'Delete',
      accept: async () => {
        try {
          const result = await this.apiService.deleteCarpark(cp.id);
          if (result.success) {
            this.messageService.add({
              key: 'tc',
              severity: 'success',
              summary: 'Success',
              detail: 'Carpark deleted',
            });
            this.lsSrv.removeItem(LS_SELECTED_CARPARK_ID);
            this.currentPage$.next(1);
            // fetch the first page with current query again..
            /* a more involved logic would be to call the same page again as long as its not he only item in the page? */
            return;
          }
        } catch (error) {
          this.messageService.add({
            key: 'tc',
            severity: 'error',
            summary: 'Error',
            detail: 'Carpark deletion failed!',
          });
        }
      },
    });
  }

  async rejectCarpark(event: any, carpark: CarparkListModel) {
    event.stopPropagation();
    if (!this.accountService.isAdminUser) return;

    const { id: carparkId, name: carparkName } = carpark;

    let confirmationMessage = `Are you sure you want to reject <b>${
      carparkName || 'this'
    } carpark</b>?`;
    let acceptVisible = true;
    let rejectLabel = 'Cancel';

    // revisionMessage Calculations
    const revisionMessages = await this.apiService.getRevisionMessages({
      parentId: carparkId!,
      parentType: 'carpark',
    });
    const unApprovedReviewMessages =
      revisionMessages
        .flatMap((revision) => revision.reviews || [])
        .filter((review) => review?.reviewState === 'pending') || [];

    if (unApprovedReviewMessages.length === 0) {
      confirmationMessage =
        'Please go to either Location Pins or Parking Charges section to add comments where necessary before rejecting a carpark.';
      rejectLabel = 'Ok';
      acceptVisible = false;
    }

    this.confirmationService.confirm({
      header: 'Rejection Confirmation',
      message: [confirmationMessage],
      accept: () => {
        this.apiService
          .rejectCarpark(carparkId)
          .then((result) => {
            this.messageService.add({
              key: 'tc',
              severity: 'success',
              summary: 'Success',
              detail: 'Carpark rejected!',
            });
            this.currentPage$.next(1);
          })
          .catch((err) => {
            this.messageService.add({
              key: 'tc',
              severity: 'error',
              summary: 'Error',
              detail: 'Could not reject carpark!',
            });
          })
          .finally(() => {
            this.ngOnInit();
          });
      },
      acceptVisible,
      rejectLabel,
      acceptLabel: 'Reject',
    });
  }

  async acceptCarpark(event: any, carpark: CarparkListModel) {
    event.stopPropagation();
    if (!this.accountService.isAdminUser) return;
    const { id: carparkId, name: carparkName, parkingType } = carpark;
    if (parkingType === 'PARTIAL_SEASON_PARKING') {
      this.confirmationService.confirm({
        header: 'Publishing restricted',
        message: [
          'Publishing PARTIAL SEASON PARKING carparks is disabled at the moment',
        ],
        acceptLabel: 'Okay',
        rejectVisible: false,
      });
      return;
    }
    let confirmationMessage = `Are you sure you want to approve <b>${
      carparkName || 'this'
    } carpark</b>?`;

    // revisionMessage Calculations
    const revisionMessages = await this.apiService.getRevisionMessages({
      parentId: carparkId!,
      parentType: 'carpark',
    });
    const unApprovedReviewMessages =
      revisionMessages
        .flatMap((revision) => revision.reviews || [])
        .filter((review) => review?.reviewState === 'pending') || [];

    if (unApprovedReviewMessages.length > 0)
      confirmationMessage =
        `There are ${unApprovedReviewMessages.length} unresolved revisions. ` +
        confirmationMessage;

    this.confirmationService.confirm({
      header: 'Approval confirmation',
      message: [confirmationMessage],
      accept: () => {
        this.apiService
          .publishCarpark(carparkId)
          .then((result) => {
            this.messageService.add({
              key: 'tc',
              severity: 'success',
              summary: 'Success',
              detail: 'Carpark published!',
            });
            this.currentPage$.next(1);
          })
          .catch((err) => {
            this.messageService.add({
              key: 'tc',
              severity: 'error',
              summary: 'Error',
              detail: 'Could not publish carpark!',
            });
          })
          .finally(() => {
            this.ngOnInit();
          });
      },
      acceptLabel: 'Approve',
      rejectLabel: 'Cancel',
    });
  }
}
