import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '../services/account.service';
import {
  LS_SELECTED_CARPARK_ID,
  LocalStorageService,
} from '../services/local-storage.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  constructor(
    private router: Router,
    private lsSrv: LocalStorageService,
    public accountService: AccountService
  ) {}

  ngOnInit(): void {
    this.accountService.getUserAttributes().then((res) => {
      this.accountService.userName =
        res?.result?.find((e) => e.Name === 'custom:full_name')?.Value || '';
    });
  }

  createCarpark() {
    this.lsSrv.removeItem(LS_SELECTED_CARPARK_ID);
    this.router.navigate(['/carpark', 'create']);
  }
}
