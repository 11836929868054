<div
  class="revision-message-overlay"
  [ngClass]="{
    center: isCenter
  }"
>
  <section class="revision-message-section">
    <div class="revision-header">
      <h3>{{ header }}</h3>
      <button
        class="close-btn"
        (click)="onToggleRevisionMessageBlock.emit(false)"
      >
        <i class="pi pi-times"></i>
      </button>
    </div>
    <ng-container *ngIf="!grouped">
      <div class="revision-message-container">
        <h4 *ngIf="!this.revisionMessages?.length">
          {{ revisionDisplayValue }}
        </h4>
        <section
          class="revision-message-group"
          *ngFor="let revisionMessageObj of revisionMessages"
        >
          <h4>{{ revisionMessageObj.displayValue }}</h4>
          <ng-container
            *ngFor="let reviewMessageObj of revisionMessageObj.reviews"
          >
            <app-revision-message
              *ngIf="
                accountService.isAdminUser ||
                (!accountService.isAdminUser &&
                  reviewMessageObj.reviewState === 'pending')
              "
              [reviewMessage]="reviewMessageObj"
              [isAdmin]="accountService.isAdminUser"
              (toggleReviewState)="
                toggleReviewMessageStatus(
                  revisionMessageObj,
                  reviewMessageObj,
                  $event
                )
              "
            ></app-revision-message>
          </ng-container>
        </section>
      </div>
      <div
        class="revision-input-container"
        *ngIf="
          revisionMessages &&
          revisionMessages.length <= 1 &&
          revisionBlockId &&
          accountService.isAdminUser
        "
      >
        <input
          type="text"
          pInputText
          [(ngModel)]="newRevisionMessage"
          placeholder="Enter message"
        />
        <button
          class="send-button"
          (click)="addRevisionMessage()"
          [disabled]="!newRevisionMessage?.trim()?.length"
          placeholder="Enter message"
        >
          <i class="pi pi-arrow-up"></i>
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="grouped">
      <div
        class="grouped-revisions revision-message-container"
        *ngIf="isLocationPinRevMessagesEmpty"
      >
        <h3>Location pins</h3>
        <section class="revision-message-group">
          <p class="no-revision-text">No revision message</p>
        </section>
      </div>
      <div
        class="grouped-revisions revision-message-container"
        *ngIf="isRateDayBlockRevMessagesEmpty"
      >
        <h3>Parking charges</h3>
        <section class="revision-message-group">
          <p class="no-revision-text">No revision message</p>
        </section>
      </div>

      <ng-container
        *ngFor="
          let revisionKeyValueArray of getGroupedRevisionKeyValues();
          trackBy: trackBy
        "
      >
        <div
          class="grouped-revisions revision-message-container"
          *ngIf="revisionKeyValueArray[1]?.length"
        >
          <h3>
            {{ getGroupedHeader(revisionKeyValueArray[0]) }}
          </h3>
          <section
            class="revision-message-group"
            *ngFor="
              let revisionMessageObj of revisionKeyValueArray[1];
              trackBy: trackBy
            "
          >
            <h4>{{ revisionMessageObj.displayValue }}</h4>
            <ng-container
              *ngFor="
                let reviewMessageObj of revisionMessageObj.reviews;
                trackBy: trackBy
              "
            >
              <app-revision-message
                *ngIf="
                  accountService.isAdminUser ||
                  (!accountService.isAdminUser &&
                    reviewMessageObj.reviewState === 'pending')
                "
                [reviewMessage]="reviewMessageObj"
                [isAdmin]="accountService.isAdminUser"
                (toggleReviewState)="
                  toggleReviewMessageStatus(
                    revisionMessageObj,
                    reviewMessageObj,
                    $event
                  )
                "
              ></app-revision-message>
            </ng-container>
          </section>
        </div>
      </ng-container>
    </ng-container>
  </section>
</div>
