<div class="dashboard-wrapper">
  <div class="h1-head">Hello {{ accountService.userName }}!</div>

  <div class="dash-box-wrpper">
    <div class="path-image-container">
      <img src="assets/images/path-road.svg" alt="" class="roadpath" />
      <div class="dash-box-1 down-box-1">
        <span>01</span>
        <div class="dash-head">
          <img src="assets/images/location-entrace.svg" height="40" class="" />
        </div>
        <div class="dash-body">
          <div class="dhad">Location & Entrance</div>
          Integrate your parking location, entrance and availability information
          to be published on the Breeze app.
        </div>
      </div>
      <div class="dash-box-1 down-box-2">
        <span>02</span>
        <div class="dash-head">
          <img src="assets/images/add-price.svg" height="40" class="" />
        </div>
        <div class="dash-body">
          <div class="dhad">Add Price</div>
          Set parking charges in just a few steps.
        </div>
      </div>
      <div class="dash-box-1 down-box-3">
        <span>03</span>
        <div class="dash-head">
          <img src="assets/images/car-park-feature.svg" height="40" class="" />
        </div>
        <div class="dash-body">
          <div class="dhad">Carpark Amenities</div>
          Provide and update information on your carpark facilities easily.
        </div>
      </div>
      <div class="dash-box-1 down-box-4">
        <span class="activespan">04</span>
        <div class="dash-head activeb">
          <img src="assets/images/preview-publish.svg" height="40" class="" />
        </div>
        <div class="dash-body">
          <div class="dhad">Preview & Publish</div>
          Broadcast messages to drivers in real-time.
        </div>
      </div>
    </div>
  </div>

  <div class="dash-footer">
    <div class="foot-text">
      Connecting parking information with drivers in real-time.
    </div>
    <div class="button-blk">
      <button (click)="createCarpark()">Create New Carpark</button>
    </div>
  </div>
</div>
