import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '../../services/account.service';
import {
  LS_IS_CAR_CREATE_MODE,
  LS_SELECTED_CARPARK_ID,
  LocalStorageService,
} from '../../services/local-storage.service';

@Component({
  selector: 'app-first-time-dash',
  templateUrl: './first-time-dash.component.html',
  styleUrls: ['./first-time-dash.component.scss'],
})
export class FirstTimeDashComponent implements OnInit {
  constructor(
    private router: Router,
    public accountService: AccountService,
    private lsSrv: LocalStorageService
  ) {}

  ngOnInit(): void {}

  createCarpark() {
    this.lsSrv.removeItem(LS_SELECTED_CARPARK_ID);
    this.lsSrv.setItem(LS_IS_CAR_CREATE_MODE, '1');
    this.router.navigate(['/carpark', 'create']);
  }
}
