import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class CustomToastbarService {
  private requireConfirmationSource = new Subject();
  requireConfirmation$ = this.requireConfirmationSource.asObservable();

  constructor() {}

  confirm() {
    this.requireConfirmationSource.next(confirm);
  }
}
