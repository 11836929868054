export const environment = {
  production: true,

  mapbox: {
    accessToken:
      'pk.eyJ1IjoiYnJlZXplbWFwIiwiYSI6ImNraTAyMzV1ajAwYmsycGxyd2F3YjN4NWQifQ.QI1bMjKtgc3NkimrTO7eCg',
    styleLink: 'mapbox://styles/breezemap/cl8vbyjmi000414pdy7du8x8f',
  },
  cognito: {
    userPoolConfig: {
      UserPoolId: 'ap-southeast-1_yb10dlgyX',
      ClientId: '5to83lcl02fq8i9ncm309ii5ma',
    },
  },

  apiUrl: 'https://dev.breeze.com.sg/api/v1/parkezy',
  breezeApiUrl: 'https://dev.breeze.com.sg/api/v1/parkezy',
  trackingVoucherBaseUrl: 'https://trackvouchers-dev.breeze.com.sg',
  redeemVoucherBaseUrl: 'https://redeemvoucher-dev.breeze.com.sg',
};
