<section class="notification-wrpper">
  <div class="not-head">
    <div class="notif-head-lf" *ngIf="notificationList.length">
      Notifications
    </div>
    <div class="notif-head-lf" *ngIf="!notificationList.length">
      No notifications
    </div>
    <button
      class="notif-head-rf"
      (click)="markNotificationsAsRead()"
      *ngIf="notificationList.length && !areNotificationsRead()"
    >
      Mark all as read
    </button>
  </div>
  <div
    class="notificaiton-row"
    *ngFor="let notification of notificationList"
    [ngClass]="{
      'notif-active': !notification.read
    }"
    (click)="handleNotificationRouting(notification)"
  >
    <div class="notfic-icon"><i class="pi pi-bell"></i></div>
    <div class="notfic-title">
      <p class="not-head-block">{{ notification.title }}</p>
      <p class="not-para-block">{{ notification.content }}</p>
    </div>
    <div class="notfic-date">
      {{ getFormattedTimeDistance(notification.createdOn) }}
    </div>
  </div>
</section>
