<div class="footer-list-hover">
  <div class="fdh3block">Create your carpark in 4 steps</div>
  <div class="drive-cont">
    <ul>
      <li>
        <div class="btmain">
          <div class="bottom-list-hover">
            <span>01</span>
            <div class="bt-header">
              <img src="/assets/images/location-entrace.svg" />
            </div>
            <div class="bt-body">
              <h3>Identify location & entrances of carpark</h3>
              <p>Give carpark an identifier so customers can easily find it</p>
            </div>
          </div>
          <div class="iconblk-1"></div>
          <h3>location & entrance</h3>
        </div>
      </li>
      <li>
        <div class="btmain">
          <div class="bottom-list-hover">
            <span>02</span>
            <div class="bt-header">
              <img src="/assets/images/location-entrace.svg" />
            </div>
            <div class="bt-body">
              <h3>Add Prices</h3>
              <p>Set parking charges in just a few steps</p>
            </div>
          </div>
          <div class="iconblk-2"></div>
          <h3>Prices</h3>
        </div>
      </li>
      <li>
        <div class="btmain">
          <div class="bottom-list-hover">
            <span>03</span>
            <div class="bt-header">
              <img src="/assets/images/location-entrace.svg" />
            </div>
            <div class="bt-body">
              <h3>Carpark Amenities</h3>
              <p>
                Provide and update information on your carpark facilities
                easily.
              </p>
            </div>
          </div>
          <div class="iconblk-3"></div>
          <h3>features</h3>
        </div>
      </li>
      <li>
        <div class="btmain">
          <div class="bottom-list-hover">
            <span>04</span>
            <div class="bt-header">
              <img src="/assets/images/location-entrace.svg" />
            </div>
            <div class="bt-body">
              <h3>Preview & Publish</h3>
              <p>Broadcast messages to drivers in real-time.</p>
            </div>
          </div>
          <div class="iconblk-4"></div>
          <h3>preview & publish</h3>
        </div>
      </li>
    </ul>
  </div>
</div>
