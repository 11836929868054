<section class="custom-confirm-dialog-wrapper" *ngIf="visible">
  <div class="mask">
    <div class="wrapper">
      <button
        class="close-btn"
        (click)="close()"
        *ngIf="option('closeVisible')"
      >
        <i class="pi pi-times"></i>
      </button>
      <div class="header" *ngIf="option('header')">{{ option("header") }}</div>
      <div class="body" *ngIf="option('message')?.length">
        <div
          class="section"
          *ngFor="let line of option('message')"
          [innerHTML]="line"
        ></div>
      </div>
      <div class="footer">
        <button
          class="reject-btn"
          [ngStyle]="{
            order:btnReversed ? 3: 1
          }"
          *ngIf="option('rejectVisible')"
          (click)="reject()"
        >
          {{ option("rejectLabel") }}
        </button>
        <span class="devider"></span>
        <button
          class="accept-btn"
          [ngStyle]="{
            order:btnReversed ? 1 : 3
          }"
          *ngIf="option('acceptVisible')"
          (click)="accept()"
        >
          {{ option("acceptLabel") }}
        </button>
      </div>
    </div>
  </div>
</section>
