import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  Parking,
  VehicleTypes,
} from '../../../carpark/carpark-create-main/carpark.model';
import { AccountService } from '../../../services/account.service';
import { ApiService } from '../../../services/api.service';
import { CarparkService } from '../../../services/carpark.service';
import { Review, Revision, RevisionTypeDisplayMap } from '../../revision.model';

export type GroupedRevisionMessages = {
  [key in Revision['type']]: Revision[];
};

@Component({
  selector: 'app-revision-messages-popup',
  templateUrl: './revision-messages-popup.component.html',
  styleUrls: ['./revision-messages-popup.component.scss'],
})
export class RevisionMessagesPopupComponent implements OnInit {
  revisionMessages: Revision[];
  @Input('groupedRevisionMessages')
  groupedRevisionMessages: GroupedRevisionMessages;
  @Input('isCenter') isCenter = false;
  @Output() onToggleRevisionMessageBlock = new EventEmitter<boolean>();
  @Input('header') header = 'All revision messages';
  @Input('revisionParentType') revisionParentType: Revision['parentType'];
  @Input('revisionType') revisionType: Revision['type'] | null;
  @Input('revisionDisplayValue') revisionDisplayValue = '';
  @Input('revisionParentId') revisionParentId = '';
  @Input('revisionBlockId') revisionBlockId: string | null = '';
  @Input('grouped') grouped: boolean = false;
  @Input('carparkRates') carparkRates: Parking['rates'];
  @Input('carparkSeasonRates') carparkSeasonRates: Parking['seasonParking'];
  @Output() onParentBlockReviewStateUpdate = new EventEmitter<any>();
  Object = Object;
  newRevisionMessage = '';
  isLocationPinRevMessagesEmpty: boolean;
  isRateDayBlockRevMessagesEmpty: boolean;

  constructor(
    public accountService: AccountService,
    private apiService: ApiService,
    private cpSrv: CarparkService
  ) {}

  ngOnInit(): void {
    if (!this.groupedRevisionMessages) this.getRevisionMessages().then();
    else if (this.groupedRevisionMessages) {
      this.doEmptyBlockChecks();
      this.groupedRevisionMessages['rate-day-block'].forEach((revisionGrp) =>
        this.addDisplayHeaderForRateRevision(revisionGrp, this.carparkRates)
      );
      this.groupedRevisionMessages['season-rate-block'].forEach((revisionGrp) =>
        this.addDisplayHeaderForSeasonRateRevision(
          revisionGrp,
          this.carparkSeasonRates
        )
      );
    }
  }
  addDisplayHeaderForSeasonRateRevision(
    revisionObj: Revision,
    seasonRates: Parking['seasonParking']
  ) {
    Object.entries(seasonRates).forEach(([key, value]) => {
      if ((value as any).revisionId === revisionObj.id)
        revisionObj.displayValue = (
          {
            [VehicleTypes.CARS]: 'Cars',
            [VehicleTypes.LOADING_BAY]: 'Heavy Vehicles',
            [VehicleTypes.MOTORCYCLES]: 'Motorcycles',
            contactEmail: 'Contact Email',
          } as any
        )[key];
    });
  }
  addDisplayHeaderForRateRevision(
    revisionObj: Revision,
    carparkRate: Parking['rates'],
    skipPrefix = false
  ) {
    const revisionId = revisionObj.id;

    if (carparkRate?.cars)
      for (let rateObjForDaySlot of carparkRate?.cars) {
        if (rateObjForDaySlot.revisionId === revisionId) {
          revisionObj.displayValue =
            (skipPrefix ? '' : 'Car: ') +
            this.cpSrv.getDaysRange(rateObjForDaySlot.day);
          return;
        }
      }
    if (carparkRate?.loadingbay)
      for (let rateObjForDaySlot of carparkRate?.loadingbay) {
        if (rateObjForDaySlot.revisionId === revisionId) {
          revisionObj.displayValue =
            (skipPrefix ? '' : 'Loading Bay: ') +
            this.cpSrv.getDaysRange(rateObjForDaySlot.day);
          return;
        }
      }
    if (carparkRate?.motorcycles)
      for (let rateObjForDaySlot of carparkRate?.motorcycles) {
        if (rateObjForDaySlot.revisionId === revisionId) {
          revisionObj.displayValue =
            (skipPrefix ? '' : 'Motorcycle: ') +
            this.cpSrv.getDaysRange(rateObjForDaySlot.day);
          return;
        }
      }
  }

  doEmptyBlockChecks() {
    this.isLocationPinRevMessagesEmpty =
      !this.groupedRevisionMessages.locationpin?.length;
    this.isRateDayBlockRevMessagesEmpty =
      !this.groupedRevisionMessages['rate-day-block']?.length;
  }

  async getRevisionMessages() {
    // bad code: using null to bypass fetching everything todo: refactor --ys
    if (this.revisionType === null || this.revisionBlockId === null) return;
    const revisionFetchDetails: Parameters<
      ApiService['getRevisionMessages']
    >[0] = {
      parentId: this.revisionParentId,
      parentType: this.revisionParentType,
      type: this.revisionType,
      id: this.revisionBlockId,
    };
    const result = await this.apiService.getRevisionMessages(
      revisionFetchDetails
    );
    this.revisionMessages = result;
    if (this.revisionType === 'rate-day-block' && this.revisionParentId) {
      const {
        data: { rates },
      } = await this.apiService.getCarparkById(undefined, true);
      this.revisionMessages.forEach((revision) =>
        this.addDisplayHeaderForRateRevision(revision, rates)
      );
    }
  }

  async addRevisionMessage() {
    /* note: this can/should only be called when just one revisionType is being
     displayed in screen so revisionMessages array would only have one element
     present */
    if (this.revisionMessages.length > 1) return;

    const newRevMsg = this.newRevisionMessage?.trim();
    if (!newRevMsg) return;
    if (!this.revisionBlockId) return;

    const selectedRMObj = this.revisionMessages[0];

    const revMessageBody = {
      id: selectedRMObj?.id || this.revisionBlockId,
      type: selectedRMObj?.type || this.revisionType || 'rate-day-block',
      parentType: selectedRMObj?.parentType || this.revisionParentType,
      parentId: selectedRMObj?.parentId! || this.revisionParentId,
      displayValue: selectedRMObj?.displayValue || this.revisionDisplayValue,
      reviews: [
        {
          deleted: false, // for future?
          message: this.newRevisionMessage,
          reviewState: 'pending',
        },
      ] as [Review],
    };
    const result = await this.apiService.addOrUpdateRevisionMessage(
      revMessageBody
    );
    this.newRevisionMessage = '';
    this.revisionMessages = await this.apiService.getRevisionMessages({
      id: revMessageBody.id,
      type: revMessageBody.type,
      parentId: revMessageBody.parentId,
      parentType: revMessageBody.parentType,
    });
    await this.updateReviewStateOfParentBlock(false);
  }

  async toggleReviewMessageStatus(
    revision: Revision,
    review: Review,
    newReviewState: Review['reviewState']
  ) {
    if (!revision.type || !revision.parentId) return;

    // note: bad typing issues b/w function argument and review object causing issues here. Hence using any for now;
    const toggledReviewObject: any = {
      ...revision,
      reviews: [
        {
          ...review,
          reviewState:
            review.reviewState === 'approved' ? 'pending' : 'approved',
          // newReviewState not really needed if we use above logic?
        },
      ],
    };

    const result = await this.apiService.addOrUpdateRevisionMessage(
      toggledReviewObject
    );
    // if "grouped", this component has a different flow.. best to refactor this approach;
    if (this.grouped) {
      const selectedReview = this.groupedRevisionMessages[revision.type]
        .find((r) => r.id === revision.id)
        ?.reviews?.find((r) => r.reviewId === review.reviewId);
      if (selectedReview)
        selectedReview.reviewState =
          selectedReview.reviewState === 'approved' ? 'pending' : 'approved';
      const pendingReviewsPresent = result?.reviews?.some(
        (review) => review.reviewState === 'pending'
      );
      await this.apiService.updateReviewableBlockReviewState({
        type: revision.type,
        parentId: revision.parentId,
        parentType: revision.parentType,
        reviewState: pendingReviewsPresent ? 'pending' : 'approved',
        revisionId: revision.id,
      });
    } else {
      await this.getRevisionMessages();
      await this.updateReviewStateOfParentBlock();
    }
  }

  async updateReviewStateOfParentBlock(newIsApprovedState?: boolean) {
    let isApproved = false;
    if (newIsApprovedState !== undefined) isApproved = newIsApprovedState;
    else
      isApproved = this.revisionMessages
        .flatMap((revision) => revision.reviews)
        .every((review) => review?.reviewState === 'approved');

    const newReviewStateBody = {
      parentId: this.revisionParentId,
      parentType: this.revisionParentType,
      reviewState: (isApproved
        ? 'approved'
        : 'pending') as Review['reviewState'], // for typescript
      revisionId: this.revisionBlockId!,
      type: this.revisionType!,
    };

    const reviewStateUpdateResult =
      await this.apiService.updateReviewableBlockReviewState(
        newReviewStateBody
      );
    this.onParentBlockReviewStateUpdate.emit(newReviewStateBody);
  }

  // due to html typing being unavailable, its difficult to use Revision['type'] here
  getGroupedHeader(revisionType: string) {
    return RevisionTypeDisplayMap[revisionType as Revision['type']];
  }

  getGroupedRevisionKeyValues() {
    return Object.entries(this.groupedRevisionMessages);
  }

  trackBy(index: number, item: any) {
    return index;
  }
}
