import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AccountService } from '../account.service';
import {
  LS_ENABLE_CREATE_ACCOUNT,
  LS_IS_ADMIN,
  LocalStorageService,
} from '../local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private accountService: AccountService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.accountService.isLoggedIn()) {
      return true;
    } else if (this.accountService.loginPopupTriggered) {
      return true;
    } else {
      // localStorage.setItem(LS_SAVED_URL, JSON.stringify(window.location));
      return this.router.parseUrl('account/login');
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class CreateAccountGuard implements CanActivate {
  constructor(private router: Router, private lsSrv: LocalStorageService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.lsSrv.getItem(LS_ENABLE_CREATE_ACCOUNT)) {
      return this.router.parseUrl('account/login');
    } else return true;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthAdminGuard implements CanActivate {
  constructor(private router: Router, private lsSrv: LocalStorageService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const isAdmin = this.lsSrv.getItem(LS_IS_ADMIN) == '1' ? true : false;
    return isAdmin ? true : this.router.parseUrl('dashboard');
  }
}
