import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-image',
  templateUrl: './confirm-image.component.html',
  styleUrls: ['./confirm-image.component.scss']
})
export class ConfirmImageComponent implements OnInit {

  @Input('showModal')
  showModal = false
  @Input('cancelText')
  cancelText = 'Cancel'
  @Input('confirmText')
  confirmText = 'OK'
  @Input('header')
  header = 'Confirm'
  @Input('description')
  description = 'Please Confirm'

  @Output('onCancel')
  onCancel: any = () => {}

  @Output('onConfirm')
  onConfirm: any = () => {}

  constructor() { }

  ngOnInit(): void {
  }

}
