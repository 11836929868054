import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export class CustomEvent {
  name: string;
  data?: any
}

@Injectable()
export class EventService {
  public event = new BehaviorSubject<CustomEvent>({
    name: 'default-event',
    data: null
  });
  eventObservable = this.event.asObservable();

  emit(value: CustomEvent): void {
    this.event.next(value);
  }
}
