import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccountService } from '../../../services/account.service';

// Note: this standalone popup component is not being used atm (?)
@Component({
  selector: 'app-revision-message-popup',
  templateUrl: './revision-message-popup.component.html',
  styleUrls: ['./revision-message-popup.component.scss'],
})
export class RevisionMessagePopupComponent implements OnInit {
  @Input('revisionMessages') revisionMessage: any;
  @Output() onToggleRevisionMessageBlock = new EventEmitter<boolean>();

  constructor(public accountService: AccountService) {}

  ngOnInit(): void {}

  toggleRevisionMessageStatus() {}
}
