<div class="custom-multiselect-container">
  <div class="multiselect-option" *ngIf="showAll">
    <app-custom-checkbox
      label="Show All"
      [fc]="showAllFC"
      [multiselectName]="multiselectName"
      checkboxId="{{multiselectName + '_checkbox_showAll}}"
      checkedIcon="selected-gray-checkbox.png"
      uncheckedIcon="empty-gray-checkbox.png"
      [minimal]="false"
    >
    </app-custom-checkbox>
  </div>
  <ng-container *ngFor="let optionFC of multiSelectFA.controls; let i = index">
    <div
      class="multiselect-option"
      *ngIf="multiselectName + '_checkbox_' + i as checkboxId"
    >
      <app-custom-checkbox
        [label]="options[i].label"
        [fc]="fu.getAsFC(optionFC)"
        [multiselectName]="multiselectName"
        [checkboxId]="checkboxId"
        checkedIcon="selected-gray-checkbox.png"
        uncheckedIcon="empty-gray-checkbox.png"
        [minimal]="false"
      >
      </app-custom-checkbox>
    </div>
  </ng-container>
</div>
