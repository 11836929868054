import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-custom-checkbox',
  templateUrl: './custom-checkbox.component.html',
  styleUrls: ['./custom-checkbox.component.scss'],
})
export class CustomCheckboxComponent implements OnInit {
  @Input('label') label: string;
  @Input('fc') fc: FormControl;
  @Input('multiselectName') multiselectName: string;
  @Input('checkboxId') checkboxId: string;

  @Input('minimal') minimal = true;
  @Input('checkedIcon') checkedIcon = 'check-box-checked.png';
  @Input('uncheckedIcon') uncheckedIcon = 'check-box-unchecked.png';

  @Input('preventBubbling') preventBubbling = false;
  @HostListener('click', ['$event'])
  handleClick(event: any) {
    if (this.preventBubbling) event.stopPropagation();
  }

  constructor() {}

  ngOnInit(): void {}
}
