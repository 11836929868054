import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Pin, Place } from '../admin/associate-places/places.model';
import { BroadCastModel } from '../broadcast/broadcast.model';
import {
  CarparkDefaultLocationPin,
  CarparkLocationMarkers,
  CarparkMapIcon,
  Parking,
} from '../carpark/carpark-create-main/carpark.model';
import {} from '../config/constants/general.constants';
import { Review, Revision } from '../shared/revision.model';
import { AccountService } from './account.service';
import {
  LS_OPERATOR_ID,
  LS_SELECTED_CARPARK_ID,
  LS_USER_EMAIL,
  LocalStorageService,
} from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private apiUrl: string;
  private breezeApiUrl: string;
  mappedUserList: MappedUserDetails[];

  constructor(
    private http: HttpClient,
    private accountService: AccountService,
    private lsSrv: LocalStorageService
  ) {
    this.apiUrl = environment.apiUrl;
    this.breezeApiUrl = environment.breezeApiUrl;
    this.getTranslationsFromBE().then();
  }

  // todo: define the types when details are finalized
  async createAccount(operatorName: string, role: string) {
    const userEmail = this.accountService.userEmail;
    const cognitoUsername = this.accountService.cognitoUsername;
    const accountDetails = {
      userEmail,
      cognitoUsername,
      operatorName,
      role,
    };

    return <any>(
      this.http.post(`${this.apiUrl}/user`, accountDetails).toPromise()
    );
  }

  async setInitialLocation(locationDetails: any) {
    const reqBody = {
      operatorId: this.accountService.operatorId,
      locationDetails,
    };
    return <any>(
      this.http
        .post(`${this.apiUrl}/operator/initial-location`, reqBody)
        .toPromise()
    );
  }

  generateToken() {
    const params: any = {
      operatorId: this.accountService.operatorId,
      userId: this.accountService.userEmail,
    };
    return this.http
      .get(`${this.apiUrl}/carpark/availability/token`, {
        params,
      })
      .toPromise<any>();
  }

  getToken() {
    const params: any = {
      operatorId: this.accountService.operatorId,
    };
    return this.http
      .get(`${this.apiUrl}/carpark/availability/existing-token`, {
        params,
      })
      .toPromise<any>();
  }

  getAdminToken() {
    const adminEmail = this.accountService.userEmail;
    return this.http
      .get(`${this.apiUrl}/user/admin/auth-token`, {
        params: {
          adminEmail,
        },
      })
      .toPromise<any>();
  }

  getAvailabilityLogs(queryObj: QueryObj) {
    return this.http
      .post(`${this.apiUrl}/carpark/availability/logs`, queryObj)
      .toPromise<any>();
  }

  updateAvailabilityIntegrateDetails(
    apiDetails: AvailabilityIntegrateApiDetails
  ) {
    return this.http
      .post(`${this.apiUrl}/carpark/availability/api-details`, apiDetails)
      .toPromise<any>();
  }

  getAvailabilityIntegrateDetails() {
    return this.http
      .get(`${this.apiUrl}/carpark/availability/api-details`, {
        params: {
          operatorId: this.accountService.operatorId || '',
        },
      })
      .toPromise<any>();
  }

  getUploadMetaData(ext: string, newFileName: string) {
    return this.http
      .get(`${this.breezeApiUrl}/presignurl`, {
        params: {
          filecontenttype: ext,
          filename: newFileName,
        },
        headers: {
          Authorization: `Bearer ${this.accountService.getIdToken()}`,
        },
      })
      .toPromise<any>();
  }

  createCarpark(carparkDetails: any) {
    carparkDetails.operatorId = this.accountService.operatorId;
    carparkDetails.createdByUser = this.accountService.userEmail;
    carparkDetails.updatedBy = this.accountService.userEmail;

    return this.http
      .post(`${this.apiUrl}/carpark`, carparkDetails)
      .toPromise<any>();
  }

  updateCarpark(carparkDetails: any, carparkId?: string) {
    carparkId = carparkId || this.lsSrv.getItem(LS_SELECTED_CARPARK_ID) || '';
    carparkDetails.updatedBy = this.accountService.userEmail;
    return this.http
      .put(`${this.apiUrl}/carpark/${carparkId}`, carparkDetails)
      .toPromise<any>();
  }

  getCarparkById(
    carparkId?: string,
    checkIfLiveInBreeze?: boolean
  ): Promise<{ data: Parking; success: boolean }> {
    carparkId = carparkId || this.lsSrv.getItem(LS_SELECTED_CARPARK_ID) || '';
    return (
      this.http
        .get(`${this.apiUrl}/carpark/${carparkId}`, {
          params: {
            ...(checkIfLiveInBreeze && {
              checkIfLiveInBreeze: '1',
            }),
          },
        })
        // new queryParam checkIfLiveInBreeze => '0' or '1'
        .toPromise<any>()
    );
  }

  deleteCarpark(carparkId?: string) {
    carparkId = carparkId || this.lsSrv.getItem(LS_SELECTED_CARPARK_ID) || '';
    return this.http
      .delete(`${this.apiUrl}/carpark/${carparkId}`)
      .toPromise<any>();
  }

  // used for reactive pattern
  newGetAllCarparks(
    queryObj: any,
    skipDetails: boolean,
    checkIfLiveInBreeze?: boolean
  ) {
    const carparkSearchPostBody = {
      ...queryObj,
    };
    if (!this.accountService.isAdminUser)
      carparkSearchPostBody.operatorId = this.accountService.operatorId;
    if (skipDetails !== undefined)
      carparkSearchPostBody.skipDetails = skipDetails ? '1' : '0';
    if (checkIfLiveInBreeze !== undefined)
      carparkSearchPostBody.checkIfLiveInBreeze = checkIfLiveInBreeze
        ? '1'
        : '0';

    return this.http.post<any>(
      `${this.apiUrl}/carparks/search`,
      carparkSearchPostBody
    );
  }

  getAllCarparks(
    queryObj: any,
    skipDetails?: boolean,
    checkIfLiveInBreeze?: boolean
  ) {
    const carparkSearchPostBody = {
      ...queryObj,
      ...(!this.accountService.isAdminUser && {
        operatorId: this.accountService.operatorId,
      }),
    };

    if (skipDetails !== undefined)
      carparkSearchPostBody.skipDetails = skipDetails ? '1' : '0';

    if (checkIfLiveInBreeze !== undefined)
      carparkSearchPostBody.checkIfLiveInBreeze = checkIfLiveInBreeze
        ? '1'
        : '0';

    return this.http
      .post(`${this.apiUrl}/carparks/search`, carparkSearchPostBody)
      .toPromise<any>();
  }

  // todo: update/create this [paginated?] api to fetch carparksList with only needed details
  getAllCarparkList() {
    return this.http
      .post(`${this.apiUrl}/carparks/search`, {
        page: 1,
        limit: 100000,
      })
      .toPromise<any>();
  }
  async getCarparkMapIcons(): Promise<{
    success: boolean;
    data?: CarparkMapIcon[];
  }> {
    return await this.http
      .get(`${this.apiUrl}/carpark-map-icons`)
      .toPromise<any>();
  }

  publishCarpark(carparkId?: string) {
    carparkId = carparkId || this.lsSrv.getItem(LS_SELECTED_CARPARK_ID) || '';
    const adminEmail = this.lsSrv.getItem(LS_USER_EMAIL);
    const body: any = { adminEmail };
    return this.http
      .patch(`${this.apiUrl}/carpark/${carparkId}/publish`, body)
      .toPromise<any>();
  }

  rejectCarpark(carparkId?: string) {
    carparkId = carparkId || this.lsSrv.getItem(LS_SELECTED_CARPARK_ID) || '';
    const adminEmail = this.lsSrv.getItem(LS_USER_EMAIL);
    const body: any = { adminEmail };
    return this.http
      .patch(`${this.apiUrl}/carpark/${carparkId}/reject`, body)
      .toPromise<any>();
  }

  getBroadcastMessages(queryObj: any) {
    if (!this.accountService.isAdminUser) {
      queryObj.operatorId = this.accountService.operatorId;
    }

    return this.http.post(`${this.apiUrl}/broadcasts/search`, {
      ...queryObj,
    });
  }

  createBroadcastMessage(broadcastDetails: BroadCastModel) {
    return this.http
      .post(`${this.apiUrl}/broadcast`, {
        broadcastDetails: {
          ...broadcastDetails,
          operatorId: this.accountService.operatorId,
        },
      })
      .toPromise<any>();
  }

  updateBroadcastMessage(id: string | undefined, updateBroadcastObj: any) {
    return this.http
      .put(`${this.apiUrl}/broadcast`, {
        id,
        updateBroadcastObj,
      })
      .toPromise<any>();
  }

  deleteBroadcastMessages(ids: string[] = []) {
    return this.http
      .delete(`${this.apiUrl}/broadcast`, {
        body: {
          broadcastIds: ids,
        },
      })
      .toPromise<any>();
  }

  // operators APIS
  getOperatorById(operatorId?: string) {
    operatorId =
      operatorId ||
      this.accountService.operatorId ||
      this.lsSrv.getItem(LS_OPERATOR_ID) ||
      '';
    return this.http
      .get(`${this.apiUrl}/operator/${operatorId}`)
      .toPromise<any>();
  }

  updateOperator(operatorDetails: any, operatorId?: string) {
    operatorId =
      operatorId ||
      this.accountService.operatorId ||
      this.lsSrv.getItem(LS_OPERATOR_ID) ||
      '';
    return this.http
      .put(`${this.apiUrl}/operator/${operatorId}`, operatorDetails)
      .toPromise<any>();
  }

  getOperators(reqObj: any = {}) {
    return this.http
      .post(`${this.apiUrl}/operators/search`, {
        ...reqObj,
      })
      .toPromise<any>();
  }

  deleteOperator(operatorId: string) {
    return this.http
      .delete(`${this.apiUrl}/operator/${operatorId}`)
      .toPromise<any>();
  }

  getNotifications(userEmail?: string, operatorId?: string) {
    return this.http
      .get(`${this.apiUrl}/user/notifications`, {
        params: {
          operatorId: operatorId || this.accountService.operatorId || 'admin',
          userEmail: userEmail || this.accountService.userEmail || '',
        },
      })
      .toPromise<any>();
  }

  markAllNotificationsAsRead(userEmail?: string, operatorId?: string) {
    return this.http
      .post(`${this.apiUrl}/user/mark-notifications`, {
        operatorId: operatorId || this.accountService.operatorId || '',
        userEmail: userEmail || this.accountService.userEmail || '',
      })
      .toPromise<any>();
  }

  markNotificationAsRead(notificationId: string, operatorId?: string) {
    return this.http
      .patch(`${this.apiUrl}/user/mark-notifications`, {
        operatorId: operatorId || this.accountService.operatorId || '',
        ids: [notificationId],
      })
      .toPromise<any>();
  }

  getUtilizationReport(
    breezeId: string,
    days: string[]
  ): Promise<
    {
      dayNum: string;
      parkingId: string;
      carparks: number;
    }[]
  > {
    return this.http
      .post(`${this.apiUrl}/carpark/availabilities`, {
        carparkIds: [breezeId], // breeze ids
        days: days,
        // the days you want the logs for, for each day one entry should be present
      })
      .toPromise<any>()
      .then((res) => res.availabilities);
  }

  getCarparkLocationPins(carparkId: string) {
    return this.http
      .get(`${this.apiUrl}/carpark/${carparkId}/locationpins`)
      .toPromise<any>();
  }

  addUpdateCustomCarparkLocationPin(
    customPin: CarparkDefaultLocationPin,
    carparkId: string
  ) {
    return this.http
      .put(`${this.apiUrl}/carpark/${carparkId}/locationpins`, [
        {
          ...customPin,
          carparkId,
        },
      ])
      .toPromise();
  }

  addUpdateCarparkLocationMarkers(
    carparkLocationMarkers: CarparkLocationMarkers[],
    carparkId: string
  ) {
    return this.http
      .put(
        `${this.apiUrl}/carpark/${carparkId}/locationpins`,
        carparkLocationMarkers
      )
      .toPromise();
  }

  async getRevisionMessages(
    revisionDetails: {
      parentId: string;
      parentType: Revision['parentType']; // optional parameters id and type allow to further filter the collection
      type?: Revision['type'];
      id?: string;
    },
    filterActive = true
  ): Promise<Revision[]> {
    return this.http
      .get(`${this.apiUrl}/revisions`, {
        params: revisionDetails,
      })
      .toPromise()
      .then((res: any) => {
        // Note: 'published' messages are not handled properly in components as of now
        if (!filterActive) return res?.data;

        const activeRevisionMessages: Revision[] = [];
        res.data?.forEach((revision: Revision) => {
          revision.reviews = revision.reviews?.filter(
            (review) => review.reviewState !== 'published'
          );
          if (revision.reviews?.length) activeRevisionMessages.push(revision);
        });
        return activeRevisionMessages;
      });
  }

  addOrUpdateRevisionMessage(revisionDetails: {
    id: string;
    type: string;
    parentType: string;
    parentId: string;
    displayValue?: string;
    reviews: Review[];
  }): Promise<Revision> {
    revisionDetails.reviews[0] = {
      createdById: this.accountService.cognitoUsername,
      createdByName: this.accountService.userName || 'Admin',
      reviewId: null,
      ...revisionDetails.reviews[0],
    };
    return this.http
      .put(`${this.apiUrl}/revisions`, revisionDetails)
      .toPromise()
      .then((res: any) => {
        return res?.data;
      });
  }

  updateReviewableBlockReviewState(reviewStateUpdateDetails: {
    revisionId: string;
    type: Revision['type'];
    parentType: Revision['parentType'];
    parentId: string;
    reviewState: Review['reviewState'];
  }) {
    this.http
      .patch(`${this.apiUrl}/revisions/reviewable`, reviewStateUpdateDetails)
      .toPromise()
      .then((res) => res);
  }

  async createAccountByAdmin(accountDetails: {
    operatorName: string;
    email: string;
    officePhone?: string;
    website?: string;
    carparks?: {
      carparkId: string;
      isRemoved?: boolean;
    }[];
    organisationName: string;
    password: string;
    isPermanent?: boolean;
  }) {
    if (accountDetails.isPermanent === undefined)
      accountDetails.isPermanent = false;
    return this.http
      .post(`${this.apiUrl}/admin-users`, accountDetails)
      .toPromise()
      .then((res) => {
        return res;
      });
  }

  async updateAccountByAdmin(updatedAccountDetails: {
    email: string;
    role?: string;
    cognitoUsername: string;
    officePhone?: string;
    website?: string;
    operatorId?: string;
    operatorName?: string;
    carparks?: {
      carparkId: string;
      isRemoved?: boolean;
    }[];
  }) {
    return this.http
      .patch(`${this.apiUrl}/admin-users`, updatedAccountDetails)
      .toPromise()
      .then((res) => {
        return res;
      });
  }

  async updateAccountPasswordByAdmin(newPasswordDetails: {
    userName: string; // sub from cognito
    password: string;
    isPermanent?: boolean;
  }) {
    if (newPasswordDetails.isPermanent === undefined)
      newPasswordDetails.isPermanent = false;
    return this.http
      .patch(`${this.apiUrl}/admin-users/password`, newPasswordDetails)
      .toPromise()
      .then((res) => {
        return res;
      });
  }

  async getUsersByAdmin() {
    return this.http
      .get(`${this.apiUrl}/admin-users`)
      .toPromise<any>()
      .then((res) => {
        const { users } = res as { users: FetchedUserDetails[] };
        const mappedUserList = users.map(
          (user) =>
            ({
              email: user.email || user.userEmail || user['custom:work_email'],
              role: user['custom:role'],
              cognitoUsername: user.cognitoUsername,
              officePhone: user['custom:office_phone'],
              website: user.website,
              operatorName: user['custom:full_name'],
              operatorId: user.operatorId,
              organisationName: user.operatorName,
              carparks: user.carparks,
              deleted: user.deleted,
            } as MappedUserDetails)
        );
        this.mappedUserList = mappedUserList;
        return mappedUserList;
      });
  }

  async deleteUserByAdmin(deleteUserDetails: {
    email: string;
    deleted: boolean;
  }) {
    this.http
      .delete(`${this.apiUrl}/admin-users`, {
        body: deleteUserDetails,
      })
      .toPromise()
      .then((res) => {});
  }

  async searchPlaces(searchStr: string) {
    return this.http
      .get(`${this.apiUrl}/places/search`, {
        params: new HttpParams().set('s', 'g').set('q', searchStr),
      })
      .toPromise<any>();
  }

  async getGooglePlaceDetails(gPlaceId: string) {
    return this.http
      .get(`${this.apiUrl}/places/search/details`, {
        params: new HttpParams().set('gPlaceId', gPlaceId),
      })
      .toPromise<any>();
  }

  async createPlace(
    places: Array<Place>,
    amenities: Array<any>,
    placePins: Array<Pin>
  ) {
    return this.http
      .post(`${this.apiUrl}/places`, {
        places,
        amenities,
        pins: placePins,
      })
      .toPromise<any>();
  }

  async getBreezeCarDetailsById(carparkId: string) {
    return this.http
      .get(`${this.apiUrl}/preview/carpark/${carparkId}`)
      .toPromise()
      .then((res) => {
        return res;
      });
  }

  async getBreezeAmenityDetailsById(carparkId: string) {
    return this.http
      .get(`${this.apiUrl}/preview/amenities/carparks/${carparkId}`)
      .toPromise()
      .then((res) => {
        return res;
      });
  }

  async getTranslationsFromBE() {
    this.carparkKeyDisplayMapFromBE = await this.http
      .get(`${this.apiUrl}/translations`)
      .toPromise()
      .then((res) => (res as any).translations);
  }

  carparkKeyDisplayMapFromBE: {
    [param: string]: string;
  };

  async updateClosurePeriod(
    carparkId: string,
    closurePeriodDetails: {
      closureStartDate?: string;
      closureEndDate?: string;
      isScheduleToPermanentlyClose?: boolean;
    }
  ) {
    return (this.carparkKeyDisplayMapFromBE = await this.http
      .put(
        `${this.apiUrl}/carpark/closureStatus/${carparkId}`,
        closurePeriodDetails
      )
      .toPromise()
      .then((res) => (res as any).translations));
  }

  async deleteClosurePeriod(carparkId: string) {
    return (this.carparkKeyDisplayMapFromBE = await this.http
      .delete(`${this.apiUrl}/carpark/closureStatus/${carparkId}`)
      .toPromise()
      .then((res) => (res as any).translations));
  }

  async getCarparkAvailabilityThresholds() {
    return this.http
      .get(`${this.apiUrl}/carpark/threshold`)
      .toPromise()
      .then((res) => res as any);
  }

  async updateCarparkAvailabilityThresholds(reqBody: {
    availThreshold: number;
    minAlmostFullFeedback: number;
    minManyAvailableFeedback: number;
    lotsToAlmostFull: number;
    minFullFeedback: number;
  }) {
    return this.http
      .post(`${this.apiUrl}/carpark/threshold`, reqBody)
      .toPromise()
      .then((res) => res as any);
  }
}

export interface FetchedUserDetails {
  userEmail: string;
  cognitoUsername: string;
  operatorName: string;
  operatorId: string;
  operatorStatus: string;
  'custom:office_phone': string;
  'custom:full_name': string;
  sub: string;
  website: string;
  email_verified: boolean;
  'custom:work_email': string;
  'custom:role': string;
  email: string;
  carparks: string[];
  deleted: boolean;
}

export interface MappedUserDetails {
  email: string;
  role: string;
  cognitoUsername: string;
  officePhone: string;
  website: string;
  operatorName: string;
  carparks: string[];
  operatorId: string;
  deleted: boolean;
  organisationName: string;
}

export interface AvailabilityIntegrateApiDetails {
  operatorId: string;
  createdBy: string;
  apiUrl: string;
  supportEmail: string;
  authenticationDetails: {
    isRequired: boolean;
    email?: string;
    password?: string;
    token?: string;
  };
  remarks?: string;
  fileUploadDetails?: any;
}
export interface QueryObj {
  search?: string;
  limit?: number;
  page?: number;
  startTime?: string;
  endTime?: string;
  operatorId?: string;
}
