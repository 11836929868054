import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccountService } from '../../../services/account.service';
import { Review } from '../../revision.model';

@Component({
  selector: 'app-revision-message',
  templateUrl: './revision-message.component.html',
  styleUrls: ['./revision-message.component.scss'],
})
export class RevisionMessageComponent implements OnInit {
  @Input('reviewMessage') reviewMessage: Review;
  @Input('isAdmin') isAdmin = false;
  @Input('isStatic') isStatic = false;
  @Output() toggleReviewState = new EventEmitter<Review['reviewState']>();

  constructor(public accountService: AccountService) {}

  ngOnInit(): void {}

  toggleReviewMessageStatus(currentReviewState: Review['reviewState']) {
    this.toggleReviewState.emit(
      currentReviewState === 'approved' ? 'pending' : 'approved'
    );
  }
}
