import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormUtilsService {
  constructor() {}

  getFromFGAsFA(fg: FormGroup, formArrayName: string) {
    return (<FormArray>fg.get(formArrayName)).controls as FormGroup[];
  }

  getFromFGAsFG(fg: FormGroup, formGroupName: string) {
    return fg.get(formGroupName) as FormGroup;
  }

  getFromFGAsFC(fg: FormGroup, formControlName: string) {
    return fg.get(formControlName) as FormControl;
  }

  getAsFG(ac: AbstractControl) {
    return ac as FormGroup;
  }

  getAsFC(ac: AbstractControl) {
    return ac as FormControl;
  }

  getAsFA(ac: AbstractControl) {
    return ac as FormArray;
  }

  toggleValidation(
    fC: AbstractControl | null | undefined,
    toggleOn: boolean = true,
    validators: ValidatorFn | ValidatorFn[] = Validators.required
  ) {
    if (!fC) return;

    if (toggleOn) fC.addValidators(validators);
    else fC.removeValidators(validators);
    fC.updateValueAndValidity();
  }

  getErrorAfterTouched(ac: AbstractControl) {
    return ac.touched && ac.errors;
  }
}
