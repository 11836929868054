<input
  type="checkbox"
  [name]="multiselectName"
  [id]="checkboxId"
  [formControl]="fc"
/>
<label [for]="checkboxId" [class.minimal]="minimal">
  <i class="empty">
    <img src="/assets/images/icons/{{ uncheckedIcon }}" alt="" />
  </i>
  <i class="selected">
    <img src="/assets/images/icons/{{ checkedIcon }}" alt="" />
  </i>
  <span *ngIf="label">{{ label }}</span>
</label>
