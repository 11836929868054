<section class="header-section">
  <!-- <h2 class="question-header">Do you have Season parking plan?</h2> -->
  <div class="radio-button-section" [formGroup]="detailsFG">
    <p-radioButton
      [value]="true"
      label="Yes"
      [formControl]="fUtils.getFromFGAsFC(detailsFG, 'isEnabled')"
      [ngStyle]="{
        'margin-right': '30px'
      }"
      name="{{ id }}-isEnabled-radio"
    ></p-radioButton>
    <p-radioButton
      [value]="false"
      label="No"
      [formControl]="fUtils.getFromFGAsFC(detailsFG, 'isEnabled')"
      name="{{ id }}-isEnabled-radio"
    ></p-radioButton>
  </div>
</section>
<section
  class="form-section"
  [formGroup]="detailsFG"
  *ngIf="detailsFG.get('isEnabled')?.value"
>
  <div class="row">
    <div class="form-control">
      <label>Period of charge</label>
      <p-dropdown
        [options]="durationOptions"
        optionLabel="display"
        optionValue="value"
        formControlName="unit"
        styleClass="period-dropdown"
      ></p-dropdown>
    </div>
    <div
      class="form-control"
      [class.error]="
        detailsFG.get('charges')?.touched && detailsFG.get('charges')?.errors
      "
    >
      <label>Charging Rate</label>
      <span data-prefix="$" class="include-prefix">
        <input
          type="number"
          class="input-sm"
          placeholder="0.00"
          formControlName="charges"
        />
      </span>
      <span class="error-text">Enter charging rate</span>
    </div>
  </div>
  <div class="row" *ngIf="detailsFG.get('unit')?.value === 'other'">
    <div
      class="form-control"
      [class.error]="
        detailsFG.get('durationDetails')?.touched &&
        detailsFG.get('durationDetails')?.errors
      "
    >
      <label>What is the period of charge?</label>
      <input
        class="input-sm"
        placeholder="E.g. Every 2 months or every 2 weeks, etc."
        formControlName="durationDetails"
      />
      <span class="error-text">Enter period of charge</span>
    </div>
  </div>
  <div class="row">
    <div class="form-control">
      <label>Remarks  <span class="option">(optional)</span></label>
      <textarea
        formControlName="remarks"
        cols="30"
        rows="10"
        placeholder="Add details"
      ></textarea>
    </div>
  </div>
</section>
