import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import * as mapboxgl from 'mapbox-gl';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { AccountService } from '../../../services/account.service';
import { ApiService } from '../../../services/api.service';
import {
  CarparkCreationPages,
  CarparkService,
} from '../../../services/carpark.service';
import { CommonService } from '../../../services/common.service';
import { FormUtilsService } from '../../../services/form-utils.service';
import {
  LS_CARPARK_NAME,
  LS_IS_CAR_CREATE_MODE,
  LS_LOCATION_ADDRESS_DETAILS,
  LS_SELECTED_CARPARK_ID,
  LocalStorageService,
} from '../../../services/local-storage.service';
import { CustomConfirmService } from '../../../shared/components/custom-confirm/custom-confirm.service';
import { ConfirmationStatus } from '../../../shared/directives/confirm-proceed.directive';
import { makeConvertedSVG } from '../../generic-carpark-map-icon/generic-carpark-map-icon.component';
import { svgTemplateMap } from '../../generic-carpark-map-icon/svgTemplateMap';
import { SubmitTypes } from '../carpark-location/carpark-location.component';
import {
  BackButtonOptions,
  ConfirmProceedOptions,
  SaveExitConfirmOptions,
} from '../carpark-preview-web/carpark-preview-web.component';
import {
  CarparkDefaultLocationPin,
  CarparkLocationMarkers,
  PublishStatus,
} from '../carpark.model';

interface CustomLocation {
  latitude: number;
  longitude: number;
  isCurrentLocation: boolean;
}

const ColorList = [
  '#333333',
  '#BDBDBD',
  '#FF0000',
  '#F2994B',
  '#F2C94D',
  '#219654',
  '#28AF60',
  '#29CAD3',
  '#2F80EE',
  '#2D9DDB',
  '#9C52E0',
  '#E82370',
];

// todo: move these to a util file, or better yet, utilService, so as to make it singleton and do DI
// util functions for type completion in template files;
const getAsFG = (control: AbstractControl) => control as FormGroup;
const getAsFC = (control: AbstractControl) => control as FormControl;
const getAsFA = (control: AbstractControl) => control as FormArray;

export const DEFAULT_LOCATION = {
  latitude: 1.2746,
  longitude: 103.799,
};
export const CARPARK_MARKER_CSS_CLASS = 'gray-map-marker';

@Component({
  selector: 'app-carpark-entrances',
  templateUrl: './carpark-entrances.component.html',
  styleUrls: ['./carpark-entrances.component.scss'],
})
export class CarparkEntrancesComponent implements OnInit, OnDestroy {
  map: mapboxgl.Map;
  centerLong: number;
  centerLat: number;
  isCurrentLocation: boolean;
  currentDestinationMarker: mapboxgl.Marker;
  isDropped = false;
  selectedMarkerIconId: string;
  carParkName = '';
  isSaveExitEnabled = false;
  mapboxMarkerArray: any[] = [];
  markerCollection: {
    [cursorType: string]: any[]; // todo: this is not ideal
  } = {};
  carparkCursorList: string[] = [];
  markerFormGroup: FormGroup;
  currentCarparkId: string | null;
  colorList = ColorList;
  queryParams: any = null;
  isCreateMode: boolean;
  // todo: refactor to use/move these to Form Util services;
  getAsFG = getAsFG;
  getAsFC = getAsFC;
  getAsFA = getAsFA;
  publishStatus: PublishStatus;
  PublishStatuses = PublishStatus;
  confirmProceedOptions = ConfirmProceedOptions;
  markersFormArray: FormArray;
  showCustomIconModal = false;
  selectedColor = ColorList[0];
  revisionSelectedMarkerDisplayValue: string;
  revisionSelectedMarkerId: string;
  backButtonOptions = BackButtonOptions;
  defaultPins: CarparkDefaultLocationPin[];
  caparkLocationMarkers: CarparkLocationMarkers[];
  customPinNameFC: FormControl;
  showRevisionMessage = false;
  readonly LOCATIONPINNAME_MAXCOUNT = 32;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private confirmationService: CustomConfirmService,
    private apiService: ApiService,
    private messageService: MessageService,
    public fUtils: FormUtilsService,
    public accountService: AccountService,
    public cmnSrv: CommonService,
    public cpSrv: CarparkService,
    private lsSrv: LocalStorageService
  ) {
    const m = Object.getOwnPropertyDescriptor(mapboxgl, 'accessToken');
    m && m.set?.(environment.mapbox.accessToken);
    this.customPinNameFC = this.fb.control('', Validators.required);
    this.initMarkersFormArray();
  }

  initMarkersFormArray() {
    this.markersFormArray = this.fb.array([]);
  }

  async toggleRevisionMessageBlock(
    newBlockVisibility?: boolean,
    markerRevisionId?: string,
    displayValue?: string
  ) {
    if (displayValue) this.revisionSelectedMarkerDisplayValue = displayValue;
    if (markerRevisionId) this.revisionSelectedMarkerId = markerRevisionId;

    if (newBlockVisibility === undefined)
      this.showRevisionMessage = !this.showRevisionMessage;
    else this.showRevisionMessage = newBlockVisibility;

    // if (newBlockVisibility === false) {
    //   this.initMarkersFormArray();
    //   this.markerCollection = {};
    //   await this.getCarparkMapIcons();
    // }
  }

  ngOnDestroy() {
    this.map?.remove();
  }

  async ngOnInit() {
    this.map = new mapboxgl.Map({
      container: 'ezpark-map',
      style: environment.mapbox.styleLink,
      center: [DEFAULT_LOCATION.longitude, DEFAULT_LOCATION.latitude],
      zoom: 19,
    });

    this.currentCarparkId = this.lsSrv.getItem(LS_SELECTED_CARPARK_ID);
    await this.getCarparkMapIcons();
    if (this.currentCarparkId) await this.getCarparkById();

    if (!this.centerLat) {
      // centerLat maybe set inside getCarparkById function..
      const customLocation: CustomLocation =
        await this.getInitialCarparkLocation();
      this.centerLong = customLocation.longitude;
      this.centerLat = customLocation.latitude;
      this.isCurrentLocation = customLocation.isCurrentLocation;
    }

    this.map.on('load', async () => {
      this.resetCenter();
    });

    this.carParkName = this.lsSrv.getItem(LS_CARPARK_NAME) || this.carParkName;
    this.isCreateMode = this.lsSrv.getItem(LS_IS_CAR_CREATE_MODE) === '1';
  }

  async getCarparkMapIcons(skipRefresh = false) {
    if (!this.currentCarparkId) return;
    const result = await this.apiService.getCarparkLocationPins(
      this.currentCarparkId
    );
    this.defaultPins = result.data.defaultPins;
    if (skipRefresh) return;

    this.caparkLocationMarkers = result.data.icons;

    this.caparkLocationMarkers?.forEach((locationMarkerGroup) => {
      locationMarkerGroup?.markers
        ?.filter((marker) => !marker.deleted)
        .forEach((marker) => {
          this.createMarkerIconAndAddToFormObj({
            selectedIconId: locationMarkerGroup.iconId!,
            lngLat: [marker.lng, marker.lat],
            displayValue: marker.displayValue,
            description: marker.description,
            markerId: marker.id,
            deleted: marker.deleted, // this should always be false here since its getting filtered above
            revisionId: marker.revisionId,
            reviewState: marker.reviewState,
          });
        });
    });
  }

  getMapPinById(iconId: string) {
    return this.defaultPins.find((e) => e.iconId === iconId)!;
  }

  onDragStart(event: any, iconId: string) {
    this.selectedMarkerIconId = iconId;
    this.isDropped = false;
    this.map.once('mousemove', this.setMarker.bind(this));
  }
  onDragOver(event: any) {
    event.preventDefault();
  }
  onDrop(event: any) {
    this.isDropped = true;
  }

  openCustomPointModal() {
    this.showCustomIconModal = true;
    this.customPinNameFC.setValue('');
    this.customPinNameFC.markAsUntouched();
  }

  handleColorSelection(selectedColor: string) {
    this.selectedColor = selectedColor;
  }

  createCustomIcon() {
    if (this.customPinNameFC.errors) {
      this.customPinNameFC.markAsTouched();
      return;
    }
    const customIcon: CarparkDefaultLocationPin = {
      displayValue: this.customPinNameFC.value,
      isDefault: true,
      iconBGColor: this.selectedColor,
    };

    this.apiService
      .addUpdateCustomCarparkLocationPin(customIcon, this.currentCarparkId!)
      .then((res) => {
        this.getCarparkMapIcons(true);
        this.showCustomIconModal = false;
      });
  }
  cancelCreateCustomIcon() {
    this.showCustomIconModal = false;
    this.selectedColor = ColorList[0];
  }

  async getCarparkById() {
    const {
      data: { long, lat, name, publishStatus },
    } = await this.apiService.getCarparkById(<string>this.currentCarparkId);

    // setup CarparkLocation coordinates
    this.centerLat = lat!;
    this.centerLong = long!;
    this.isCurrentLocation = false;
    this.publishStatus = publishStatus!;
  }

  //#region mapbox interactions
  setMarker(e: mapboxgl.MapMouseEvent & mapboxgl.EventData) {
    // console.log(`A mouse event has occurred at ${e.lngLat}`); // debug-log
    if (!this.isDropped) return;
    this.createMarkerIconAndAddToFormObj({
      selectedIconId: this.selectedMarkerIconId,
      lngLat: e.lngLat.toArray() as [number, number],
      revisionId: null,
      openToolTip: true,
    });
    this.markersFormArray.markAsDirty();
    /* note: multiple of these can occur in sequence if the mousemove was not
     triggered for other 'cancelled' markers. so setting to false manually after
     first addition */
    this.isDropped = false;
  }

  createMarkerIconAndAddToFormObj(markerDetailsObj: {
    selectedIconId: string;
    lngLat: [number, number];
    displayValue?: string;
    description?: string;
    markerId?: number;
    deleted?: boolean;
    revisionId: string | null;
    reviewState?: string;
    openToolTip?: boolean;
  }) {
    const {
      selectedIconId,
      lngLat,
      displayValue,
      description,
      markerId,
      deleted,
      revisionId,
      reviewState,
      openToolTip = false,
    } = markerDetailsObj;

    const icon = document.createElement('div');
    icon.className = 'carpark-marker-icon';
    let marker: mapboxgl.Marker | null = null;

    const selectedIcon = this.getMapPinById(selectedIconId);
    if (!deleted) {
      const svgTemplate =
        (selectedIcon.iconName && svgTemplateMap[selectedIcon.iconName]) ||
        svgTemplateMap._customIcon;
      let totalMarkers = 0;
      this.markersFormArray.controls.forEach((element) => {
        totalMarkers += element?.value.markers.filter(
          (marker: any) => marker.deleted === false
        ).length;
      });
      if (totalMarkers < 10) {
        icon.innerHTML = makeConvertedSVG(svgTemplate, selectedIcon);
        marker = new mapboxgl.Marker(icon, { draggable: true }) // this closure to be used during marker deletion
          .setLngLat(lngLat)
          .addTo(this.map);
        if (openToolTip) {
          const dragInfoPopup = new mapboxgl.Popup({
            anchor: 'bottom',
            maxWidth: '500px',
            closeOnClick: false,
            closeButton: false,
            offset: {
              bottom: [0, 60],
            },
            className: 'location-pin-map-tooltip',
          });
          marker.setPopup(dragInfoPopup);
          marker.togglePopup();
          marker.getPopup().setHTML(`
          <div class="location-pin-info-tooltip">
            Place pin on the exact location
          </div>
        `);
          marker.on('dragend', () => {
            marker?.getPopup()?.remove();
          });
        }
        this.mapboxMarkerArray.push(marker);
      } else {
        const alertInfor = () => {
          this.confirmationService.confirm({
            message: [
              `You've reached the limit of 10 location pins. Please delete one of the added pins before creating a new one.`,
            ],
            rejectVisible: false,
            acceptLabel: 'Okay',
          });
        };
        alertInfor();
      }
    }
    if (!marker) {
      return;
    }
    const selectedMarkerGroupInd = (
      this.markersFormArray.value as CarparkLocationMarkers[]
    ).findIndex((e) => e.iconId === selectedIconId);

    let selectedMarkerFormGroup: FormGroup;

    if (selectedMarkerGroupInd !== -1) {
      selectedMarkerFormGroup = this.markersFormArray.at(
        selectedMarkerGroupInd
      ) as FormGroup;
    } else {
      const groupObj = {
        ...selectedIcon,
        isDefault: false,
        carparkId: this.currentCarparkId,
        markers: this.fb.array([]),
        revisionId,
      };
      delete groupObj._id;
      selectedMarkerFormGroup = this.fb.group(groupObj);
      this.markersFormArray.push(selectedMarkerFormGroup);
    }
    const innerMarkersFA = selectedMarkerFormGroup.get('markers') as FormArray;

    const newMarkerFG = this.fb.group({
      ...(!!marker && marker.getLngLat()), // lat and lng fields
      displayValue: this.fb.control(
        displayValue || '',
        !deleted ? [Validators.required] : []
      ),
      description: this.fb.control(description),
      deleted: deleted || false,
      revisionId,
      reviewState: [reviewState],
    });
    innerMarkersFA.push(newMarkerFG);

    if (!deleted && marker) {
      const markerInputId =
        selectedIconId + '_marker_' + (innerMarkersFA.length - 1);
      // focus on created marker input when new marker created (displayValue is
      // empty when new markers are drag-dropped)
      !displayValue &&
        setTimeout(() => {
          document.getElementById(markerInputId)?.focus();
        });
      // click triggers deletion
      marker.getElement().addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        const currentMakerIndex = this.markerCollection[
          selectedIconId
        ].findIndex((e) => e === marker); // marker from closure
        // selectedIconId from closure
        this.deleteMarker(selectedIconId, currentMakerIndex, newMarkerFG);
      });
      // setup dragEnd listener to update the the LngLat values in form array
      marker.on('dragend', () => {
        // newMarkerFG from closure
        newMarkerFG.markAsDirty();
        newMarkerFG.patchValue({
          ...marker?.getLngLat(), // changed values get patched
        });
      });
      const mapboxMarkerCollectionForId = (this.markerCollection[
        selectedIconId
      ] = this.markerCollection[selectedIconId] || []);
      mapboxMarkerCollectionForId.push(marker);
    }
  }

  getInitialCarparkLocation() {
    return new Promise<CustomLocation>(async (resolve, reject) => {
      const locationDetails =
        this.lsSrv.getItem(LS_LOCATION_ADDRESS_DETAILS) || null;
      if (locationDetails) {
        const { locationAddress } = JSON.parse(locationDetails);
        return resolve({
          latitude: Number(locationAddress.lat),
          longitude: Number(locationAddress.long),
          isCurrentLocation: false,
        });
      }

      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            isCurrentLocation: true,
          });
        },
        (error) => {
          resolve({
            ...DEFAULT_LOCATION,
            isCurrentLocation: false,
          });
        },
        { enableHighAccuracy: true }
      );
    });
  }

  resetCenter() {
    if (this.currentDestinationMarker) {
      this.currentDestinationMarker.remove();
    }
    const el = document.createElement('div');
    el.className = `${CARPARK_MARKER_CSS_CLASS}`;
    this.currentDestinationMarker = new mapboxgl.Marker(el, { draggable: true })
      .setLngLat([this.centerLong, this.centerLat])
      .addTo(this.map);

    const zoom = this.map.getZoom();
    this.map.flyTo({
      zoom: zoom,
      center: [this.centerLong, this.centerLat],
    });

    this.currentDestinationMarker.on('dragend', () => {
      [this.centerLong, this.centerLat] = this.currentDestinationMarker
        .getLngLat()
        .toArray();
      this.markersFormArray.markAsDirty();
    });
  }

  findMarker(markerIconId: string, index: number) {
    const marker = this.markerCollection[markerIconId][index];
    const zoom = this.map.getZoom();
    this.map.flyTo({
      zoom: zoom,
      center: marker.getLngLat(),
    });
  }

  isActiveMarkersPresent(parentMarkerObj: CarparkLocationMarkers) {
    return parentMarkerObj.markers.some((e) => !e.deleted);
  }

  deleteMarker(markerIconId: string, _index: number, markerFG?: FormGroup) {
    let markerDisplayValue = '';
    let index = _index;

    // global values for this function
    const markerGrpInd = (
      this.markersFormArray.value as CarparkLocationMarkers[]
    ).findIndex((e) => e.iconId === markerIconId);

    const markerGrp = this.markersFormArray.at(markerGrpInd);
    const selectedMarkerGroupArray = markerGrp.get('markers') as FormArray;
    const markerTypeName = markerGrp.value.displayValue;

    if (markerFG) index = selectedMarkerGroupArray.controls.indexOf(markerFG);
    markerDisplayValue =
      selectedMarkerGroupArray.controls[index].value.displayValue || '';
    const selectedMarkerIconValue =
      selectedMarkerGroupArray.controls[index].value;

    // function for actual deletion
    const deleteMarker = () => {
      // delete from collection
      const [markerToBeDeleted] = this.markerCollection[markerIconId].splice(
        _index,
        1
      );
      // delete from mapbox
      markerToBeDeleted.remove();
      selectedMarkerGroupArray.removeAt(index);
      selectedMarkerGroupArray.markAsDirty();
      // if marker not added to BE then no point in calling the API
      if (!selectedMarkerIconValue.revisionId) return;

      const selectedMarker = this.caparkLocationMarkers.find(
        (e) => e.iconId === markerIconId
      )!;

      selectedMarker.markers =
        selectedMarker?.markers.map((e) =>
          e.revisionId === selectedMarkerIconValue.revisionId
            ? { ...e, deleted: true }
            : e
        ) || [];

      // send as deleted to backend
      this.apiService.addUpdateCarparkLocationMarkers(
        [selectedMarker],
        this.currentCarparkId!
      );
    };

    this.confirmationService.confirm({
      acceptLabel: 'Delete',
      rejectLabel: 'Cancel',
      header: 'Delete pin',
      message: [
        `Are you sure you want to delete <b>${markerTypeName} point</b>${
          markerDisplayValue ? `<b> at ${markerDisplayValue}</b>` : ''
        }?`,
      ],
      accept: () => {
        deleteMarker();
      },
    });
  }
  //#endregion mapbox interaction

  goBack(confirmValue: ConfirmationStatus) {
    if (confirmValue === ConfirmationStatus.CANCEL) return;
    this.cpSrv.handleBackButtonClick(CarparkCreationPages.ENTRANCES);
  }

  continue() {
    this.router.navigate(['/carpark', 'create', 'prices'], {
      queryParams: { ...this.queryParams },
    });
  }

  // todo: find the type here
  async saveEntrance(
    confirmationStatus: ConfirmationStatus,
    submitType: SubmitTypes = 'continue'
  ) {
    this.markersFormArray.markAllAsTouched();
    if (this.markersFormArray.invalid) {
      if (submitType === 'saveExit') {
        this.confirmationService.confirm({
          header: SaveExitConfirmOptions.header,
          message: SaveExitConfirmOptions.message,
          accept: () => {
            this.cpSrv.handleSaveExitClick(ConfirmationStatus.PROCEED);
          },
          acceptLabel: SaveExitConfirmOptions.confirmLabel,
          rejectLabel: SaveExitConfirmOptions.cancelLabel,
        });
      }
      return;
    }
    const markers = this.markersFormArray.value;
    if (!this.isValidMarkers(markers)) {
      console.log('Invalid Markers'); // todo: check what validations are required and what is to be shown
      this.confirmationService.confirm({
        message: ['Please give correct markers!'],
        rejectVisible: false,
        acceptLabel: 'Ok',
      });
      return;
    }

    if (confirmationStatus === ConfirmationStatus.CANCEL) return;
    const newPublishStatus = this.cpSrv.getNewPublishStatus(
      this.publishStatus,
      CarparkCreationPages.ENTRANCES,
      submitType
    );

    const carparkDetails: any = {
      lat: this.centerLat,
      long: this.centerLong,
      location: {
        type: 'Point',
        coordinates: [this.centerLong, this.centerLat],
      },
      publishStatus: newPublishStatus,
    };
    try {
      const result = await this.apiService.updateCarpark(carparkDetails);
      const locationMarkers = this.markersFormArray.value;
      const locationMarkerResult =
        await this.apiService.addUpdateCarparkLocationMarkers(
          locationMarkers,
          this.currentCarparkId!
        );
      if (submitType === 'submitChanges') {
        this.router.navigate(['/carpark', 'create', 'preview'], {
          queryParams: { edit: 1 },
        });
      } else if (submitType === 'continue') this.continue();
      else this.cpSrv.handleSaveExitClick(ConfirmationStatus.PROCEED);
    } catch (error) {
      console.log('error in updating entrances: ', error);
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Error',
        detail: 'Entrance update failed!',
      });
    }
  }

  isValidMarkers(markers: any) {
    // todo: update validation after checking
    return true;
    // simple check to see if every type has at least one value(disabled for now)
    return Object.values(markers).every((e: any) => e.length);
  }

  handleBlockReviewStateUpdate(newReviewStateDetails: {
    parentId: string;
    parentType: string;
    reviewState: string;
    revisionId: string;
    type: string;
  }) {
    for (let markerFG of this.markersFormArray.controls) {
      for (let marker of (markerFG.get('markers') as FormArray).controls) {
        if (marker.value.revisionId === newReviewStateDetails.revisionId) {
          marker
            .get('reviewState')
            ?.setValue(newReviewStateDetails.reviewState);
          return;
        }
      }
    }
  }
}
