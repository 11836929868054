import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { finalize, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoggedInStatus } from '../config/constants/general.constants';
import { EventService } from './event.service';
import {
  LSKeysToBeClearedOnForceLogout,
  LS_COGNITO_ACCESS_TOKEN,
  LS_COGNITO_ID_TOKEN,
  LS_COGNITO_REFRESH_TOKEN,
  LS_COGNITO_USERNAME,
  LS_IMPERSONATION_ACTIVE,
  LS_IS_ADMIN,
  LS_LOGGED_IN,
  LS_NEW_PASS_USER_ATTRIBUTES,
  LS_OPERATOR_ID,
  LS_OPERATOR_NAME,
  LS_OPERATOR_STATUS,
  LS_OPTED_INTEGRATION_TYPE,
  LS_USER_EMAIL,
  LS_USER_NAME,
  LS_USER_ROLE,
  LocalStorageService,
} from './local-storage.service';

enum AccountServiceMode {
  NOT_LOGGED_IN,
  FORGOT_PASSWORD,
  LOGGED_IN,
}

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private loggedInStatus = false;
  private cognitoUserPool: CognitoUserPool;
  private cognitoUser: CognitoUser | null;
  private cognitoAccessToken: string | null;
  private cognitoIdToken: string | null;
  private cognitoRefreshToken: string | null;
  private sessionUserAttributes: any;
  private _cognitoUsername: any;
  private _userEmail: any;
  private _userName: any;
  private _mode: AccountServiceMode;
  _operatorId: string | null;
  operatorName: string | null;
  private _operatorStatus: string | null;
  isImpersonating = false;
  optedIntegrationType: number;
  // below variable is used as a means to check loginPopup state in authGuard
  loginPopupTriggered = false;
  userRole: string | null;
  apiUrl: string;
  http: HttpClient;

  init() {
    this.loggedInStatus = false;
    this.cognitoUser = null;
    this.cognitoAccessToken = null;
    this.cognitoIdToken = null;
    this.cognitoRefreshToken = null;
    this.sessionUserAttributes = null;
    this._cognitoUsername = null;
    this._userEmail = null;
    this._userName = null;
    this._mode = AccountServiceMode.NOT_LOGGED_IN;
    this.operatorId = null;
    this.operatorName = null;
    this._operatorStatus = null;
    this.isImpersonating = false;
    this.optedIntegrationType = -1;
    this.userRole = null;
    this.loginPopupTriggered = false;
  }

  get operatorId() {
    if (this._operatorId) return this._operatorId;
    this._operatorId = this.lsSrv.getItem(LS_OPERATOR_ID) || null;
    return this._operatorId;
  }

  set operatorId(opId: string | null) {
    this._operatorId = opId;
    if (opId) this.lsSrv.setItem(LS_OPERATOR_ID, opId);
    else this.lsSrv.removeItem(LS_OPERATOR_ID);
  }

  get isAdminUser() {
    // todo: check if accessing localStorage frequently like inside a getter is a okay thing to do or not;
    return this.lsSrv.getItem(LS_IS_ADMIN) === '1' ? true : false;
  }

  get isSessionPresent() {
    return (this.cognitoUser as any)?.Session;
  }

  get operatorStatus() {
    this._operatorStatus =
      this._operatorStatus || this.lsSrv.getItem(LS_OPERATOR_STATUS) || null;
    return this._operatorStatus;
  }
  set operatorStatus(operatorStatus: string | null) {
    this._operatorStatus = operatorStatus;
    operatorStatus && this.lsSrv.setItem(LS_OPERATOR_STATUS, operatorStatus);
  }
  get cognitoUsername() {
    this._cognitoUsername =
      this._cognitoUsername || this.lsSrv.getItem(LS_COGNITO_USERNAME) || null;
    return this._cognitoUsername;
  }
  set cognitoUsername(userName: string) {
    this._cognitoUsername = userName;
    this.lsSrv.setItem(LS_COGNITO_USERNAME, userName);
  }

  get userEmail() {
    this._userEmail =
      this._userEmail || this.lsSrv.getItem(LS_USER_EMAIL) || null;
    return this._userEmail;
  }
  set userEmail(userEmail: string) {
    this._userEmail = userEmail;
    this.lsSrv.setItem(LS_USER_EMAIL, userEmail);
  }

  get userName() {
    this._userName = this._userName || this.lsSrv.getItem(LS_USER_NAME) || null;
    return this._userName;
  }
  set userName(userName: string) {
    this._userName = userName;
    this.lsSrv.setItem(LS_USER_NAME, userName);
  }

  setForgotPasswordFlow(flag: boolean) {
    this._mode = flag
      ? AccountServiceMode.FORGOT_PASSWORD
      : AccountServiceMode.NOT_LOGGED_IN;
  }

  isForgotPasswordFlow() {
    return this._mode === AccountServiceMode.FORGOT_PASSWORD;
  }

  removeLoader() {
    this.evtSvc.emit({
      name: 'remove-loader',
    });
  }

  addLoader() {
    this.evtSvc.emit({
      name: 'add-loader',
    });
  }

  constructor(
    private httpBackend: HttpBackend,
    private evtSvc: EventService,
    private lsSrv: LocalStorageService
  ) {
    this._mode = AccountServiceMode.NOT_LOGGED_IN;
    this.apiUrl = environment.apiUrl;
    // to fix the issue => https://stackoverflow.com/questions/45213352/httpinterceptor-service-httpclient-cyclic-dependency
    this.http = new HttpClient(httpBackend);
    this.loggedInStatus = !!Number(this.lsSrv.getItem(LS_LOGGED_IN));
    this.cognitoUserPool = new CognitoUserPool(
      environment.cognito.userPoolConfig
    );
    if (this.isLoggedIn()) {
      this.initialize();
    }
  }

  initialize() {
    this.cognitoUser = this.cognitoUserPool.getCurrentUser();
    // important note: somehow user session is not valid unless below function is called..
    this.cognitoUser?.getSession(
      async (err: null | Error, session: null | CognitoUserSession) => {
        if (session) {
          console.log('session.isValid: ', session.isValid());
          if (!session.isValid()) {
            const refreshToken = session.getRefreshToken();
            this.cognitoUser?.refreshSession(
              refreshToken,
              (err, newSession) => {
                if (newSession) {
                  this.initializeData(newSession);
                }
              }
            );
          } else {
            this.initializeData(session);
          }
        }
      }
    );
  }

  async initializeData(session: any) {
    this.cognitoUsername = session.getIdToken().payload['cognito:username']; // same as 'sub'?
    this.setLoginDetails(session);
    this.getCachedOperatorDetails();
    if (!this.operatorId) {
      await this.getUserDetails()
        .then((e) => console.log('received User details'))
        .catch((e) => console.log('error when getting user details'));
    }
    this.getOperatorDetails()
      .then((e) => console.log('received Operator Details'))
      .catch((e) => console.log('error when getting operator details'));

    this.isImpersonating =
      this.lsSrv.getItem(LS_IMPERSONATION_ACTIVE) == '1' ? true : false;
    return;
  }

  get currentUser() {
    return this.cognitoUser;
  }

  refreshSession() {
    const refreshTokenStr = this.lsSrv.getItem(LS_COGNITO_REFRESH_TOKEN);
    return new Promise<any>((resolve, reject) => {
      // return reject();
      if (!refreshTokenStr || !this.cognitoUser) {
        return reject();
      }
      this.cognitoUser.getSession(
        (err: Error | null, session: CognitoUserSession | null) => {
          if (!session) return reject();
          const refreshToken = session.getRefreshToken();
          this.cognitoUser?.refreshSession(refreshToken, (err, newSession) => {
            if (!newSession) return reject();
            this.setLoginDetails(newSession);
            this.getUserAttributes().then((res) => {
              this.userName =
                res?.result?.find((e) => e.Name === 'custom:full_name')
                  ?.Value || '';
            });
            return resolve(true);
          });
        }
      );
    });
  }

  private setLoginDetails(userSession: CognitoUserSession, email?: string) {
    this.cognitoAccessToken = userSession.getAccessToken().getJwtToken();
    this.cognitoIdToken = userSession.getIdToken().getJwtToken();
    this.cognitoRefreshToken = userSession.getRefreshToken().getToken();
    this.userEmail = this.lsSrv.getItem(LS_USER_EMAIL) || '';
    this.lsSrv.setItem(LS_COGNITO_ACCESS_TOKEN, this.cognitoAccessToken);
    this.lsSrv.setItem(LS_COGNITO_ID_TOKEN, this.cognitoIdToken);
    this.lsSrv.setItem(LS_COGNITO_REFRESH_TOKEN, this.cognitoRefreshToken);
    this.lsSrv.setItem(LS_LOGGED_IN, LoggedInStatus.LOGGED_IN);
    if (email) {
      this.lsSrv.setItem(LS_USER_EMAIL, email);
      this.userEmail = this.lsSrv.getItem(LS_USER_EMAIL) || '';
    }
    this.loggedInStatus = true;
  }

  private clearLoginDetails(clearAll = true) {
    this.init();
    if (clearAll) {
      this.lsSrv.clear();
    } else {
      LSKeysToBeClearedOnForceLogout.forEach((lsKey) =>
        this.lsSrv.removeItem(lsKey)
      );
    }

    this.lsSrv.setItem(LS_LOGGED_IN, LoggedInStatus.LOGGED_OUT);
    this.loggedInStatus = false;
  }

  login(email: string, password: string) {
    const userData = {
      Username: email,
      Pool: this.cognitoUserPool,
    };
    this.cognitoUser = new CognitoUser(userData);
    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    this.lsSrv.setItem(LS_USER_EMAIL, email);

    return new Promise<{
      isLoggedIn?: boolean;
      isNewPassRequired?: boolean;
      userAttributes?: any;
    }>((resolve, reject) => {
      this.cognitoUser?.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          // console.log('result: ', result); // debug-log
          this.setLoginDetails(result, email);
          resolve({ isLoggedIn: true });
        },
        onFailure: (err) => {
          console.log('err: ', err);
          this.lsSrv.setItem(LS_LOGGED_IN, LoggedInStatus.LOGGED_OUT);
          resolve({ isLoggedIn: false });
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          // the api doesn't accept this field back
          delete userAttributes.email_verified;
          // store userAttributes on global variable
          this.sessionUserAttributes = userAttributes;
          this.lsSrv.setItem(LS_USER_EMAIL, email);
          this.lsSrv.setItem(
            LS_NEW_PASS_USER_ATTRIBUTES,
            JSON.stringify(userAttributes)
          );
          this.userEmail = email;
          resolve({ isNewPassRequired: true });
        },
      });
    });
    /*     
      Note: refer "Use case 4" and "Use case 23" sections of
      https://www.npmjs.com/package/amazon-cognito-identity-js, for more details
     */
  }

  updateNewPassword(newPassword: string) {
    return new Promise<boolean>((resolve) => {
      this.cognitoUser?.completeNewPasswordChallenge(
        newPassword,
        this.sessionUserAttributes,
        {
          onSuccess: (result) => {
            // console.log('result: ', result); // debug-log
            this.cognitoUsername =
              result.getIdToken().payload['cognito:username'];
            this.setLoginDetails(result);
            resolve(true);
          },
          onFailure: (error) => {
            console.log('error: ', error); // debug-log
            resolve(false);
          },
        }
      );
    });
  }

  // Use case 11 of https://www.npmjs.com/package/amazon-cognito-identity-js
  changePassword(currentPassword: string, newPassword: string) {
    return new Promise<boolean>((resolve) => {
      this.cognitoUser?.changePassword(
        currentPassword,
        newPassword,
        function (err, result) {
          if (err) {
            resolve(false);
            return;
          }
          console.log('call result: ' + result);
          resolve(true);
        }
      );
    });
  }

  logout(clearAll = true) {
    this.cognitoUser?.signOut();
    this.clearLoginDetails(clearAll);
  }

  isLoggedIn() {
    return this.loggedInStatus;
  }

  getAccessToken() {
    if (this.cognitoAccessToken) return this.cognitoAccessToken;
    this.cognitoAccessToken =
      this.lsSrv.getItem(LS_COGNITO_ACCESS_TOKEN) || null;
    return this.cognitoAccessToken;
  }

  getIdToken() {
    if (this.cognitoIdToken) return this.cognitoIdToken;
    this.cognitoIdToken = this.lsSrv.getItem(LS_COGNITO_ID_TOKEN) || null;
    return this.cognitoIdToken;
  }

  getUserAttributes() {
    return new Promise<{
      success: boolean;
      result?: CognitoUserAttribute[];
      error?: Error;
    }>((resolve, reject) => {
      this.cognitoUser?.getUserAttributes((error, result) => {
        if (error) {
          console.log('error in getting user attributes: ', error); // debug-log
          return resolve({
            success: false,
            error,
          });
        }
        if (result) return resolve({ success: true, result });
      });
    });
  }

  // todo: define type once all attribute names are decided
  updateUserAttributes(userAttributes: any) {
    const attributeList = Object.entries<string>(userAttributes).map(
      ([Name, Value]) =>
        new CognitoUserAttribute({
          Name,
          Value,
        })
    );
    return new Promise<
      { success: boolean; result: string } | { success: boolean; error: Error }
    >((resolve, reject) => {
      this.cognitoUser?.updateAttributes(attributeList, (error, result) => {
        if (error)
          return resolve({
            success: false,
            error,
          });
        if (result) return resolve({ success: true, result });
      });
    });
  }

  setOperatorDetails(operatorId: string, operatorName: string) {
    this.operatorId = operatorId;
    this.operatorName = operatorName;
    this.lsSrv.setItem(LS_OPERATOR_ID, operatorId);
    this.lsSrv.setItem(LS_OPERATOR_NAME, operatorName);
  }

  /** this function also sets the public variables operatorId and operatorName
   * fetched from Localstorage if they are initially null*/
  getCachedOperatorDetails() {
    let operatorId, operatorName;
    if (this.operatorId)
      // && this.operatorName ?
      return {
        operatorId,
        operatorName,
      };

    operatorId = this.operatorId = this.lsSrv.getItem(LS_OPERATOR_ID) || null;
    operatorName = this.operatorName =
      this.lsSrv.getItem(LS_OPERATOR_NAME) || null;
    return {
      operatorId,
      operatorName,
    };
  }

  async getUserDetails() {
    console.log('this.operatorId: ', this.operatorId);
    if (this.operatorId && this.userRole) {
      this.lsSrv.setItem(LS_USER_ROLE, this.userRole);
      this.lsSrv.setItem(LS_OPERATOR_ID, this.operatorId);
      return { isAdmin: this.lsSrv.getItem(LS_IS_ADMIN) };
    }
    this.addLoader();
    const userDetails: any = await this.http
      .get(`${this.apiUrl}/user`, {
        params: {
          ...(this.userEmail && { userEmail: this.userEmail }),
        },
        headers: {
          Authorization: `Bearer ${this.getIdToken()}`,
        },
      })
      .pipe(finalize(() => this.removeLoader()))
      .toPromise();
    const {
      data: { operatorId, role, cognitoUsername, isAdmin },
    } = userDetails;
    if (this.isImpersonating) {
      this.operatorId = this.lsSrv.getItem(LS_OPERATOR_ID);
      this.userRole = this.lsSrv.getItem(LS_USER_ROLE);
      return userDetails;
    }
    this.lsSrv.setItem(LS_IS_ADMIN, isAdmin ? '1' : '0');
    this.lsSrv.setItem(LS_OPERATOR_ID, (this.operatorId = operatorId));
    this.lsSrv.setItem(LS_USER_ROLE, (this.userRole = role));
    return userDetails;
  }

  async isAdmin(userEmail: string) {
    return this.http
      .get(`${this.apiUrl}/user/is-admin`, {
        headers: {
          Authorization: `Bearer ${this.getIdToken()}`,
        },
        params: {
          userEmail,
        },
      })
      .pipe(
        tap((result: any) => {
          this.lsSrv.setItem(LS_IS_ADMIN, result?.isAdmin ? '1' : '0');
        })
      )
      .toPromise<any>();
  }

  async getOperatorDetails() {
    this.addLoader();
    return this.http
      .get(`${this.apiUrl}/operator/${this.operatorId}`, {
        headers: {
          Authorization: `Bearer ${this.getIdToken()}`,
        },
      })
      .pipe(
        tap((result: any) => {
          this.operatorStatus = result?.data?.operatorStatus || null;
          this.operatorName = result?.data?.operatorName || null;
          this.optedIntegrationType =
            result?.data?.optedIntegrationType || null;
          if (this.operatorStatus)
            this.lsSrv.setItem(LS_OPERATOR_STATUS, this.operatorStatus);
          if (this.operatorName)
            this.lsSrv.setItem(LS_OPERATOR_NAME, this.operatorName);
          if (this.optedIntegrationType)
            this.lsSrv.setItem(
              LS_OPTED_INTEGRATION_TYPE,
              this.optedIntegrationType.toString()
            );
        }),
        finalize(() => this.removeLoader())
      )
      .toPromise<any>();
  }

  initResetPassword(userEmail: string) {
    this.evtSvc.emit({
      name: 'add-loader',
    });
    return this.http
      .get(`${this.apiUrl}/user/reset-password/init`, {
        params: { userEmail },
      })
      .toPromise()
      .finally(() => {
        this.evtSvc.emit({
          name: 'remove-loader',
        });
      });
  }

  resetPassword(token: string, password: string) {
    this.evtSvc.emit({
      name: 'add-loader',
    });
    return this.http
      .post(`${this.apiUrl}/user/reset-password`, { token, password })
      .toPromise()
      .finally(() => {
        this.evtSvc.emit({
          name: 'remove-loader',
        });
      });
  }
}
