<div class="new-caparklist-wrapper">
  <section class="header">
    <h2>Carpark List</h2>
    <button (click)="createNewCarpark()">Create New Carpark</button>
  </section>
  <section class="search-bar">
    <input
      type="text"
      placeholder="Search ID or Name of carpark"
      [formControl]="searchTextControl"
    />
    <i class="pi pi-search"></i>
  </section>

  <div class="table">
    <app-table-header-wrapper>
      <div class="cell id">ID</div>
      <div class="cell name">Name</div>
      <div class="cell location">Location</div>
      <div class="cell status" [class.active]="statusFilterActive">
        <span>Status</span>
        <i
          class="pi pi-filter-fill"
          (click)="statusSelectVisible = !statusSelectVisible"
        ></i>
        <app-custom-multiselect
          *ngIf="statusSelectVisible"
          [showAll]="true"
          [options]="statusOptions"
          [multiselectName]="'carparkPublishStatus'"
          [multiSelectFA]="statusFilterFA"
          (close)="statusSelectVisible = false"
        >
        </app-custom-multiselect>
      </div>
      <div class="cell action">Action</div>
    </app-table-header-wrapper>
    <ng-container *ngIf="currentPageData$ | async as carparks">
      <ng-container *ngIf="carparks.length; else noCarparkTemplate">
        <app-table-row-wrapper
          *ngFor="let carpark of carparks"
          (click)="navigateOnRowClick(carpark)"
        >
          <div class="cell id">{{ carpark.id }}</div>
          <div class="cell name">
            <span class="liveInBreeze-pill" *ngIf="carpark.liveInBreeze"
              >Live</span
            >
            <span>{{ carpark.name }}</span>
          </div>
          <div class="cell location">{{ carpark.address }}</div>
          <div class="cell status">
            <span
              class="publishStatus-pill"
              [ngClass]="carpark?.publishStatus || 'imported'"
              [ngStyle]="{
                color: cpSrv.getCarparkStatusAttributes(carpark.publishStatus)
                  .color,
                'background-color': cpSrv.getCarparkStatusAttributes(
                  carpark.publishStatus
                ).backgroundColor
              }"
              >{{
                cpSrv.getCarparkStatusAttributes(carpark.publishStatus)
                  .displayText
              }}</span
            >
            <!-- <span *ngIf="carpark.closureStatus" class="closureStatus-pill">{{
              cpSrv.getCarparkClosureStatusText(carpark.closureStatus, true)
            }}</span> -->
          </div>
          <div
            class="cell actions"
            [class.admin-actions]="accountService.isAdminUser"
          >
            <ng-container *ngIf="!accountService.isAdminUser">
              <ng-container *ngIf="!(carpark.publishStatus == 'archived')">
                <img
                  src="/assets/images/icons/broadcast-disabled.png"
                  class="broadcast"
                  *ngIf="!carpark.liveInBreeze"
                />
                <img
                  (click)="openCreateBroadcast($event, carpark)"
                  src="/assets/images/icons/broadcast-enabled.png"
                  class="broadcast"
                  *ngIf="carpark.liveInBreeze"
                />
                <img
                  (click)="openCreateBroadcast($event, carpark)"
                  src="/assets/images/icons/delete-icon.png"
                  title="Delete"
                  class="broadcast"
                  (click)="deleteCarpark($event, carpark)"
                  *ngIf="
                    cpSrv.isDeleteEnabled(carpark.publishStatus!) &&
                    !carpark.liveInBreeze
                  "
                />
              </ng-container>
            </ng-container>
            <ng-container
              *ngIf="
                accountService.isAdminUser &&
                carpark.publishStatus !== 'archived'
              "
            >
              <button
                class="reject-btn"
                [disabled]="carpark.publishStatus === 'rejected'"
                (click)="rejectCarpark($event, carpark)"
              >
                Reject
              </button>
              <button
                class="accept-btn"
                [disabled]="carpark.publishStatus === 'published'"
                (click)="acceptCarpark($event, carpark)"
              >
                Approve
              </button>
            </ng-container>
          </div>
        </app-table-row-wrapper>
      </ng-container>
    </ng-container>
  </div>
  <p-paginator
    [rows]="ITEMS_PER_PAGE"
    [totalRecords]="totalRecords"
    (onPageChange)="onPageChange($event)"
    #paginator
  ></p-paginator>
  <app-carpark-list-footer></app-carpark-list-footer>
</div>
<ng-template #noCarparkTemplate>
  <app-table-nocontent
    noContentText="No carparks to list!"
  ></app-table-nocontent>
</ng-template>
