export interface Review {
  reviewId?: string | null; // FE to keep that null initially => send revisionId + reviewId for updating status
  createdById?: string;
  createdByName?: string;
  createdAt?: Date;
  reviewState?: 'pending' | 'approved' | 'published'; // "pending"(initial) | "approved"(once its individually approved or carpark as a whole is approved)
  message?: string; // actual message
  deleted?: boolean; // for future use
}

export interface Revision {
  id: string;
  type:
    | 'locationpin' // note so at this point this field is relevant for BE too..  so have to use locationpin instead of location-pin..
    | 'rate-day-block'
    | 'broadcast-message'
    | 'website-url'
    | 'carpark-more-info' // to be used mainly by FE side
    | 'season-rate-block'
    | 'season-contact-email';
  parentType: 'carpark' | 'broadcast';
  parentId: string;
  displayValue?: string; // either we have to use this as displayValue or map it back to the revisionId inside the carpark/broadcast obj etc.
  reviews?: Review[];
}

export const RevisionTypeDisplayMap: {
  [key in Revision['type']]: string;
} = {
  locationpin: 'Location pins',
  'rate-day-block': 'Parking charges',
  'broadcast-message': 'Broadcast Messages',
  'website-url': 'Carpark Website Url',
  'carpark-more-info': 'Carpark more info',
  'season-rate-block': 'Season parking',
  'season-contact-email': 'Season contact email',
};
