import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum ConfirmEventType {
  ACCEPT,
  REJECT,
  CANCEL,
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private requireConfirmationSource = new Subject();
  requireConfirmation$ = this.requireConfirmationSource.asObservable();

  constructor() {}

  confirm() {
    this.requireConfirmationSource.next(confirm);
  }
}
