<p-dialog
  [(visible)]="showModal"
  styleClass="generic-confirm-modal"
  [modal]="true"
  [closable]="false"
>
  <div class="generic-confirm-modal__body">
    <section class="header">
      <h3>{{ header }}</h3>
      <i class="pi pi-plus" (click)="onCancel()"></i>
    </section>
    
    <section class="generic-confirm-modal__content">
      <p [innerHTML]="description"></p>
    </section>
    <section class="actions">
      <button class="generic-confirm-modal__cancel-btn" (click)="onCancel()">
        {{ cancelText }}
      </button>
      <button class="generic-confirm-modal__confirm-btn" (click)="onConfirm()">
        {{ confirmText }}
      </button>
    </section>
  </div>
</p-dialog>
