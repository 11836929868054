import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { formatDistance } from 'date-fns';
import { ApiService } from '../../services/api.service';
import {
  LS_IS_CAR_CREATE_MODE,
  LS_SELECTED_CARPARK_ID,
  LocalStorageService,
} from '../../services/local-storage.service';

export interface Notification {
  _id: string;
  content: string;
  createdOn: string;
  operatorId: string;
  read: boolean;
  refId: string;
  refType: string;
  /* 
    ReferenceTypeFormats
    For carpark: carpark
    For broadcasts: broadcasts
    For revisions:
        locationpin:carpark
        rate-day-block:carpark
        broadcast-message:broadcast
  */

  title: string;
}

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  @Input('notifications') notificationList: any[] = [];
  @Output() markAllAsRead = new EventEmitter<string>();

  constructor(
    private lsSrv: LocalStorageService,
    private router: Router,
    private apiService: ApiService
  ) {}

  ngOnInit(): void {}

  markNotificationsAsRead() {
    this.markAllAsRead.emit('mark_as_read');
  }

  areNotificationsRead() {
    return this.notificationList.every((n) => n.read);
  }

  getFormattedTimeDistance(_date: string) {
    const date = new Date(_date);
    return formatDistance(date, new Date(), { addSuffix: true });
  }

  async handleNotificationRouting(notification: Notification) {
    const { refId, refType } = notification;
    if (!notification.read) {
      await this.apiService.markNotificationAsRead(notification._id);
      notification.read = true;
    }
    if (!refId || !refType) return;

    const refreshPage = () => {
      setTimeout(() => {
        window.location.reload();
      }, 200);
    };

    const [first, second] = refType.split(':');
    const navigateToCP = (carparkId: string) => {
      this.lsSrv.setItem(LS_SELECTED_CARPARK_ID, carparkId);
      this.lsSrv.removeItem(LS_IS_CAR_CREATE_MODE);
      this.router
        .navigate(['carpark', 'create', 'preview'], {
          queryParams: {
            edit: '1',
          },
        })
        .then(refreshPage);
    };

    const navigateToBC = (broadcastId: string) => {
      this.router
        .navigate(['broadcast', 'create'], {
          queryParams: {
            id: broadcastId,
            mode: 'preview',
          },
        })
        .then(refreshPage);
    };

    if (second === undefined) {
      // means this is not a revision message, so can redirect to carpark or broadcastMessage of specific id
      if (first === 'carpark') {
        navigateToCP(refId);
      } else if (first === 'broadcast') {
        navigateToBC(refId);
      }
    } else {
      // this is for a notification triggered from a revision messages
      const [id, parentId] = refId.split(':');
      if (second === 'carpark') {
        navigateToCP(parentId);
      } else if (second === 'broadcast') {
        navigateToBC(parentId);
      }
    }
  }
}
