export const svgTemplateMap: any = {
  'door-entry': `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M17.46 31.9765L17.459 31.9744C15.8205 28.7945 14.1497 26.4237 12.9708 25.2402C11.6566 23.921 10.0973 22.1435 8.8724 20.1632C7.63995 18.1706 6.80078 16.0605 6.80078 14.0541C6.80078 6.81892 12.6028 1 19.8008 1C26.9987 1 32.8008 6.81892 32.8008 14.0541C32.8008 16.1303 32.03 18.1641 30.849 20.0842C29.6689 22.0028 28.1146 23.7506 26.6308 25.2402C25.4632 26.4123 23.6792 28.7785 22.1309 31.9979C21.2908 33.7343 20.7547 35.4727 20.342 36.8109C20.2856 36.9939 20.2314 37.1694 20.1792 37.3364C20.0418 37.7752 19.923 38.1359 19.8136 38.4248C19.7101 38.1374 19.5975 37.78 19.4654 37.3446C19.4327 37.237 19.3992 37.1259 19.3647 37.0116C18.9516 35.6414 18.3995 33.8104 17.46 31.9765Z" fill="#FF8A19" stroke="#FF8A19" stroke-width="2" stroke-miterlimit="10"/>
  <path d="M22.4062 13.6602L18.4688 9.94141C18.2773 9.75 18.0039 9.72266 17.7578 9.80469C17.5117 9.91406 17.375 10.1602 17.375 10.4062V12.375H13.875C13.3828 12.375 13 12.7852 13 13.25V15C13 15.4922 13.3828 15.875 13.875 15.875H17.375V17.8438C17.375 18.1172 17.5117 18.3633 17.7578 18.4727C18.0039 18.5547 18.2773 18.5273 18.4688 18.3359L22.4062 14.6172C22.6797 14.3711 22.6797 13.9062 22.4062 13.6602ZM24.375 8H22.625C22.1328 8 21.75 8.41016 21.75 8.875C21.75 9.36719 22.1328 9.75 22.625 9.75H24.375C24.8398 9.75 25.25 10.1602 25.25 10.625V17.625C25.25 18.1172 24.8398 18.5 24.375 18.5H22.625C22.1328 18.5 21.75 18.9102 21.75 19.375C21.75 19.8672 22.1328 20.25 22.625 20.25H24.375C25.8242 20.25 27 19.1016 27 17.625V10.625C27 9.17578 25.8242 8 24.375 8Z" fill="white"/>
  </svg>
  `,
  'door-exit': `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M33.8008 14.0541C33.8008 18.7027 30.3546 22.9189 27.3392 25.9459C26.2623 27.027 24.5392 29.2973 23.0316 32.4324C21.2008 36.2162 20.8777 40 19.8008 40C18.7239 40 18.5085 36.2162 16.57 32.4324C14.9546 29.2973 13.3392 27.027 12.2623 25.9459C9.57001 23.2432 5.80078 18.5946 5.80078 14.0541C5.80078 6.27027 12.0469 0 19.8008 0C27.5546 0 33.8008 6.27027 33.8008 14.0541Z" fill="#005FD0"/>
  <path d="M15.625 20.25H17.375C17.8398 20.25 18.25 19.8672 18.25 19.375C18.25 18.9102 17.8398 18.5 17.375 18.5H15.625C15.1328 18.5 14.75 18.1172 14.75 17.625V10.625C14.75 10.1602 15.1328 9.75 15.625 9.75H17.375C17.8398 9.75 18.25 9.36719 18.25 8.875C18.25 8.41016 17.8398 8 17.375 8H15.625C14.1484 8 13 9.17578 13 10.625V17.625C13 19.0742 14.1484 20.25 15.625 20.25ZM26.7812 13.6602L22.8438 9.94141C22.6523 9.75 22.3789 9.72266 22.1328 9.80469C21.8867 9.91406 21.75 10.1602 21.75 10.4062V12.375H18.25C17.7578 12.375 17.375 12.7852 17.375 13.25V15C17.375 15.4922 17.7578 15.875 18.25 15.875H21.75V17.8438C21.75 18.0898 21.8867 18.3359 22.1328 18.4453C22.3789 18.5273 22.6523 18.5 22.8438 18.3086L26.7812 14.5898C27.0547 14.3711 27.0547 13.9062 26.7812 13.6602Z" fill="white"/>
  </svg>
  `,
  'circle-tick': `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M33.8008 14.0541C33.8008 18.7027 30.3546 22.9189 27.3392 25.9459C26.2623 27.027 24.5392 29.2973 23.0316 32.4324C21.2008 36.2162 20.8777 40 19.8008 40C18.7239 40 18.5085 36.2162 16.57 32.4324C14.9546 29.2973 13.3392 27.027 12.2623 25.9459C9.57001 23.2432 5.80078 18.5946 5.80078 14.0541C5.80078 6.27027 12.0469 0 19.8008 0C27.5546 0 33.8008 6.27027 33.8008 14.0541Z" fill="#15B765"/>
  <path d="M13 14C13 10.1445 16.1172 7 20 7C23.8555 7 27 10.1445 27 14C27 17.8828 23.8555 21 20 21C16.1172 21 13 17.8828 13 14ZM23.1445 12.7969C23.4453 12.4961 23.4453 12.0312 23.1445 11.7305C22.8438 11.4297 22.3789 11.4297 22.0781 11.7305L19.125 14.6836L17.8945 13.4805C17.5938 13.1797 17.1289 13.1797 16.8281 13.4805C16.5273 13.7812 16.5273 14.2461 16.8281 14.5469L18.5781 16.2969C18.8789 16.5977 19.3438 16.5977 19.6445 16.2969L23.1445 12.7969Z" fill="white"/>
  </svg>
  `,
  taxi: `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M33.8008 14.0541C33.8008 18.7027 30.3546 22.9189 27.3392 25.9459C26.2623 27.027 24.5392 29.2973 23.0316 32.4324C21.2008 36.2162 20.8777 40 19.8008 40C18.7239 40 18.5085 36.2162 16.57 32.4324C14.9546 29.2973 13.3392 27.027 12.2623 25.9459C9.57001 23.2432 5.80078 18.5946 5.80078 14.0541C5.80078 6.27027 12.0469 0 19.8008 0C27.5546 0 33.8008 6.27027 33.8008 14.0541Z" fill="#00C1CD"/>
  <path d="M15.3333 13.3333L16.3333 10.3333H23.6667L24.6667 13.3333H15.3333ZM23.6667 16.6667C23.4015 16.6667 23.1471 16.5613 22.9596 16.3738C22.772 16.1862 22.6667 15.9319 22.6667 15.6667C22.6667 15.4015 22.772 15.1471 22.9596 14.9596C23.1471 14.772 23.4015 14.6667 23.6667 14.6667C23.9319 14.6667 24.1862 14.772 24.3738 14.9596C24.5613 15.1471 24.6667 15.4015 24.6667 15.6667C24.6667 15.9319 24.5613 16.1862 24.3738 16.3738C24.1862 16.5613 23.9319 16.6667 23.6667 16.6667ZM16.3333 16.6667C16.0681 16.6667 15.8138 16.5613 15.6262 16.3738C15.4387 16.1862 15.3333 15.9319 15.3333 15.6667C15.3333 15.4015 15.4387 15.1471 15.6262 14.9596C15.8138 14.772 16.0681 14.6667 16.3333 14.6667C16.5985 14.6667 16.8529 14.772 17.0404 14.9596C17.228 15.1471 17.3333 15.4015 17.3333 15.6667C17.3333 15.9319 17.228 16.1862 17.0404 16.3738C16.8529 16.5613 16.5985 16.6667 16.3333 16.6667ZM24.6133 10C24.48 9.61333 24.1067 9.33333 23.6667 9.33333H22V8H18V9.33333H16.3333C15.8933 9.33333 15.52 9.61333 15.3867 10L14 14V19.3333C14 19.5101 14.0702 19.6797 14.1953 19.8047C14.3203 19.9298 14.4899 20 14.6667 20H15.3333C15.5101 20 15.6797 19.9298 15.8047 19.8047C15.9298 19.6797 16 19.5101 16 19.3333V18.6667H24V19.3333C24 19.5101 24.0702 19.6797 24.1953 19.8047C24.3203 19.9298 24.4899 20 24.6667 20H25.3333C25.5101 20 25.6797 19.9298 25.8047 19.8047C25.9298 19.6797 26 19.5101 26 19.3333V14L24.6133 10Z" fill="white"/>
  </svg>
  `,
  loadingbay: `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M33.8008 14.0541C33.8008 18.7027 30.3546 22.9189 27.3392 25.9459C26.2623 27.027 24.5392 29.2973 23.0316 32.4324C21.2008 36.2162 20.8777 40 19.8008 40C18.7239 40 18.5085 36.2162 16.57 32.4324C14.9546 29.2973 13.3392 27.027 12.2623 25.9459C9.57001 23.2432 5.80078 18.5946 5.80078 14.0541C5.80078 6.27027 12.0469 0 19.8008 0C27.5546 0 33.8008 6.27027 33.8008 14.0541Z" fill="#778188"/>
  <g clip-path="url(#clip0_62971_53338)">
  <path d="M13 18.8389V11.7805C13 11.1997 13.3281 10.6637 13.875 10.4403L19.7375 8.05026C19.8906 7.98325 20.0875 7.98325 20.2406 8.05026L26.1031 10.4403C26.65 10.6637 27 11.1997 27 11.7805V18.8389C27 19.1516 26.7594 19.375 26.475 19.375H25.425C25.1188 19.375 24.9 19.1516 24.9 18.8389V12.942C24.9 12.5623 24.5719 12.2272 24.2 12.2272H15.8C15.4062 12.2272 15.1 12.5623 15.1 12.942V18.8389C15.1 19.1516 14.8594 19.375 14.575 19.375H13.525C13.2188 19.375 13 19.1516 13 18.8389ZM16.325 19.375C16.0187 19.375 15.8 19.1516 15.8 18.8389V17.5881H24.2V18.8389C24.2 19.1516 23.9594 19.375 23.675 19.375H16.325ZM15.8 15.4437H24.2V16.8733H15.8V15.4437ZM15.8 12.942H24.2V14.729H15.8V12.942Z" fill="white"/>
  </g>
  <defs>
  <clipPath id="clip0_62971_53338">
  <rect width="16" height="16" fill="white" transform="translate(12 6)"/>
  </clipPath>
  </defs>
  </svg>
  `,
  'create-custom': `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M17.2588 31.9765L17.2578 31.9744C15.6193 28.7945 13.9486 26.4237 12.7696 25.2402C11.4555 23.921 9.89612 22.1435 8.67123 20.1632C7.43878 18.1706 6.59961 16.0605 6.59961 14.0541C6.59961 6.81892 12.4017 1 19.5996 1C26.7975 1 32.5996 6.81892 32.5996 14.0541C32.5996 16.1303 31.8288 18.1641 30.6478 20.0842C29.4678 22.0028 27.9134 23.7506 26.4296 25.2402C25.2621 26.4123 23.4781 28.7785 21.9297 31.9979C21.0897 33.7343 20.5535 35.4727 20.1408 36.8109C20.0844 36.9939 20.0303 37.1694 19.978 37.3364C19.8406 37.7752 19.7218 38.1359 19.6125 38.4248C19.509 38.1374 19.3963 37.78 19.2642 37.3446C19.2315 37.237 19.198 37.1259 19.1635 37.0116C18.7504 35.6414 18.1984 33.8104 17.2588 31.9765Z" fill="white" stroke="#782EB1" stroke-width="2" stroke-miterlimit="10"/>
  <path d="M24.5996 14.7891H20.5996V18.7891H18.5996V14.7891H14.5996V12.7891H18.5996V8.78906H20.5996V12.7891H24.5996V14.7891ZM19.5996 3.78906C18.2864 3.78906 16.986 4.04772 15.7728 4.55027C14.5595 5.05281 13.4571 5.78941 12.5285 6.71799C10.6532 8.59336 9.59961 11.1369 9.59961 13.7891C9.59961 16.4412 10.6532 18.9848 12.5285 20.8601C13.4571 21.7887 14.5595 22.5253 15.7728 23.0279C16.986 23.5304 18.2864 23.7891 19.5996 23.7891C22.2518 23.7891 24.7953 22.7355 26.6707 20.8601C28.546 18.9848 29.5996 16.4412 29.5996 13.7891C29.5996 12.4758 29.341 11.1755 28.8384 9.96223C28.3359 8.74897 27.5993 7.64658 26.6707 6.71799C25.7421 5.78941 24.6397 5.05281 23.4264 4.55027C22.2132 4.04772 20.9128 3.78906 19.5996 3.78906Z" fill="#782EB1"/>
  </svg>
  `,
  _customIcon: `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M34.0039 14.0541C34.0039 18.7027 30.5578 22.9189 27.5424 25.9459C26.4654 27.027 24.7424 29.2973 23.2347 32.4324C21.4039 36.2162 21.0808 40 20.0039 40C18.927 40 18.7116 36.2162 16.7731 32.4324C15.1578 29.2973 13.5424 27.027 12.4654 25.9459C9.77314 23.2432 6.00391 18.5946 6.00391 14.0541C6.00391 6.27027 12.2501 0 20.0039 0C27.7578 0 34.0039 6.27027 34.0039 14.0541Z" fill="{icon-bgColor}"/>
  </svg>`,
};
