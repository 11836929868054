import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Parking } from '../../carpark/carpark-create-main/carpark.model';
import { AccountService } from '../../services/account.service';
import { ApiService } from '../../services/api.service';
import { CommonService } from '../../services/common.service';
import { FormUtilsService } from '../../services/form-utils.service';
import { CustomConfirmService } from '../../shared/components/custom-confirm/custom-confirm.service';
import { ToastService } from '../../shared/components/toast/toast.service';
import { Review, Revision } from '../../shared/revision.model';
import { BroadCastModel, MessageStatuses } from '../broadcast.model';

export const BroadcastStatusAttributesMap: {
  [key in MessageStatuses]: {
    color: string;
    backgroundColor: string;
    displayText: string;
  };
} = {
  broadcasting: {
    color: '#3D7FE2',
    backgroundColor: 'rgba(100, 153, 232, 0.2)',
    displayText: 'Broadcasting',
  },
  archived: {
    color: 'rgba(154, 160, 165, 1)',
    backgroundColor: 'rgba(214, 217, 219, 0.3)',
    displayText: 'Archived',
  },
  expired: {
    color: 'rgba(154, 160, 165, 1)',
    backgroundColor: 'rgba(214, 217, 219, 0.3)',
    displayText: 'Expired',
  },
  paused: {
    color: 'rgba(205, 0, 62, 1)',
    backgroundColor: 'rgba(205, 0, 62, 0.1)',
    displayText: 'Paused',
  },
  pending: {
    color: 'rgba(0, 193, 205, 1)',
    backgroundColor: 'rgba(48, 202, 211, 0.2)',
    displayText: 'Pending',
  },
  reviewing: {
    color: 'rgba(255, 170, 44, 1)',
    backgroundColor: 'rgba(255, 183, 74, 0.2)',
    displayText: 'Reviewing',
  },
  approved: {
    color: 'rgba(75, 200, 17, 1)',
    backgroundColor: 'rgba(71, 221, 0, 0.2)',
    displayText: 'Approved',
  },
  rejected: {
    color: 'rgba(255, 170, 44, 1)',
    backgroundColor: 'rgba(255, 183, 74, 0.2)',
    displayText: 'To revise',
  },
};

@Component({
  selector: 'app-broadcast-create',
  templateUrl: './broadcast-create.component.html',
  styleUrls: ['./broadcast-create.component.scss'],
})
export class BroadcastCreateComponent implements OnInit {
  carparkList: { name: string; carparkId: string }[] = [];
  headerText = 'New Broadcast Message';
  mode: 'edit' | 'create' | 'preview';
  minDate: Date = new Date();
  revisionMessagesObj: Revision;
  selectedBroadcastId: string;
  broadcastFG: FormGroup;
  fetchedBroadcastDetails: BroadCastModel;
  selectedCarparkId: string;
  newRevisionMessage = '';
  showModalCreate = false;
  showModalEdit = false;
  showToast = false;
  readonly MAX_CHARACTER_LIMIT = 80;
  constructor(
    private messageService: MessageService,
    private confirmationService: CustomConfirmService,
    private toastSercive: ToastService,
    private apiService: ApiService,
    private fb: FormBuilder,
    public fU: FormUtilsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public accountService: AccountService,
    public cmnSrv: CommonService
  ) {
    this.broadcastFG = this.fb.group({
      carparkId: ['', Validators.required],
      broadcastMode: ['planning', Validators.required],
      content: [
        '',
        [Validators.required, Validators.maxLength(this.MAX_CHARACTER_LIMIT)],
      ],
      isTimed: [false, [Validators.required]],
      startDate: [null],
      startTime: [null],
      endDate: [null],
      endTime: [null],
      revisionId: [null],
    });
    this.mode = this.activatedRoute.snapshot.queryParams?.mode;
    if (this.mode === 'edit' || this.mode === 'preview') {
      this.selectedBroadcastId = this.activatedRoute.snapshot.queryParams.id;
    } else {
      this.selectedCarparkId =
        this.activatedRoute.snapshot.queryParams.forCarparkId;
    }
    this.setHeaderText();
    this.broadcastFG
      .get('isTimed')
      ?.valueChanges.subscribe(this.handleIsTimedChange.bind(this));
  }

  setHeaderText() {
    this.headerText =
      {
        edit: 'Edit ',
        create: 'New ',
        preview: '',
      }[this.mode] + 'Broadcast Message';
  }

  handleIsTimedChange() {
    const isTimed = this.broadcastFG.get('isTimed')?.value;
    if (isTimed) {
      this.broadcastFG.get('startDate')?.addValidators(Validators.required);
      this.broadcastFG.get('startTime')?.addValidators(Validators.required);
      this.broadcastFG.get('endDate')?.addValidators(Validators.required);
      this.broadcastFG.get('endTime')?.addValidators(Validators.required);
    } else {
      this.broadcastFG.get('startDate')?.clearValidators();
      this.broadcastFG.get('startTime')?.clearValidators();
      this.broadcastFG.get('endDate')?.clearValidators();
      this.broadcastFG.get('endTime')?.clearValidators();
      this.broadcastFG.get('startDate')?.updateValueAndValidity();
      this.broadcastFG.get('startTime')?.updateValueAndValidity();
      this.broadcastFG.get('endDate')?.updateValueAndValidity();
      this.broadcastFG.get('endTime')?.updateValueAndValidity();
    }
  }

  async ngOnInit() {
    if (this.mode === 'edit' || this.mode === 'preview') {
      this.getBroadcast().then();
    }
    await this.getCarparkList();
  }

  get charactersLeft() {
    return (
      this.MAX_CHARACTER_LIMIT -
      (this.broadcastFG.get('content')?.value?.length || 0)
    );
  }

  async getCarparkList() {
    const queryObj = {
      limit: 10000, // todo: update API so that we can get list of all carparks for an operator by sending all or null?
      // Note: todo autoscroll api and only send 10 as limit
    };
    const res = await this.apiService.getAllCarparks(queryObj, true, true);
    this.carparkList = res.data.result
      .filter(
        (cp: Parking) => this.accountService.isAdminUser || cp.liveInBreeze
      )
      .map(({ id, name }: any) => ({
        carparkId: id,
        name,
      }));

    if (
      !!this.carparkList.find((c) => c.carparkId === this.selectedCarparkId)
    ) {
      this.broadcastFG.patchValue({
        carparkId: this.selectedCarparkId,
      });
    }
  }

  async getBroadcast() {
    const queryObj = {
      page: 1,
      limit: 10,
      filters: { _id: this.selectedBroadcastId },
    };

    this.fetchedBroadcastDetails = (
      await this.apiService.getBroadcastMessages(queryObj).toPromise<any>()
    ).data.result[0];
    this.broadcastFG.patchValue(this.fetchedBroadcastDetails);
    if (this.fetchedBroadcastDetails.isTimed) {
      this.broadcastFG.patchValue({
        startDate: new Date(this.fetchedBroadcastDetails.startEnd?.start!),
        startTime: new Date(this.fetchedBroadcastDetails.startEnd?.start!),
        endDate: new Date(this.fetchedBroadcastDetails.startEnd?.end!),
        endTime: new Date(this.fetchedBroadcastDetails.startEnd?.end!),
      });
    }
    if (this.mode === 'preview') this.broadcastFG.disable();

    if (this.fetchedBroadcastDetails.revisionId) {
      this.getRevisionMessages();
    }
  }

  triggerEditMode() {
    this.mode = 'edit';
    this.broadcastFG.enable();
    this.setHeaderText();
  }

  getRevisionMessages() {
    this.apiService
      .getRevisionMessages({
        parentId: this.fetchedBroadcastDetails._id!,
        parentType: 'broadcast',
        type: 'broadcast-message',
      })
      .then((res) => {
        this.revisionMessagesObj = res[0] || [];
      });
  }

  checkValidityAndGenerateBroadcastDetails() {
    let errMsg = '';
    if (this.broadcastFG.invalid) errMsg = 'Please fill all fields';

    const broadcastFormValues = this.broadcastFG.value;
    const broadcastDetails = new BroadCastModel();
    broadcastDetails.isActive = true;
    broadcastDetails.carparkId = broadcastFormValues.carparkId;
    broadcastDetails.carparkName =
      this.carparkList.find((e) => e.carparkId == broadcastDetails.carparkId)
        ?.name || '';
    broadcastDetails.content = broadcastFormValues.content;
    broadcastDetails.broadcastMode = broadcastFormValues.broadcastMode;
    broadcastDetails.isTimed = broadcastFormValues.isTimed;
    broadcastDetails.revisionId = broadcastFormValues.revisionId || null;

    broadcastDetails.status = 'pending'; // note: todo: maybe change this to 'pending' when admin flow is ready

    if (this.mode === 'create') {
      broadcastDetails.dateSent = new Date();
    } else {
      broadcastDetails.dateSent =
        this.fetchedBroadcastDetails.dateSent || new Date();
    }
    if (broadcastFormValues.content?.length > this.MAX_CHARACTER_LIMIT)
      errMsg = 'Message maximum character limit is ' + this.MAX_CHARACTER_LIMIT;

    if (broadcastFormValues.isTimed) {
      if (
        !broadcastFormValues.startDate ||
        !broadcastFormValues.endDate ||
        !broadcastFormValues.startTime ||
        !broadcastFormValues.endTime
      ) {
        errMsg = 'Please enter dates';
      } else {
        const startDate = new Date(broadcastFormValues.startDate);
        const endDate = new Date(broadcastFormValues.endDate);
        startDate?.setHours(broadcastFormValues.startTime?.getHours() || 0);
        startDate?.setMinutes(broadcastFormValues.startTime?.getMinutes() || 0);
        endDate?.setHours(broadcastFormValues.endTime?.getHours() || 0);
        endDate?.setMinutes(broadcastFormValues.endTime?.getMinutes() || 0);

        broadcastDetails.startEnd = {
          start: startDate,
          end: endDate,
        };
        if (
          broadcastDetails.startEnd.start! < new Date() ||
          broadcastDetails.startEnd.end! < new Date()
        ) {
          errMsg = 'Start or end date cannot be earlier than current date';
        } else if (
          broadcastDetails.startEnd.start! > broadcastDetails.startEnd.end!
        ) {
          errMsg = 'End date can not be earlier than start date';
        }
      }
    } else {
      broadcastDetails.startEnd = null;
    }
    if (!broadcastDetails.content) {
      errMsg = 'Message content can not be empty';
    } else if (!broadcastDetails.carparkId) {
      errMsg = 'Must select a carpark';
    }
    if (errMsg) {
      this.messageService.add({
        key: 'tc',
        severity: 'error',
        summary: 'Error',
        detail: errMsg,
      });
      return { isValid: false };
    }
    return { isValid: true, broadcastDetails };
  }

  async save() {
    if (this.broadcastFG.invalid) {
      this.broadcastFG.markAllAsTouched();
      return;
    }

    const { isValid, broadcastDetails } =
      this.checkValidityAndGenerateBroadcastDetails();
    if (!isValid) return;

    if (this.mode === 'create') {
      const result = await this.apiService.createBroadcastMessage(
        broadcastDetails!
      );
      this.confirmationService.confirm({
        header: 'Successfully submitted',
        message: [
          'Your broadcast message has been submitted to Breeze system. It will take a while to review your broadcast message.',
        ],
        acceptLabel: 'Okay',
        rejectVisible: false,
        accept: () => {
          this.router.navigate(['/broadcast', 'list']);
        },
      });
    } else {
      this.confirmationService.confirm({
        header: 'Edit broadcast message',
        message: [
          'A round of approvals will be required with every edits made. Would you like to proceed?',
        ],
        acceptLabel: 'Proceed',
        accept: async () => {
          const result = await this.apiService.updateBroadcastMessage(
            this.selectedBroadcastId,
            broadcastDetails!
          );
          if (
            this.fetchedBroadcastDetails?.status === 'pending' ||
            this.fetchedBroadcastDetails?.status === 'approved'
          ) {
            this.toastSercive.confirm();
            setTimeout(() => {
              this.router.navigate(['/broadcast', 'list']);
            }, 1000);
          } else {
            this.router.navigate(['/broadcast', 'list']);
          }
        },
      });
    }
  }

  cancel() {
    this.router.navigate(['/broadcast', 'list']);
  }

  async reject() {
    if (!this.accountService.isAdminUser) return;

    const unApprovedReviewMessages =
      this.revisionMessagesObj.reviews?.filter(
        (review) => review?.reviewState === 'pending'
      ) || [];

    let confirmationMessage = `Are you sure you want to reject this message?`;
    let acceptVisible = true;
    let rejectLabel = 'No';
    if (unApprovedReviewMessages.length === 0) {
      confirmationMessage =
        'Please add a comment where necessary before rejecting a message.';
      rejectLabel = 'Ok';
      acceptVisible = false;
    }

    this.confirmationService.confirm({
      header: 'Rejection confirmation',
      message: [confirmationMessage],
      accept: () => {
        this.apiService
          .updateBroadcastMessage(this.selectedBroadcastId, {
            status: 'rejected',
          } as BroadCastModel)
          .then((result) => {
            this.messageService.add({
              key: 'tc',
              severity: 'success',
              summary: 'Success',
              detail: 'Broadcast message rejected!',
            });
            this.router.navigate(['/broadcast', 'list']);
          })
          .catch((err) => {
            this.messageService.add({
              key: 'tc',
              severity: 'error',
              summary: 'Error',
              detail: 'Could not reject broadcast message!',
            });
          })
          .finally(() => {
            this.ngOnInit();
          });
      },
      acceptVisible,
      rejectLabel,
    });
  }

  async approve() {
    if (!this.accountService.isAdminUser) return;

    let confirmationMessage = `Are you sure you want to approve this broadcast message?`;
    const unApprovedReviewMessages =
      this.revisionMessagesObj.reviews?.filter(
        (review) => review?.reviewState === 'pending'
      ) || [];

    if (unApprovedReviewMessages.length) {
      confirmationMessage =
        `There are ${unApprovedReviewMessages.length} unresolved revisions. ` +
        confirmationMessage;
    }

    this.confirmationService.confirm({
      header: 'Approval confirmation',
      message: [confirmationMessage],
      accept: () => {
        this.apiService
          .updateBroadcastMessage(this.selectedBroadcastId, {
            status: 'approved',
          } as BroadCastModel)
          .then((result) => {
            this.messageService.add({
              key: 'tc',
              severity: 'success',
              summary: 'Success',
              detail: 'Broadcast message approved',
            });
            this.router.navigate(['/broadcast', 'list']);
          })
          .catch((err) => {
            this.messageService.add({
              key: 'tc',
              severity: 'error',
              summary: 'Error',
              detail: 'Could not approve broadcast!',
            });
          });
      },
    });
  }

  async toggleReviewMessageStatus(
    revision: Revision,
    review: Review,
    newReviewState: Review['reviewState']
  ) {
    if (!revision.type || !revision.parentId) return;

    // note: bad typing issues b/w function argument and review object causing issues here. Hence using any for now;
    const toggledReviewObject: any = {
      ...revision,
      reviews: [
        {
          ...review,
          reviewState:
            review.reviewState === 'approved' ? 'pending' : 'approved',
          // newReviewState not really needed if we use above logic?
        },
      ],
    };

    const result = await this.apiService.addOrUpdateRevisionMessage(
      toggledReviewObject
    );
    this.getRevisionMessages();
  }

  async addRevisionMessage() {
    const newRevMsg = this.newRevisionMessage?.trim();
    if (!newRevMsg) return;
    if (!this.fetchedBroadcastDetails.revisionId) return;

    const selectedRMObj = this.revisionMessagesObj;

    const revMessageBody = {
      id: selectedRMObj?.id || this.fetchedBroadcastDetails.revisionId,
      type: 'broadcast-message',
      parentType: 'broadcast',
      parentId: this.fetchedBroadcastDetails._id!,
      reviews: [
        {
          deleted: false, // for future?
          message: this.newRevisionMessage,
          reviewState: 'pending',
        },
      ] as [Review],
    };
    const result = await this.apiService.addOrUpdateRevisionMessage(
      revMessageBody
    );
    this.newRevisionMessage = '';
    this.getRevisionMessages();
  }
}
