import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmProceedData as ConfirmProceedOptions } from '../components/confirm-proceed/confirm-proceed.component';
import { CustomConfirmService } from '../components/custom-confirm/custom-confirm.service';

export enum ConfirmationStatus {
  PROCEED = 'proceed', // proceed directly
  CONFIRM_PROCEED = 'confirm_proceed',
  REJECT_PROCEED = 'reject_proceed',
  CANCEL = 'cancel',
}

@Directive({
  selector: '[appConfirmProceed]',
})
export class ConfirmProceedDirective {
  @Output() confirm: EventEmitter<any> = new EventEmitter();
  @Input('confirmationTriggerOn') confirmationTriggerOn: boolean;
  @Input('confirmProceedOptions') confirmProceedOptions: ConfirmProceedOptions;
  @Input('styleClass') styleClass = 'verification-popup';

  constructor(
    private dialogService: DialogService,
    private confirmationService: CustomConfirmService
  ) {}

  @HostListener('click', ['$event']) onClick($event: any) {
    if (!this.confirmationTriggerOn) {
      return this.confirm.emit(ConfirmationStatus.PROCEED);
    }

    this.confirmationService.confirm({
      header:
        this.confirmProceedOptions.header ||
        'Are you sure you want to submit changes?',
      message: this.confirmProceedOptions.message || [
        'Changes made to the carpark will be reviewed by Super Admin before publishing onto Breeze app.',
        'The carpark listing with already approved information will still be shown on Breeze.',
      ],
      acceptLabel: this.confirmProceedOptions.confirmLabel || 'Submit',
      rejectLabel: this.confirmProceedOptions.cancelLabel || 'Cancel',
      accept: () => {
        this.confirm.emit(ConfirmationStatus.CONFIRM_PROCEED);
      },
      reject: () => {
        this.confirm.emit(ConfirmationStatus.CANCEL);
      },
    });

    // const ref = this.dialogService.open(ConfirmProceedComponent, {
    //   data: this.confirmProceedOptions,
    //   closable: false,
    //   styleClass: `confirm-trigger-popup ${this.styleClass}`,
    // });

    // ref.onClose.subscribe((status: ConfirmationStatus) => {
    //   // console.log('onClose status: ', status); // debug-log
    //   this.confirm.emit(status);
    // });
  }
}
