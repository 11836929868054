import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { AccountService } from 'src/app/services/account.service';
import { ApiService } from '../../services/api.service';
import {
  LS_IS_ADMIN,
  LocalStorageService,
} from '../../services/local-storage.service';

const NOTIFICATION_POLL_TIME = 60; // in seconds

const refreshOn = (selectedPathName: string) => () => {
  if (window.location.pathname.includes(selectedPathName))
    window.location.reload();
};

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit, OnDestroy {
  items: MenuItem[];
  intervalId: number;
  constructor(
    public accountService: AccountService,
    private router: Router,
    private apiService: ApiService,
    private lsSrv: LocalStorageService
  ) {
    this.items = [
      {
        label: 'Carparks',
        routerLink: ['/dashboard'],
        command: refreshOn('/dashboard'),
      },
      {
        label: 'Manage Users',
        routerLink: ['/admin', 'manage', 'users'],
        visible: this.accountService.isAdminUser,
        command: refreshOn(['/admin', 'manage', 'users'].join('/')),
      },
      {
        label: 'Integrate Availability',
        routerLink: ['/carpark', 'availability'],
        command: refreshOn(['/carpark', 'availability'].join('/')),
      },
      {
        label: 'Utilisation charts',
        routerLink: ['/carpark', 'utilisation'],
        command: refreshOn(['/carpark', 'utilisation'].join('/')),
      },
      {
        label: 'Broadcast Messages',
        routerLink: ['/broadcast', 'list'],
        command: refreshOn(['/broadcast', 'list'].join('/')),
      },
      {
        label: 'Vouchers',
        routerLink: ['/vouchers', 'list'],
        command: refreshOn(['/vouchers', 'list'].join('/')),
        visible: !this.accountService.isAdminUser,
      },
      {
        label: 'Breeze settings',
        routerLink: ['/admin', 'breeze-settings'],
        command: refreshOn(['/admin', 'breeze-settings'].join('/')),
        visible: this.accountService.isAdminUser,
      },
      // {
      //   label: 'User Guide',
      //   routerLink: ['/how-to'],
      //   command: refreshOn(['/how-to'].join('/')),
      // },
    ];
  }

  ngOnInit() {
    const isAdmin = this.lsSrv.getItem(LS_IS_ADMIN) == '1';
    // not need to call notifications for admin when not impersonating
    if (isAdmin && !this.accountService.isImpersonating) return;

    this.getNotifications();
    this.intervalId = window.setInterval(() => {
      console.log('polling notifications');
      this.getNotifications();
    }, NOTIFICATION_POLL_TIME * 1000);
  }

  ngOnDestroy(): void {
    this.intervalId && clearInterval(this.intervalId);
  }

  get initials() {
    return this.accountService?.userName?.trim().slice(0, 1);
  }

  logout() {
    this.accountService.logout();
    this.router.navigate(['account', 'login']);
  }

  notificationList: any[] = [];
  unreadCount = 0;

  async getNotifications() {
    const { success, result = null } = await this.apiService.getNotifications();
    if (success) {
      this.notificationList = result.notifications;
      this.unreadCount = this.notificationList.filter((n) => !n.read).length;
    }
  }

  markNotificationsAsRead(event: string) {
    this.apiService.markAllNotificationsAsRead().then((result) => {
      this.getNotifications();
    });
  }

  async openNotifications(event: any, notification: any) {
    await this.getNotifications();
    notification.toggle(event);
  }
}
