<div class="mobile-wrapper">
  <app-mobile-view-fallback></app-mobile-view-fallback>
</div>
<div class="desktop-wrapper">
  <app-navbar
    *ngIf="isNavBarEnabled && accountService.isLoggedIn()"
  ></app-navbar>
  <main style="position: relative">
    <router-outlet></router-outlet>
    <app-toast></app-toast>
  </main>

  <div class="progress-spinner" *ngIf="showSpinner">
    <p-progressSpinner></p-progressSpinner>
  </div>

  <p-toast position="bottom-center" key="bc"></p-toast>
  <p-toast position="top-center" key="tc" [baseZIndex]="99999"></p-toast>
  <app-custom-confirm></app-custom-confirm>
</div>
