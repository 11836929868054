import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormUtilsService } from '../../../../services/form-utils.service';
import { SeasonParkingDurationOptions } from '../../carpark-price/carpark-season-parking-tab/season-parking-panel/season-parking-panel.component';
import { CarparkKeyDisplayMap, DAYS } from '../../carpark.model';
import { DaysMap } from '../carpark-price-entry/carpark-price-entry.component';
import { CombinedRatesDetails } from '../carpark-price.component';

const daysArray = DaysMap.map((e) => e.key);
@Component({
  selector: 'app-carpark-price-preview',
  templateUrl: './carpark-price-preview.component.html',
  styleUrls: ['./carpark-price-preview.component.scss'],
  providers: [DatePipe],
})
export class CarparkPricePreviewComponent implements OnInit {
  @Input('ratesDetails') ratesDetails: CombinedRatesDetails;
  @Input('isOpenToPublic') isOpenToPublic: FormControl;
  @Input('tabIndex') tabIndex?: number;
  keyDisplayMap = CarparkKeyDisplayMap;
  normalRates: CombinedRatesDetails['normalRates'];
  seasonalRates: CombinedRatesDetails['seasonalRates'];
  constructor(private datePipe: DatePipe, public fUtils: FormUtilsService) {}

  ngOnInit(): void {
    this.normalRates = this.ratesDetails.normalRates;
    this.seasonalRates = this.ratesDetails.seasonalRates;
  }
  getFormattedOffTimeSlots = getFormattedOffTimeSlots;
}

// not using this function anymore.. to be removed once the logic is confirmed and stable
export const getFormattedDays = (days: string[]) => {
  let selectedDays = [...days];

  //handle public holiday
  const publicHolidayIndex = selectedDays.findIndex(
    (e) => e == DAYS.PUBLIC_HOLIDAY
  );
  const isPublicHolidayPresent = publicHolidayIndex !== -1;
  if (isPublicHolidayPresent) selectedDays.splice(publicHolidayIndex, 1);
  //handle public holiday weekday(public holiday eve)
  const publicHolidayWeekdayIndex = selectedDays.findIndex(
    (e) => e == DAYS.PUBLIC_HOLIDAY_EVE
  );
  const isPublicHolidayWeekdayPresent = publicHolidayWeekdayIndex !== -1;
  if (isPublicHolidayWeekdayPresent)
    selectedDays.splice(publicHolidayWeekdayIndex, 1);

  //construct publicHolidayString
  let publicHolidayStringArray = [];
  if (isPublicHolidayPresent)
    publicHolidayStringArray.push(CarparkKeyDisplayMap[DAYS.PUBLIC_HOLIDAY]);
  if (isPublicHolidayWeekdayPresent)
    publicHolidayStringArray.push(
      CarparkKeyDisplayMap[DAYS.PUBLIC_HOLIDAY_EVE]
    );
  const publicHolidayFormattedString =
    publicHolidayStringArray.join(' and ') || '';
  if (!selectedDays.length) {
    return publicHolidayFormattedString;
  }

  let dayString = '';
  if (selectedDays.length == 1) {
    dayString = `${CarparkKeyDisplayMap[selectedDays[0]]}`;
  } else {
    let isConsecutive = true;
    let prevInd = null,
      currentInd = null;
    for (let day of selectedDays) {
      currentInd = daysArray.findIndex((e) => e == day);
      if (prevInd == null) {
        prevInd = currentInd;
        continue;
      }
      if (prevInd == currentInd - 1) {
        prevInd = currentInd;
        continue;
      }
      isConsecutive = false;
      break;
    }

    if (isConsecutive) {
      const firstDay = CarparkKeyDisplayMap[selectedDays.slice(0, 1)[0]];
      const lastDay = CarparkKeyDisplayMap[selectedDays.slice(-1)[0]];
      dayString = `${firstDay} - ${lastDay}`;
    } else {
      dayString = selectedDays.map((e) => CarparkKeyDisplayMap[e]).join(', ');
    }
  }

  if (publicHolidayFormattedString)
    dayString += `, ${publicHolidayFormattedString}`;
  return dayString;
};

export const getFormattedOffTimeSlots = (
  timeSlots: any[],
  datePipe: DatePipe
) => {
  return timeSlots
    .map(
      (t: { start_time: string; end_time: string }) =>
        `${datePipe.transform(t.start_time, 'HH:mm')} - ${datePipe.transform(
          t.end_time,
          'HH:mm'
        )}`
    )
    .join(' • ');
};

@Component({
  selector: 'app-carpark-seasonal-details',
  template: `
    <section class="seasonal-details">
      <h4>{{ header }}</h4>
      <div
        class="details-section"
        *ngIf="
          seasonalDetailsFG.value?.duration && seasonalDetailsFG.value?.charges
        "
      >
        <h5>Rate</h5>
        <div class="row">
          <div class="duration">
            {{ getFormattedDuration(seasonalDetailsFG.value.unit) }}
          </div>
          <div class="price">
            {{
              seasonalDetailsFG.value.charges
                ? '$' + seasonalDetailsFG.value.charges
                : ''
            }}
          </div>
        </div>
      </div>
      <div class="details-section" *ngIf="seasonalDetailsFG.value?.remarks">
        <h5>Remarks</h5>
        <p>{{ seasonalDetailsFG.value.remarks }}</p>
      </div>
    </section>
  `,
  styles: [
    `
      .details-section {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 10px;
      }
    `,
    `
      .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px;
        background-color: #f7f8f8;
      }
    `,
    `
      .row > div.price {
        font-weight: 600;
        font-size: 16px;
        color: #222638;
      }
    `,
    `
      .row > div.duration {
        font-weight: 400;
        font-size: 16px;
        color: #34404b;
      }
    `,
    `
      .details-section > h5,
      .details-section > p {
        margin: 0;
        margin-botton: 8px;
      }
    `,
    `
      .seasonal-details > h4 {
        font-weight: 700;
        font-size: 16px;
        margin: 18px 0 8px;
      }
    `,
    `
      .details-section > h5 {
        font-weight: 600;
        font-size: 16px;
      }
    `,
    ``,
  ],
})
export class CarparkSeasonalDetailsComponent implements OnInit {
  @Input('header') header: string;
  @Input('seasonDetailsFG') seasonalDetailsFG: FormGroup;

  constructor() {}

  ngOnInit(): void {}

  getFormattedDuration(duration: any) {
    return SeasonParkingDurationOptions.find((e) => e.value === duration)
      ?.display!;
  }
}
