export type MessageStatuses =
  | 'broadcasting'
  | 'archived'
  | 'pending'
  | 'reviewing' // same as pending
  | 'approved'
  | 'rejected'
  | 'paused'
  | 'expired';

export class BroadCastModel {
  _id?: string;
  content: string | undefined;
  carparkId: string | undefined;
  carparkName: string | undefined;
  dateSent: Date | undefined;
  dateSentStr?: string | undefined;
  broadcastMode: 'navigation' | 'planning';
  isTimed: boolean | undefined;
  isActive: boolean;
  startEnd?: {
    start: Date | undefined;
    startStr?: string;
    end: Date | undefined;
    endStr?: string;
  } | null;
  presentationStatus: string;
  status: MessageStatuses | undefined;
  _v?: string;
  updatedOn: string;
  revisionId: string;
}
