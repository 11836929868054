import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Observable, from, throwError } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AccountService } from '../account.service';
import { EventService } from '../event.service';
import {
  LS_ADMIN_AUTH_TOKEN,
  LS_COGNITO_REFRESH_TOKEN,
  LocalStorageService,
} from '../local-storage.service';

const whiteListedUrls = [
  'dev.breeze.com.sg/api',
  'cognito-idp.ap-southeast-1.amazonaws.com/',
  'api.mapbox.com/geocoding/v5/mapbox.places',
];

const blackListedUrls = ['user/notifications'];
export enum CUSTOM_UI_ERRORS {
  LOGIN_REFRESHED = 'LOGIN_REFRESHED',
  TOKEN_REFRESH_FAILED = 'TOKEN_REFRESH_FAILED',
}

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private accountService: AccountService,
    private evtSvc: EventService,
    private messageService: MessageService,
    private router: Router,
    private lsSrv: LocalStorageService
  ) {}

  // loginPopupTriggered = false;

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const url = request.url;

    if (!this.isBlackListed(url)) {
      this.evtSvc.emit({
        name: 'add-loader',
      });
    }

    const removeLoader = () => {
      if (!this.isBlackListed(url))
        this.evtSvc.emit({
          name: 'remove-loader',
        });
    };

    if (!url.includes(environment.apiUrl) && !this.isWhitelisted(url))
      return next
        .handle(request)
        .pipe(
          catchError(this.errorHandler.bind(this, request, next)),
          finalize(removeLoader)
        );
    if (!this.accountService.isLoggedIn())
      return next
        .handle(request)
        .pipe(
          catchError(this.errorHandler.bind(this, request, next)),
          finalize(removeLoader)
        );

    const requestWithToken = this.getRequestWithHeaders(request);
    return next
      .handle(requestWithToken)
      .pipe(
        catchError(this.errorHandler.bind(this, request, next)),
        finalize(removeLoader)
      );
  }

  getRequestWithHeaders(request: HttpRequest<any>) {
    const url = request.url;
    const jwt = this.accountService.getIdToken();
    const adminToken = this.lsSrv.getItem(LS_ADMIN_AUTH_TOKEN);
    const requestWithToken = request.clone({
      setHeaders: {
        Authorization: `Bearer ${jwt}`,
        ...(adminToken && { 'ezp-auth-admin': `Bearer ${adminToken}` }),
      },
    });
    return requestWithToken;
  }

  errorHandler(
    request: HttpRequest<any>,
    next: HttpHandler,
    err: any,
    caught: Observable<HttpEvent<any>>
  ) {
    if (err.status == 401 && this.lsSrv.getItem(LS_COGNITO_REFRESH_TOKEN)) {
      return from(this.accountService.refreshSession()).pipe(
        switchMap((res) => {
          const requestWithNewToken = this.getRequestWithHeaders(request);
          return next.handle(requestWithNewToken);
        }),
        catchError((_err) => {
          console.log('err in refreshing Token and handling request: ', _err);
          this.messageService.add({
            key: 'tc',
            severity: 'error',
            summary: 'Error',
            detail: 'Token expired. Please login again',
          });
          this.logout();
          return throwError(CUSTOM_UI_ERRORS.TOKEN_REFRESH_FAILED);
        })
      );
    } else if (err.status == 401) {
      this.logout();
      return throwError(err);
    }
    return throwError(err);
  }

  logout() {
    this.accountService.logout();
    this.router.navigate(['account', 'login']);
  }

  // triggerLogin(
  //   err?: any,
  //   _message?: string,
  //   request?: HttpRequest<any>,
  //   next?: HttpHandler
  // ) {
  //   if (this.loginPopupTriggered) return throwError(err);

  //   this.accountService.loginPopupTriggered = this.loginPopupTriggered = true;

  //   const message = _message || 'Unauthorized. Please login again!';
  //   this.messageService.add({
  //     key: 'tc',
  //     severity: 'error',
  //     summary: 'Error',
  //     detail: message,
  //   });
  //   this.accountService.logout(false);

  //   this.evtSvc.emit({
  //     name: 'remove-loader',
  //   });

  //   const ref = this.dialogService.open(LoginPopupComponent, {
  //     data: {
  //       isModal: true,
  //     },
  //     closable: false,
  //     styleClass: `login-popup`,
  //   });
  //   return ref.onClose.pipe(
  //     switchMap((isLoggedIn) => {
  //       if (!next || !request) return throwError('Unknown Error');
  //       if (isLoggedIn) {
  //         this.accountService.loginPopupTriggered = this.loginPopupTriggered =
  //           false;

  //         // refresh the page unless its a carpark update request => PUT '/carpark/${carparkId}'
  //         // Note: maybe do this in a way where there is a reload popup with a cancel button like in aws?
  //         const isRefreshDisabled = refreshDisabledEndPoints.some(
  //           (endpoint) =>
  //             request.url.includes(endpoint.url) &&
  //             request.method === endpoint.method
  //         );
  //         if (!isRefreshDisabled)
  //           setTimeout(() => {
  //             location.reload();
  //           }, 300);

  //         return throwError(CUSTOM_UI_ERRORS.LOGIN_REFRESHED);
  //       } else {
  //         this.accountService.loginPopupTriggered = this.loginPopupTriggered =
  //           false;
  //         return throwError(err || 'Unknown Error');
  //       }
  //     })
  //   );
  // }

  isWhitelisted(reqUrl: string) {
    return whiteListedUrls.some((url) => reqUrl.includes(url));
  }

  isBlackListed(reqUrl: string) {
    return blackListedUrls.some((url) => reqUrl.includes(url));
  }

  // isAdminUrl(reqUrl: string) {
  //   return adminUrls.some((url) => reqUrl.includes(url));
  // }
}
