<div class="support-page-wrapper">
  <div class="selector-container">
    <span>Jump to: </span>
    <p-dropdown
      [options]="pageSections"
      [(ngModel)]="selectedPageSection"
      optionLabel="name"
      (onChange)="sectionChange($event)"
    ></p-dropdown>
  </div>

  <p-accordion
    [multiple]="true"
    (onOpen)="onAccordionOpen($event)"
    (onClose)="onAccordionClose($event)"
  >
    <p-accordionTab [selected]="getPageSection('login')?.selected">
      <ng-template pTemplate="header">
        <div class="panel-header" #accordionHeader>
          <h2>
            <a href="/how-to#login" id="login"> 1. Login</a>
          </h2>
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="accordion-content">
          <ol type="a">
            <li>Open the link to the portal's unique URL.</li>
            <li>
              Use the username and password provided to log in. Note: At the
              following step in the Account Creation process, you can change
              your password.
            </li>
            <li>If you forget your password, you can also request a change.</li>
          </ol>
          <custom-support-img
            [src]="'/assets/images/support/sign-in.png'"
            [label]="'Sign In'"
          ></custom-support-img>
          <!-- video sample code below -->
          <!-- <div class="video-container">
            <video controls>
              <source src="/assets/videos/1.Login.mov" type="video/mp4" />
            </video>
            <label for="">Sign In</label>
          </div> -->
        </div>
      </ng-template>
    </p-accordionTab>
    <p-accordionTab [selected]="getPageSection('account-creation')?.selected">
      <ng-template pTemplate="header">
        <div class="panel-header" #accordionHeader>
          <h2>
            <a href="/how-to#account-creation" id="account-creation"
              >2. Account Creation</a
            >
          </h2>
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="accordion-content">
          <ol type="a">
            <li>
              Fill in the fields for the user's basic information, the carpark
              building's website (if applicable), and the role of the user who
              will have access to the parking portal.
            </li>
            <custom-support-img
              [src]="'/assets/images/support/create-account.png'"
              [label]="'Create Account'"
            ></custom-support-img>
            <li>
              Choose a role from the drop-down list, or select 'Others' and fill
              in. This gives you access to the modules that are relevant to your
              role.
            </li>
            <li>
              You can also modify the temporary password that was provided to
              you.
            </li>
            <li>And click "Continue"</li>
            <custom-support-img
              [src]="'/assets/images/support/operator-roles.png'"
              [label]="'Operator roles'"
            ></custom-support-img>
          </ol>
        </div>
      </ng-template>
    </p-accordionTab>
    <p-accordionTab
      [selected]="getPageSection('add-parking-facility')?.selected"
    >
      <ng-template pTemplate="header">
        <div class="panel-header" #accordionHeader>
          <h2>
            <a href="/how-to#add-parking-facility" id="add-parking-facility"
              >3. Add Parking Facility</a
            >
          </h2>
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="accordion-content">
          <p>Provide the Name and the location of the venue.</p>
          <custom-support-img
            [src]="'/assets/images/support/set-name-and-location.png'"
            [label]="'Set Name and Location'"
          ></custom-support-img>
          <p class="no-margin">E.g.</p>
          <p class="no-margin">Name of Location: NCS Hub</p>
          <p class="no-margin">
            Address of Location: 5 Ang Mo Kio Street 62, NCS Hub, Singapore
            569141
          </p>
        </div>
      </ng-template>
    </p-accordionTab>
    <p-accordionTab [selected]="getPageSection('pin-location')?.selected">
      <ng-template pTemplate="header">
        <div class="panel-header" #accordionHeader>
          <h2>
            <a href="/how-to#pin-location" id="pin-location">4. Pin Location</a>
          </h2>
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="accordion-content">
          <ol type="a">
            <li>
              Based on the address provided in Step 5.3, a location pin is
              created.
            </li>
            <li>To confirm the address, click "Looks good."</li>
            <li>
              You have the option of moving the pin to a more precise place.
            </li>
          </ol>
          <custom-support-img
            [src]="'/assets/images/support/confirm-address.png'"
            [label]="'Confirm Address'"
          ></custom-support-img>
          <custom-support-img
            [src]="'/assets/images/support/drag-pin.png'"
            [label]="'Move Location Pin'"
          ></custom-support-img>
        </div>
      </ng-template>
    </p-accordionTab>
    <p-accordionTab [selected]="getPageSection('dashboard')?.selected">
      <ng-template pTemplate="header">
        <div class="panel-header" #accordionHeader>
          <h2>
            <a href="/how-to#dashboard" id="dashboard">5. Dashboard</a>
          </h2>
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="accordion-content">
          <ol type="a">
            <li>For the carpark, a dashboard is created.</li>
            <li>
              You will be able to do the following here:
              <ul>
                <li>Set up the parking availability integration.</li>
                <li>Add location-related details</li>
                <li>Set parking prices</li>
                <li>Enter features of carpark facilities</li>
                <li>
                  Review and Publish information on the Breeze driver’s app
                </li>
              </ul>
            </li>
            <li>Click on “Create New Carpark”</li>
            <li>Set a name for the carpark</li>
            <li>Set the carpark's location.</li>
            <li>
              Choose between integrating with an existing API or generating API
              tokens in our sandbox.
            </li>
          </ol>
          <custom-support-img
            [src]="'/assets/images/support/dashboard.png'"
            [label]="'Dashboard'"
          ></custom-support-img>
          <custom-support-img
            [src]="'/assets/images/support/create-carpark-step-1-location.png'"
            [label]="'Create Carpark Step 1 - Location'"
          ></custom-support-img>
        </div>
      </ng-template>
    </p-accordionTab>
    <!-- todo: add the header sections below  -->
    <p-accordionTab [selected]="getPageSection('parking-details')?.selected">
      <ng-template pTemplate="header">
        <div class="panel-header" #accordionHeader>
          <h2>
            <a href="/how-to#parking-details" id="parking-details"
              >6. Creating Details Parking Details</a
            >
          </h2>
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="accordion-content">
          Adding your carpark's Entry, Exit, and Pick-Up points will allow
          drivers to be directed to the correct areas.
          <ol type="a">
            <li>
              Add pins to the map to show the exact locations of the road
              leading to the carpark's entry, exit, and pick-up places.
            </li>
            <ul>
              <li>
                Drag the relevant Entry/ Exit/ Pick-Up icons from the bottom
                right panel onto the map screen.
              </li>
              <li>
                For example, on the bottom right panel, click the 'Entry' icon
                and drag it to the 'Ang Mo Kio Street 62' area.
              </li>
              <li>
                This adds a new 'Entry' point to the top right panel's "Carpark
                Info," which can be updated at any time. Click the "x" icon to
                delete any 'Entry' or 'Exit' points that need to be eliminated.
              </li>
            </ul>
            <li>
              The point's longitude and latitude will be determined and
              published as searchable places on Breeze.
            </li>
            <li>
              Breeze drivers will then be guided with turn-by-turn navigation
              instructions.
            </li>
          </ol>

          <custom-support-img
            [src]="'/assets/images/support/carpark-step-1-entrances.png'"
            [label]="'Carpark Step 1 - Entrances'"
          ></custom-support-img>
        </div>
      </ng-template>
    </p-accordionTab>
    <p-accordionTab [selected]="getPageSection('parking-charges')?.selected">
      <ng-template pTemplate="header">
        <div class="panel-header" #accordionHeader>
          <h2>
            <a href="/how-to#parking-charges" id="parking-charges"
              >7. Set Parking Charges</a
            >
          </h2>
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="accordion-content">
          <p>
            Drivers will be kept up to date on the newest parking prices if
            updated and accurate parking charges are published. It also allows
            drivers to use the Breeze Parking Calculator on the mobile app to
            estimate parking fees before visiting your location.
          </p>
          <custom-support-img
            [src]="'/assets/images/support/parking-calculator.png'"
            [label]="'Breeze Parking Calculator'"
          ></custom-support-img>
          <custom-support-img
            [src]="'/assets/images/support/sample-price.png'"
            [label]="'Sample Price'"
          ></custom-support-img>
          <div class="step-section">
            <strong>Step 1: </strong><span>Select the required day(s)</span>
            <ol type="a">
              <li>
                If parking fees are the same every day across time slots, select
                "All days."
              </li>
              <li>
                If not, choose the precise days that apply to the various
                parking fees. By selecting the selected day again, you can
                deselect it.
              </li>
            </ol>
            <custom-support-img
              [src]="'/assets/images/support/select-carpark-days.png'"
              [label]="'Select Parking days'"
            ></custom-support-img>
          </div>
          <div class="step-section">
            <div>
              <strong>Step 2: </strong><span>Set time & parking charges</span>
            </div>
            <ol type="a">
              <li>
                Set the time slots for parking. Please use a four-digit format
                with a 24-hour format (eg, 04:00). The time frame should not be
                longer than 24 hours. If it's 7 a.m. to 7 a.m. the next day, the
                time window should be 07:00 to 07:00.
              </li>
              <li>
                Choose either a 'per minute' or a 'flat rate' charging model
                from the drop-down menu. You can set the parking charge for the
                first x minutes (the initial block of time), as well as the
                remainder or subsequent minutes, per minute. Consider the
                following scenario:
                <ul>
                  <li>
                    Select the 'per minute' billing type if you need an initial
                    block of time.
                  </li>
                  <li>
                    Enter the time duration (always in minutes) in the length
                    field, and the applicable parking charge in the rate field.
                  </li>
                </ul>
              </li>
              <li>
                Fill in the next time block by minute, along with the applicable
                parking fee.
              </li>
              <li>
                By clicking on 'Add more time slots,' you can add extra time
                slots. Enter the next time in minutes and its rate in the same
                way. The parking fee will be calculated every x minutes
                specified in the time field. Consider the following scenario:
                <ul>
                  <li>
                    $0.75 will be added to the initial amount every 30 minutes.
                    Any number of times after that can be added. Every
                    succeeding time will be calculated as the 2nd hour, 3rd
                    hour, and so on.
                  </li>
                </ul>
              </li>
            </ol>
            <custom-support-img
              [src]="'/assets/images/support/set-hours.png'"
              [label]="'Set Parking Hours'"
            ></custom-support-img>
          </div>
          <div class="step-section">
            <strong>Step 3: </strong
            ><span>Add Grace Period Extra Charges </span>
            <ul>
              <li>
                If applicable, choose a grace period. When you select "Yes,"
                enter the grace period's duration.
              </li>
            </ul>
            <custom-support-img
              [src]="'/assets/images/support/extra-charges.png'"
              [label]="'Extra charges'"
            ></custom-support-img>
          </div>
          <div class="step-section">
            <strong>Step 3: </strong
            ><span>Add Grace Period Extra Charges </span>
            <ol type="a">
              <li>
                If applicable, choose a grace period. When you select "Yes,"
                enter the grace period's duration.
              </li>
            </ol>
          </div>
          <div class="step-section">
            <strong>Step 4: </strong><span>Add Extra Charges</span>
            <ol type="a">
              <li>
                Add additional fees, such as a capped amount/surcharge or a
                maximum charge. From the drop-down menu, choose the sort of
                additional charge. Fill in the appropriate charging fee.
              </li>
            </ol>
            <custom-support-img
              [src]="'/assets/images/support/extra-charges.png'"
              [label]="'Grace Period & Extra Charges'"
            ></custom-support-img>
          </div>
          <p>
            After these four steps have been accomplished, you can add a
            different time slot or pricing for a different day.
          </p>
          <h3>Parking Charges Preview</h3>
          <p>
            On the right panel, an example illustration of how it is displayed
            to Breeze drivers is provided.
          </p>
          <custom-support-img
            [src]="'/assets/images/support/full-pricing.png'"
            [label]="'Parking Charges'"
          ></custom-support-img>
          <p>
            To review the parking charge entries, click "Price Preview." You can
            also use the pencil icon to edit any of the entries.
          </p>
          <custom-support-img
            [src]="'/assets/images/support/price-preview-button.png'"
            [label]="'Price Preview Button'"
          ></custom-support-img>
          <custom-support-img
            [src]="'/assets/images/support/price-preview.png'"
            [label]="'Parking Charges Preview'"
          ></custom-support-img>
        </div>
      </ng-template>
    </p-accordionTab>

    <!-- do from here......  -->

    <p-accordionTab [selected]="getPageSection('carpark-amenities')?.selected">
      <ng-template pTemplate="header">
        <div class="panel-header" #accordionHeader>
          <h2>
            <a href="/how-to#carpark-amenities" id="carpark-amenities"
              >8. Updating Carpark Amenities</a
            >
          </h2>
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="accordion-content">
          <p>
            With this area, you can effortlessly direct Breeze drivers to your
            parking facilities. Include amenities such as specific accessible
            parking places, electric vehicle charging stations, CashCard top-up
            points, car grooming, and valet services in the carpark. This
            provides useful information to Breeze drivers when arranging a trip
            to your property.
          </p>
          <ol type="a">
            <li>For each amenity, select and enter appropriate information.</li>
            <custom-support-img
              [src]="'/assets/images/support/features-(1).png'"
              [label]="'Features (1)'"
            ></custom-support-img>
            <custom-support-img
              [src]="'/assets/images/support/features-(2).png'"
              [label]="'Features (2)'"
            ></custom-support-img>
            <li>
              For simple access and visual understanding, you can also upload
              photographs of the amenities, entry and exit locations. Each image
              can have a title and a description. Note: The maximum file size is
              10 MB.
            </li>
            <custom-support-img
              [src]="'/assets/images/support/upload-image.png'"
              [label]="'Upload Image'"
            ></custom-support-img>
            <li>
              You will be able to see a preview of all the amenities. This gives
              you a quick overview of all the information you've entered. By
              selecting the pencil icon within each section, you can make
              changes to any entry.
            </li>
            <custom-support-img
              [src]="'/assets/images/support/preview.png'"
              [label]="'Preview'"
            ></custom-support-img>
          </ol>
        </div>
      </ng-template>
    </p-accordionTab>
    <p-accordionTab [selected]="getPageSection('carpark-list')?.selected">
      <ng-template pTemplate="header">
        <div class="panel-header" #accordionHeader>
          <h2>
            <a href="/how-to#carpark-list" id="carpark-list"
              >9. View Carpark List</a
            >
          </h2>
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="accordion-content">
          <ol type="a">
            <li>On the Preview page, click "Continue."</li>
            <li>
              Click “View Carpark List”. You will see the new carpark added to
              the inventory
            </li>
            <li>
              You can add several carparks, update their details, or remove them
              altogether.
            </li>

            <custom-support-img
              [src]="'/assets/images/support/carpark-list.png'"
              [label]="'Carpark List'"
            ></custom-support-img>
            <li>
              Check the status of the carpark's submission.
              <ul>
                <li>
                  The term "In-progress" refers to the fact that not all
                  information has been provided.
                </li>
                <li>
                  “Verifying” denotes that the Breeze mobile application team
                  has yet to review and verify the information you've given.
                </li>
                <li>
                  The term "Published" refers to when data has been successfully
                  deployed to the Breeze mobile app.
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </ng-template>
    </p-accordionTab>
    <p-accordionTab [selected]="getPageSection('broadcast-messages')?.selected">
      <ng-template pTemplate="header">
        <div class="panel-header" #accordionHeader>
          <h2>
            <a href="/how-to#broadcast-messages" id="broadcast-messages"
              >10. Broadcast Messages</a
            >
          </h2>
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="accordion-content">
          <p>
            Connect with Breeze drivers en route to or searching for your
            property to send them valuable messages ranging from arrival
            instructions to merchant offers, or to notify them when the carpark
            is expected to be full on a particular day or time.
          </p>
          <p>
            These messages can be sent at predetermined periods on a regular
            basis. Set a start and end date and time that you want. All
            broadcasted messages are listed in a table with status indicators
            for easy reference. Each message can be paused, edited, or deleted.
          </p>
          <custom-support-img
            [src]="'/assets/images/support/create-broadcast-message.png'"
            [label]="'Create Broadcast Message'"
          ></custom-support-img>
          <custom-support-img
            [src]="'/assets/images/support/broadcast-table.png'"
            [label]="'Broadcast Table'"
          ></custom-support-img>
        </div>
      </ng-template>
    </p-accordionTab>
    <p-accordionTab
      [selected]="getPageSection('availability-integration')?.selected"
    >
      <ng-template pTemplate="header">
        <div class="panel-header" #accordionHeader>
          <h2>
            <a
              href="/how-to#availability-integration"
              id="availability-integration"
              >11. Parking Availability Integration</a
            >
          </h2>
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="accordion-content">
          <p>
            Breeze drivers can use the parking availability integration to find
            the precise number of available parking lots in real time. There are
            two integration options:
          </p>
          <ol type="i">
            <li>Breeze app integrating with carparks or</li>
            <li>Carparks integrating with the Breeze app</li>
          </ol>
          <section>
            <h3>Option 1: Breeze app integrating with carparks</h3>
            <ol type="a">
              <li>
                The integration API URL as well as authorization information
                (email, password, and token) must be provided.
              </li>
              <li>
                For any additional clarification, the postman collection must be
                uploaded along with the support email. If necessary, you can
                also add any additional steps or comments in the provided space.
              </li>
            </ol>
            <custom-support-img
              [src]="
                '/assets/images/support/availability-breeze-integrating-with-operators.png'
              "
              [label]="'Availability - Breeze integrating with Operators'"
            ></custom-support-img>
          </section>
          <section>
            <h3>Option 2: Carparks integrating with the Breeze app</h3>
            <ol type="a">
              <li>
                The second approach is to obtain the token from the Breeze app
                and use it in conjunction with the provided code.
              </li>
              <li>
                To do so, you'll need to create a token. Note: A collection of
                sample code for a variety of languages, including C#, Java,
                Python, and Node.js are given in the 'How to Integrate' tab.
              </li>
              <li>
                The Request Log is displayed in real time in the Usage Table.
              </li>
              <li>
                Customised filters allow you to look at the log at any point in
                time.
              </li>
            </ol>
            <custom-support-img
              [src]="
                '/assets/images/support/operators-integration-token-generation.png'
              "
              [label]="'Operators Integration - Token Generation'"
            ></custom-support-img>
            <custom-support-img
              [src]="'/assets/images/support/operators-integration-how-to.png'"
              [label]="'Operators Integration - How to'"
            ></custom-support-img>
            <custom-support-img
              [src]="
                '/assets/images/support/operators-integration-usage-table.png'
              "
              [label]="'Operators Integration - Usage Table'"
            ></custom-support-img>
          </section>
        </div>
      </ng-template>
    </p-accordionTab>
    <p-accordionTab [selected]="getPageSection('managing-account')?.selected">
      <ng-template pTemplate="header">
        <div class="panel-header" #accordionHeader>
          <h2>
            <a href="/how-to#managing-account" id="managing-account"
              >12. Managing Account</a
            >
          </h2>
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="accordion-content">
          <ol type="a">
            <li>
              In the top right corner, click on the account icon and select
              “Account".
            </li>
            <li>Under "Account Info," edit the required fields.</li>
            <li>At the bottom of the page, click "Save."</li>
          </ol>
          <custom-support-img
            [src]="'/assets/images/support/account-information.png'"
            [label]="'Account Information'"
          ></custom-support-img>
          <section class="sub-section-wrapper">
            <section class="sub-section">
              <h3>12.1 Change Password</h3>
              <ol>
                <li>
                  Scroll down to the Password section and enter the current and
                  the new password
                </li>
                <li>
                  After you've entered your new password, click "Update
                  Password.”
                </li>
              </ol>
            </section>
            <section class="sub-section">
              <h3>12.2 Forgot Password</h3>
              <ol type="a">
                <li>
                  Click "I don't remember the password" on the Login page.
                </li>
                <li>
                  Click "Submit" after entering your user email (the email
                  address associated with your account).
                </li>
                <li>
                  You will receive an email with a code. Click "Submit" after
                  entering the code to create a new password.
                </li>
              </ol>
              <custom-support-img
                [src]="'/assets/images/support/forgot-password.png'"
                [label]="'Forgot Password - Enter email'"
              ></custom-support-img>
              <custom-support-img
                [src]="'/assets/images/support/update-password.png'"
                [label]="'Update Password'"
              ></custom-support-img>
            </section>
          </section>
        </div>
      </ng-template>
    </p-accordionTab>
    <p-accordionTab
      [selected]="getPageSection('frequently-asked-question')?.selected"
    >
      <ng-template pTemplate="header">
        <div class="panel-header" #accordionHeader>
          <h2>
            <a
              href="/how-to#frequently-asked-question"
              id="frequently-asked-question"
              >13. Frequently Asked Questions</a
            >
          </h2>
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="accordion-content">
          <p>
            Please visit the
            <a href="https://www.breezeparkeasy.sg/" target="_blank"
              >Breeze ParkEasy website</a
            >
            to see the Frequently Asked Questions.
          </p>
        </div>
      </ng-template>
    </p-accordionTab>
  </p-accordion>
</div>
<p-scrollTop></p-scrollTop>
