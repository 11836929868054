import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { BroadcastStatusAttributesMap } from '../broadcast/broadcast-create/broadcast-create.component';
import { BroadCastModel, MessageStatuses } from '../broadcast/broadcast.model';
import { CarparkKeyDisplayMap } from '../carpark/carpark-create-main/carpark.model';
import { AccountService } from './account.service';
import { ApiService } from './api.service';
import {
  LS_IS_MAIN_NAV_ENABLE_FORCED,
  LocalStorageService,
} from './local-storage.service';

//not used now
export const WHOLE_NUMBER_VALIDATOR_PATTERN = Validators.pattern(/^\d*$/);
export const DECIMAL_NUMBER_VALIDATOR_PATTERN =
  Validators.pattern(/^\d*\.?\d+$/);

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(
    private accountService: AccountService,
    private apiService: ApiService,
    private lsSrv: LocalStorageService
  ) {}

  // function to Add st, nd, rd and th (ordinal) suffix to a number
  // https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
  getOrdinalSuffixedNumbers(i: number) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + 'st';
    }
    if (j == 2 && k != 12) {
      return i + 'nd';
    }
    if (j == 3 && k != 13) {
      return i + 'rd';
    }
    return i + 'th';
  }

  getBroadcastStatusAttributes(status: MessageStatuses = 'pending') {
    if (this.accountService.isAdminUser && status === 'pending')
      return BroadcastStatusAttributesMap['reviewing'];

    return (
      BroadcastStatusAttributesMap[status] ||
      BroadcastStatusAttributesMap['pending']
    );
  }

  getAdjustedBroadcastStatus(broadcast: BroadCastModel) {
    /* Note: the extra logic below makes it inconsistent with the data we have
    in BE until the scheduler is run. so skipping it for now */
    // if (!broadcast?.isTimed) return broadcast?.status;
    // if (broadcast?.status == 'archived') return broadcast?.status;
    // const { end, start } = broadcast?.startEnd || {};
    // const currentDate = new Date().getTime();
    // if (start && end) {
    //   const startDate = new Date(start).getTime();
    //   const endDate = new Date(end).getTime();
    //   if (endDate < currentDate || startDate > currentDate) {
    //     return 'paused';
    //   }
    // }
    return broadcast?.status;
  }

  getTranslationFor(key: string, fallbackValue?: string) {
    let translation = this.apiService.carparkKeyDisplayMapFromBE?.[key];
    translation ??= CarparkKeyDisplayMap[key];
    return translation ?? fallbackValue ?? key;
  }

  private mainNavDisplayStatusSource = new Subject<Boolean>();
  mainNavDisplayStatus$ = this.mainNavDisplayStatusSource.asObservable();

  updateForcedMainNavDisplayStatus(show: boolean) {
    if (show) this.lsSrv.setItem(LS_IS_MAIN_NAV_ENABLE_FORCED, '1');
    else this.lsSrv.removeItem(LS_IS_MAIN_NAV_ENABLE_FORCED);
    this.mainNavDisplayStatusSource.next(show);
  }

  enableIfWholeNumberOnKeyup(e: any) {
    if (isNaN(e.key)) {
      e.preventDefault();
    }
  }

  enablePositiveDecimalOnKeyup(e: any) {
    if (isNaN(e.key) && e.key !== '.') {
      e.preventDefault();
    }
  }

  preventClickBubbling(event: any) {
    event?.stopPropagation?.();
  }

  getTimeForSGTEoD(d = new Date()) {
    // construct UTC end of day for SGT date
    const newDate = new Date(d.getTime());
    newDate.setUTCMilliseconds(999);
    newDate.setUTCSeconds(59);
    newDate.setUTCMinutes(59);
    newDate.setUTCHours(15);
    newDate.setUTCFullYear(d.getFullYear());
    newDate.setUTCMonth(d.getMonth());
    newDate.setUTCDate(d.getDate());
    return newDate;
  }

  // SoD stands for Start of Day
  getTimeForSGTSoD(d = new Date()) {
    // construct UTC start of day for SGT date
    const newDate = new Date(d.getTime());
    newDate.setUTCMilliseconds(0);
    newDate.setUTCSeconds(0);
    newDate.setUTCMinutes(0);
    newDate.setUTCHours(16);
    newDate.setUTCFullYear(d.getFullYear());
    newDate.setUTCMonth(d.getMonth());
    newDate.setUTCDate(d.getDate() - 1);
    return newDate;
  }

  // reset time to 0 and get just the date back based on SGT date of the dateString provided
  getDateForSGT(dateString: string) {
    const trimmedDateStringBasedOnSGT = new Date(dateString).toLocaleDateString(
      'en-US',
      {
        timeZone: 'Asia/Singapore',
      }
    );
    return new Date(trimmedDateStringBasedOnSGT).toISOString();
  }
}
