import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-character-count',
  templateUrl: './character-count.component.html',
  styleUrls: ['./character-count.component.scss'],
})
export class CharacterCountComponent implements OnInit {
  @Input('styleClass') styleClass: string;
  @Input('count') count: number;
  @Input('suffix') suffix: string;
  @Input('prefix') prefix: string;
  @Input('position') position: {
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
  };

  posTop: string | undefined = 'initial';
  posBottom: string | undefined = '0px';
  posLeft: string | undefined = 'initial';
  posRight: string | undefined = '0px';

  constructor() {}
  ngOnInit(): void {
    this.posTop = this.position?.top || this.posTop;
    this.posBottom = this.position?.bottom || this.posBottom;
    this.posLeft = this.position?.left || this.posLeft;
    this.posRight = this.position?.right || this.posRight;
  }
}
