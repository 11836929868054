<div class="nav-wrapper">
  <nav>
    <p-menubar [model]="items" class="ngicon">
      <ng-template pTemplate="start">
        <img
          src="assets/images/breeze-parkeasy.png"
          height="46"
          class="p-mr-2"
        />
      </ng-template>
      <ng-template pTemplate="end">
        <i class="pi pi-bell" (click)="openNotifications($event, notification)">
          <span *ngIf="unreadCount">{{ unreadCount }}</span>
        </i>
        <p-overlayPanel
          #notification
          [styleClass]="'navbar-notification-overlay'"
        >
          <ng-template pTemplate>
            <app-notification
              [notifications]="notificationList"
              (markAllAsRead)="markNotificationsAsRead($event)"
            ></app-notification>
          </ng-template>
        </p-overlayPanel>
        <span>
          <div class="profile-initials" (click)="op.toggle($event)">
            {{ initials }}
          </div>
          <p-overlayPanel #op [dismissable]="true">
            <ng-template pTemplate>
              <div class="profilename-show">
                Signed in as {{ accountService.userName }}
              </div>
              <ul class="prlist">
                <li>
                  <a [routerLink]="['/account', 'profile']" (click)="op.hide()">
                    <img
                      src="../../../assets/images/icons/user-icon.svg"
                    />Account</a
                  >
                </li>
                <li>
                  <a (click)="logout()" (click)="op.hide()">
                    <img
                      src="../../../assets/images/icons/log-out-icon.svg"
                    />Logout</a
                  >
                </li>
              </ul>
            </ng-template>
          </p-overlayPanel>
        </span>
      </ng-template>
    </p-menubar>
  </nav>
</div>
