import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccordionModule } from 'primeng/accordion';
import { MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { ImageModule } from 'primeng/image';
import { InputTextModule } from 'primeng/inputtext';
import { MenubarModule } from 'primeng/menubar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ScrollTopModule } from 'primeng/scrolltop';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FirstTimeDashComponent } from './dashboard/first-time-dash/first-time-dash.component';
import { NavbarComponent } from './dashboard/navbar/navbar.component';
import { CarparkListFooterComponent } from './dashboard/new-carpark-list/carpark-list-footer/carpark-list-footer.component';
import { NewCarparkListComponent } from './dashboard/new-carpark-list/new-carpark-list.component';
import { NotificationComponent } from './dashboard/notification/notification.component';
import { MobileViewFallbackComponent } from './mobile-view-fallback/mobile-view-fallback.component';
import { EventService } from './services/event.service';
import { ApiInterceptor } from './services/interceptors/api.interceptor';
import { SharedModule } from './shared/shared.module';
import {
  CustomSupportComponent,
  SupportComponent,
} from './support/support.component';
const PrimeNgModules = [
  MenubarModule,
  TabViewModule, //note: had to use TabViewModule to get pTemplate to work, even though couldn't find it in the docs,
  InputTextModule,
  OverlayPanelModule,
  ProgressSpinnerModule,
  ToastModule,
  ConfirmDialogModule,
  DialogModule,
  PaginatorModule,
  ReactiveFormsModule,
  AccordionModule,
  ScrollTopModule,
  ImageModule,
];

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    NavbarComponent,
    NewCarparkListComponent,
    CarparkListFooterComponent,
    NotificationComponent,
    SupportComponent,
    CustomSupportComponent,
    FirstTimeDashComponent,
    MobileViewFallbackComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ...PrimeNgModules,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
  ],
  providers: [
    EventService,
    MessageService,
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
