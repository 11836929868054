import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CarparkService } from '../../../../services/carpark.service';
import { CommonService } from '../../../../services/common.service';
import { FormUtilsService } from '../../../../services/form-utils.service';
import { CappedChargeTypes, ChargingType, DAYS } from '../../carpark.model';

// todo: new type for 24hrs may come --ys
const CappedChargeTypesOptions = [
  { label: 'No capped charge', value: 'none' },
  { label: 'Time slot', value: 'timeslot' },
  { label: '24-hour', value: '24hrs' },
  { label: 'Per day', value: 'perday' },
];

export const DaysMap = [
  {
    display: 'Sun',
    key: DAYS.SUNDAY,
  },
  {
    display: 'Mon',
    key: DAYS.MONDAY,
  },
  {
    display: 'Tue',
    key: DAYS.TUESDAY,
  },
  {
    display: 'Wed',
    key: DAYS.WEDNESDAY,
  },
  {
    display: 'Thu',
    key: DAYS.THURSDAY,
  },
  {
    display: 'Fri',
    key: DAYS.FRIDAY,
  },
  {
    display: 'Sat',
    key: DAYS.SATURDAY,
  },
  {
    display: 'PH Eve',
    key: DAYS.PUBLIC_HOLIDAY_EVE,
  },
  {
    display: 'PH',
    key: DAYS.PUBLIC_HOLIDAY,
  },
];

export const GracePeriodOptions = [
  { label: 'No grace period', value: 0 },
  { label: '5 min', value: 5 },
  { label: '10 min', value: 10 },
  { label: '15 min', value: 15 },
  { label: '30 min', value: 30 },
  { label: '60 min', value: 60 },
  { label: 'Others', value: 'other' },
];

@Component({
  selector: 'app-carpark-price-entry',
  templateUrl: './carpark-price-entry.component.html',
  styleUrls: ['./carpark-price-entry.component.scss'],
})
export class CarparkPriceEntryComponent implements OnInit {
  days = DaysMap;
  gracePeriodOptions = GracePeriodOptions;
  cappedChargeTypes = CappedChargeTypesOptions;
  defaultTime = new Date(1990, 1, 1, 6, 0, 0);
  /* Note: only time is relevant here since we are storing back as string time of
    format '00:00'; setting default as 6:00 for now so that with one click it
  will switch to 7:00 */

  newChargingType: {
    label: string;
    value: ChargingType;
  }[] = [
    {
      label: 'Subsequent Charge',
      value: ChargingType.SUBSEQUENT_CHARGE,
    },
    {
      label: 'Minutes',
      value: ChargingType.MINUTE,
    },
    {
      label: 'Per Entry',
      value: ChargingType.PER_ENTRY,
    },
    {
      label: 'Free',
      value: ChargingType.FREE,
    },
  ];

  typesOfCharging_2 = [
    {
      label: 'Select',
      value: 'none',
    },
    {
      label: 'Capped',
      value: 'capped',
    },
    {
      label: 'Surcharge',
      value: 'sur_charge',
    },
    {
      label: 'Max charge',
      value: 'max_charge',
    },
  ];

  @Input('rateFormGroup') rateFormGroup: any;
  @Input('index') index: any;
  @Input('tabName') tabName: string;
  @Input('ratesFA') ratesFA: FormArray;
  @Output() delete = new EventEmitter<string>();
  cappedRateFG: FormGroup;
  rateFG: FormGroup;
  ChargingType = ChargingType;
  constructor(
    private fb: FormBuilder,
    public fUtils: FormUtilsService,
    private carparkService: CarparkService,
    public commonUtils: CommonService
  ) {}

  ngOnInit(): void {
    this.rateFG = this.rateFormGroup;
    this.initRateFGForEmpty();
  }

  initRateFGForEmpty() {
    if (!this.rateFG.value.pricing_slots.length) {
      const initialPricingSlot = this.initParkingSlotForChartingType(
        ChargingType.SUBSEQUENT_CHARGE
      );
      this.rateFG.setControl(
        'pricing_slots',
        this.fb.array([initialPricingSlot])
      );
    }
    this.cappedRateFG = this.rateFG.get('cappedCharge') as FormGroup;

    // handle cappedRate type changes
    this.cappedRateFG
      .get('type')
      ?.valueChanges.subscribe(this.handleCappedRateTypeChange.bind(this));
  }

  handleCappedRateTypeChange(type: CappedChargeTypes) {
    switch (type) {
      case '24hrs':
      case 'perday':
        this.cappedRateFG = this.fb.group({
          type: type,
          slots: this.fb.array([
            this.fb.group({
              charge: [undefined, [Validators.required, Validators.min(0)]],
            }),
          ]),
        });
        break;
      case 'timeslot':
        this.cappedRateFG = this.fb.group({
          type: 'timeslot',
          slots: this.fb.array([
            this.fb.group({
              start: [null, [Validators.required]],
              end: [null, [Validators.required]],
              charge: [undefined, [Validators.required, Validators.min(0)]],
            }),
          ]),
        });
        break;
      case 'none':
        this.cappedRateFG = this.fb.group({
          type: 'none',
        });
        break;
    }

    this.rateFG.setControl('cappedCharge', this.cappedRateFG);
    this.cappedRateFG
      .get('type')
      ?.valueChanges.subscribe(this.handleCappedRateTypeChange.bind(this));
  }

  handleGracePeriodChange(currentPricingSlotFG: FormGroup) {
    const gracePeriodType =
      currentPricingSlotFG.get('grace_period_type')!.value;
    currentPricingSlotFG.get('grace_period')?.clearValidators();

    if (gracePeriodType !== 'other')
      currentPricingSlotFG.get('grace_period')?.setValue(gracePeriodType);
    else {
      currentPricingSlotFG.get('grace_period')?.setValue(null);
      currentPricingSlotFG
        .get('grace_period')
        ?.addValidators(Validators.required);
      // this will force the validator to trigger? somehow kept resetting existing validators without this code. //todo: review later?
    }
  }

  isDayDisabled(day: DAYS) {
    const rates = this.ratesFA.value;
    const otherSelectedDays = rates
      .filter((e: any, i: number) => i !== this.index)
      .flatMap((rateDetails: any) => rateDetails.day);
    return otherSelectedDays.includes(day);
  }

  getPerMinRate(charge: number, duration: number) {
    const perMinRate = charge / duration;
    return !isNaN(perMinRate) ? perMinRate.toFixed(2) : 'NA';
  }

  initParkingSlotForChartingType(newChargingType: ChargingType) {
    let newPricingSlotFG: FormGroup;
    switch (newChargingType) {
      case ChargingType.FREE:
        newPricingSlotFG = this.carparkService.generatePricingSlotFG({
          charging_type: newChargingType,
          start_time: '',
          end_time: '',
          grace_period: 0,
          grace_period_type: 0,
        });
        break;
      case ChargingType.MINUTE:
        newPricingSlotFG = this.carparkService.generatePricingSlotFG({
          charging_type: newChargingType,
          grace_period: 0,
          grace_period_type: 0,
          start_time: '',
          end_time: '',
          display_charge_rate: undefined,
          display_charge_duration: undefined,
          min_rate: undefined,
          min_duration: undefined,
        });
        break;
      case ChargingType.PER_ENTRY:
        newPricingSlotFG = this.carparkService.generatePricingSlotFG({
          charging_type: newChargingType,
          grace_period: 0,
          grace_period_type: 0,
          start_time: '',
          end_time: '',
          entry_price: undefined,
        });
        break;
      case ChargingType.SUBSEQUENT_CHARGE:
        newPricingSlotFG = this.carparkService.generatePricingSlotFG({
          charging_type: newChargingType,
          grace_period: 0,
          grace_period_type: 0,
          start_time: '',
          end_time: '',
          entry_price: undefined,
          slot_variables: [
            {
              pricing_order: 1,
              type: 'fixed',
            },
            {
              pricing_order: 2,
              type: 'repeat',
            },
          ],
        });
        break;
    }
    return newPricingSlotFG;
  }

  handleChargingTypeChange(pIndex: number, newChargingType: ChargingType) {
    const pSFormArray = this.rateFG.get('pricing_slots') as FormArray;
    pSFormArray.removeAt(pIndex);
    const newPricingSlot = this.initParkingSlotForChartingType(newChargingType);
    pSFormArray.insert(pIndex, newPricingSlot);
  }

  getFromFGAsArray(fg: FormGroup, formArrayName: string) {
    return (<FormArray>fg.get(formArrayName)).controls as FormGroup[];
  }

  addSubSlot(pricing_slot: FormGroup) {
    const slotVariablesFA = pricing_slot.get('slot_variables') as FormArray;
    const newIndexForSubSlot = slotVariablesFA.length - 1;
    slotVariablesFA.at(newIndexForSubSlot).patchValue({
      pricing_order: newIndexForSubSlot + 2,
    });
    slotVariablesFA.insert(
      newIndexForSubSlot,
      this.fb.group({
        pricing_order: newIndexForSubSlot + 1,
        type: 'fixed',
        min_rate: ['', [Validators.required, Validators.min(0)]],
        min_duration: ['', [Validators.required, Validators.min(0)]],
      })
    );
  }

  deleteSlot(pricingSlot: FormGroup, slotIndex: number) {
    (<FormArray>pricingSlot.get('slot_variables')).removeAt(slotIndex);
  }

  deleteSubSlot(pricingSlot: FormGroup, subSlotIndex: number) {
    const slotVariables = pricingSlot.get('slot_variables') as FormArray;
    slotVariables.removeAt(subSlotIndex);
    slotVariables.controls.forEach((slotVariable, ind) => {
      slotVariable.patchValue({
        pricing_order: ind + 1,
      });
    });
  }

  deletePricingSlot(slotIndex: number) {
    const pricingSlots = <FormArray>this.rateFG.get('pricing_slots');
    pricingSlots.removeAt(slotIndex);
    pricingSlots.markAsDirty();
    if (pricingSlots.length == 0) {
      this.delete.emit('rate deleted');
    }
  }

  addTimeSlot() {
    const newPricingSlot = this.initParkingSlotForChartingType(
      ChargingType.SUBSEQUENT_CHARGE
    );
    (this.rateFG.get('pricing_slots') as FormArray).push(newPricingSlot);
    this.rateFG.markAsDirty();
  }

  onDaySelection(key: string) {
    const daysControl = this.rateFG.get('day');
    daysControl?.markAsDirty();
    const days: string[] = daysControl?.value;
    const index = days.findIndex((e: string) => e == key);
    if (index == -1) {
      // i.e. day added
      days.push(key);
      days.sort(
        (day1, day2) =>
          DaysMap.findIndex((e) => e.key === day1) -
          DaysMap.findIndex((e) => e.key === day2)
      );
      daysControl?.setValue(days);
      return;
    }
    // i.e. day removed
    days.splice(index, 1);
    this.rateFG?.get('selectAllDays')?.setValue(false);
    days.sort(
      (day1, day2) =>
        DaysMap.findIndex((e) => e.key === day1) -
        DaysMap.findIndex((e) => e.key === day2)
    );
    daysControl?.setValue(days);
  }

  clearTimeSlot() {
    const pricingSlots = <FormArray>this.rateFG.get('pricing_slots');
    pricingSlots.clear();
  }

  isDaySelected(key: string) {
    const days = this.rateFG?.get('day')?.value;
    return days?.includes(key);
  }

  getCappedChargeTypeDisplayLabel() {
    return (
      this.cappedChargeTypes
        .find((e) => e.value === this.cappedRateFG.get('type')?.value)
        ?.label?.toLowerCase() || ''
    );
  }
}
