import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as mapboxgl from 'mapbox-gl';
import { MessageService } from 'primeng/api';
import { take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AccountService } from '../../../services/account.service';
import { ApiService } from '../../../services/api.service';
import { CarparkService } from '../../../services/carpark.service';
import { CommonService } from '../../../services/common.service';
import {
  LS_IS_ADMIN,
  LS_IS_CAR_CREATE_MODE,
  LS_SELECTED_CARPARK_ID,
  LocalStorageService,
} from '../../../services/local-storage.service';
import { ConfirmProceedData } from '../../../shared/components/confirm-proceed/confirm-proceed.component';
import { CustomConfirmService } from '../../../shared/components/custom-confirm/custom-confirm.service';
import { GroupedRevisionMessages } from '../../../shared/components/revision-messages-popup/revision-messages-popup.component';
import { ConfirmationStatus } from '../../../shared/directives/confirm-proceed.directive';
import { Review, Revision } from '../../../shared/revision.model';
import { makeConvertedSVG } from '../../generic-carpark-map-icon/generic-carpark-map-icon.component';
import { svgTemplateMap } from '../../generic-carpark-map-icon/svgTemplateMap';
import {
  CARPARK_MARKER_CSS_CLASS,
  DEFAULT_LOCATION,
} from '../carpark-entrances/carpark-entrances.component';
import { CombinedRatesDetails } from '../carpark-price/carpark-price.component';
import {
  CarparkLocationMarkers,
  CarparkMapIcon,
  ClosureStatus,
  Parking,
  PublishStatus,
} from '../carpark.model';

const CARPARK_MARKER_RED_CSS_CLASS = 'red-map-marker';

export const SaveExitConfirmOptions: ConfirmProceedData = {
  header: 'Are you sure you want to save and exit?',
  confirmLabel: 'Save and Exit',
  cancelLabel: 'Cancel',
  message: [
    'We are unable to save this section because there are some required fields that are empty or incorrect. The previous section(s) will be saved but the inputs on the current page will be discarded. Do you wish to save and exit?',
  ],
  styleClass: 'confirm-save-exit',
};

export const ConfirmProceedOptions: ConfirmProceedData = {
  header: 'Are you sure you want to submit changes?',
  confirmLabel: 'Submit',
  cancelLabel: 'Cancel',
  message: [
    'Changes made to the carpark will be reviewed by Super Admin before publishing onto Breeze app.',
    'The carpark listing with already approved information will still be shown on Breeze.',
  ],
  styleClass: 'confirm-reverify',
};

export const BackButtonOptions: ConfirmProceedData = {
  header: 'Are you sure you want to go back?',
  confirmLabel: 'Proceed',
  cancelLabel: 'Cancel',
  message: [
    'All changes made on this current page will be lost. Do you want to proceed?',
  ],
  styleClass: 'confirm-back-click',
};

export interface ImagesData {
  type: string;
  displayName: string;
  images: {
    url: string;
    name: string;
  }[];
}
@Component({
  selector: 'app-carpark-preview-web',
  templateUrl: './carpark-preview-web.component.html',
  styleUrls: ['./carpark-preview-web.component.scss'],
})
export class CarparkPreviewWebComponent implements OnInit, OnDestroy {
  isLoaded = false;
  rates: any;
  ratesForUI: any;
  locationMap: mapboxgl.Map;
  entranceMap: mapboxgl.Map;
  uploadedFileDetails: any;
  publishStatus: PublishStatus;
  PublishStatuses = PublishStatus;
  confirmProceedOptions = ConfirmProceedOptions;
  isAdmin: boolean;
  allMapIcons: CarparkMapIcon[];
  activeMarkers: any[] = [];
  revisionMessages: Revision[] = [];
  groupedRevisionMessages: GroupedRevisionMessages = {
    'broadcast-message': [],
    'rate-day-block': [],
    'website-url': [],
    locationpin: [],
    'carpark-more-info': [],
    'season-rate-block': [],
    'season-contact-email': [],
  };
  showAllRevisionMessagePopup = false;
  locationLat: number;
  locationLng: number;
  combinedRatesDetails: CombinedRatesDetails;
  isGSTIncluded: FormControl;
  isOpenToPublic: FormControl;
  unApprovedReviewMessages: Review[] = [];
  organisationName: string;
  operatorName: string;
  isCreateMode: boolean;
  pricePreviewTabIndex = 0;
  showClosurePeriodPopup = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private confirmationService: CustomConfirmService,
    private messageService: MessageService,
    public accountService: AccountService,
    private fb: FormBuilder,
    private lsSrv: LocalStorageService,
    private _ngZone: NgZone,
    public cpSrv: CarparkService,
    public cmnSrv: CommonService
  ) {
    const m = Object.getOwnPropertyDescriptor(mapboxgl, 'accessToken');
    m && m.set?.(environment.mapbox.accessToken);
  }

  queryParams: any = null;
  showPreviewModal = false;
  showSuccessDialog = false;
  features: any = {};
  carparkDetails: Parking;
  currentCarparkId: string;
  ClosureStatuses = ClosureStatus;

  get isPriceEditAndPreviewDisabled() {
    return (
      this.carparkDetails?.parkingType === 'SEASON_PARKING' ||
      this.carparkDetails?.parkingType === 'PARTIAL_SEASON_PARKING'
    );
  }
  get isPermanentlyClosed() {
    return (
      this.carparkDetails?.closureStatus ===
      this.ClosureStatuses.PERMANENTLY_CLOSED
    );
  }

  async ngOnInit() {
    // below code for when using carparkId param via route =>  'carpark/:carparkId/overview'
    const params = await this.activatedRoute.params.pipe(take(1)).toPromise();
    if (params?.carparkId) {
      this.lsSrv.setItem(LS_SELECTED_CARPARK_ID, params.carparkId);
    }
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      this.queryParams = queryParams;
      this.cmnSrv.updateForcedMainNavDisplayStatus(queryParams.edit === '1');
    });

    this.currentCarparkId = this.lsSrv.getItem(LS_SELECTED_CARPARK_ID)!;

    this._ngZone.runOutsideAngular(() => {
      // this prevents mousemove from triggering angular changedetection
      this.locationMap = new mapboxgl.Map({
        container: 'ezpark-location-map',
        style: environment.mapbox.styleLink,
        center: [DEFAULT_LOCATION.longitude, DEFAULT_LOCATION.latitude],
        zoom: 16,
      });
      this.entranceMap = new mapboxgl.Map({
        container: 'ezpark-entrance-map',
        style: environment.mapbox.styleLink,
        center: [DEFAULT_LOCATION.longitude, DEFAULT_LOCATION.latitude],
        zoom: 16,
      });
    });
    this.isCreateMode = this.lsSrv.getItem(LS_IS_CAR_CREATE_MODE) === '1';

    await this.getCarparkDetails();
    await this.getCarparkMapIcons();
    this.isAdmin = this.lsSrv.getItem(LS_IS_ADMIN) == '1' ? true : false;
    await this.getRevisionMessages();
  }

  ngOnDestroy(): void {
    this.cmnSrv.updateForcedMainNavDisplayStatus(false);
    this.locationMap?.remove();
    this.entranceMap?.remove();
  }

  async getRevisionMessages() {
    this.groupedRevisionMessages = {
      'broadcast-message': [],
      'rate-day-block': [],
      'website-url': [],
      locationpin: [],
      'carpark-more-info': [],
      'season-rate-block': [],
      'season-contact-email': [],
    };
    this.revisionMessages = await this.apiService.getRevisionMessages({
      parentId: this.currentCarparkId,
      parentType: 'carpark',
    });

    this.unApprovedReviewMessages =
      this.revisionMessages
        .flatMap((revision) => revision.reviews || [])
        .filter((review) => review?.reviewState === 'pending') || [];

    this.revisionMessages.forEach((revisionMessage) => {
      const type = revisionMessage.type;
      this.groupedRevisionMessages[type].push(revisionMessage);
    });
  }

  areReviewsPendingIn(revisions: Revision[] = []) {
    return revisions.some((revision) =>
      revision.reviews?.some((review) => review.reviewState === 'pending')
    );
  }

  areReviewsPendinForChargesSection() {
    return (
      this.areReviewsPendingIn(
        this.groupedRevisionMessages['rate-day-block']
      ) ||
      this.areReviewsPendingIn(
        this.groupedRevisionMessages['rate-day-block']
      ) ||
      this.areReviewsPendingIn(
        this.groupedRevisionMessages['carpark-more-info']
      ) ||
      this.areReviewsPendingIn(this.groupedRevisionMessages['website-url']) || // todo: maybe change the type for this? --ys
      this.areReviewsPendingIn(
        this.groupedRevisionMessages['season-rate-block']
      ) ||
      this.areReviewsPendingIn(
        this.groupedRevisionMessages['season-contact-email']
      )
    );
  }

  async getCarparkMapIcons() {
    const {
      data: { icons },
    } = await this.apiService.getCarparkLocationPins(this.currentCarparkId);
    this.activeMarkers = (icons as CarparkLocationMarkers[]).flatMap(
      (iconGrp) =>
        iconGrp.markers
          .map((marker) => {
            const combinedMarker = {
              ...iconGrp,
              ...marker,
            };
            if (combinedMarker.markers) delete (combinedMarker as any).markers;
            return combinedMarker;
          })
          .filter((e) => !e.deleted)
    );

    this.entranceMap.on('load', async () => {
      this.activeMarkers.forEach((marker) => {
        this.createMarkerIcon(marker);
      });

      const el = document.createElement('div');
      el.className = `${CARPARK_MARKER_CSS_CLASS}`;
      new mapboxgl.Marker(el)
        .setLngLat([this.locationLng, this.locationLat])
        .addTo(this.entranceMap);
      const zoom = this.entranceMap.getZoom();
      this.entranceMap.flyTo({
        zoom: zoom,
        center: [this.locationLng, this.locationLat],
      });
    });
  }

  selectSeasonParkingPreviewIfNotOpenToPublic(parkingDetails: Parking) {
    if (!this.isOpenToPublic.value) {
      if (
        !parkingDetails?.rates?.loadingbay?.length &&
        parkingDetails?.seasonParking?.contactEmail
      )
        this.pricePreviewTabIndex = 1;
    }
  }

  async getCarparkDetails() {
    const { data: carparkDetails } = await this.apiService.getCarparkById(
      undefined,
      true
    );
    this.carparkDetails = carparkDetails;
    this.features = carparkDetails.features;
    this.combinedRatesDetails =
      this.cpSrv.makeCombinedRatesDetailsFormObj(carparkDetails);

    this.isGSTIncluded = this.fb.control(carparkDetails.includesGST);
    this.isOpenToPublic = this.fb.control(carparkDetails.openToPublic);
    this.selectSeasonParkingPreviewIfNotOpenToPublic(carparkDetails);

    this.isLoaded = true;
    this.uploadedFileDetails = carparkDetails?.uploadedFileDetails || {};
    this.publishStatus = carparkDetails.publishStatus || PublishStatus.PREVIEW;

    // set location and entranceMaps
    this.locationLat = carparkDetails?.lat || 0;
    this.locationLng = carparkDetails?.long || 0;
    this.locationMap.on('load', async () => {
      if (this.locationLat) {
        const el = document.createElement('div');
        el.className = `${CARPARK_MARKER_RED_CSS_CLASS}`;
        new mapboxgl.Marker(el)
          .setLngLat([this.locationLng, this.locationLat])
          .addTo(this.locationMap);
        const zoom = this.locationMap.getZoom();
        this.locationMap.flyTo({
          zoom: zoom,
          center: [this.locationLng, this.locationLat],
        });
      }
    });
    if (this.accountService.isAdminUser)
      this.apiService.getUsersByAdmin().then((users) => {
        const cpoUserDetails = users.find(
          (u) => u.operatorId === carparkDetails.operatorId
        )!;
        this.organisationName =
          cpoUserDetails?.organisationName ||
          'Go to "Manage Users" to assign Carpark to organisation';
        this.operatorName =
          cpoUserDetails?.operatorName ||
          'Go to "Manage Users" to assign carpark to operator';
      });
  }

  createMarkerIcon(marker: any) {
    const icon = document.createElement('div');
    icon.className = 'carpark-marker-icon';
    const svgTemplate =
      (marker.iconName && svgTemplateMap[marker.iconName]) ||
      svgTemplateMap._customIcon;

    icon.innerHTML = makeConvertedSVG(svgTemplate, marker);
    new mapboxgl.Marker(icon, { draggable: false })
      .setLngLat([marker.lng, marker.lat])
      .addTo(this.entranceMap);
  }

  async onContinue(confirmationStatus: ConfirmationStatus) {
    switch (confirmationStatus) {
      case ConfirmationStatus.CANCEL:
        break;
      case ConfirmationStatus.REJECT_PROCEED:
        this.router.navigate(['/dashboard']);
        break;
      case ConfirmationStatus.PROCEED: // case when confirmationProgress directive did not trigger
      case ConfirmationStatus.CONFIRM_PROCEED: // case when confirmationProgress directive triggered and user confirmed
        await this.updateCarpark(PublishStatus.VERIFYING);
        this.showSuccessDialog = true;
        break;
    }
  }

  updateCarpark(publishStatus: PublishStatus) {
    return this.apiService.updateCarpark({
      publishStatus,
    });
  }

  onConfirm() {
    this.showSuccessDialog = false;
    this.router.navigate(['/dashboard']);
  }

  onCancel() {
    this.showSuccessDialog = false;
  }

  goBack() {
    this.router.navigate(['/carpark', 'create', 'features'], {
      queryParams: this.queryParams,
    });
  }
  showPreview() {
    this.showPreviewModal = true;
  }

  async saveAndExit(confirmationStatus: ConfirmationStatus) {
    switch (confirmationStatus) {
      case ConfirmationStatus.CANCEL:
        break;
      case ConfirmationStatus.REJECT_PROCEED:
        this.router.navigate(['/dashboard']);
        break;
      case ConfirmationStatus.PROCEED: // case when confirmationProgress directive did not trigger
        const publishStatus =
          this.publishStatus == PublishStatus.VERIFYING
            ? this.publishStatus
            : PublishStatus.PREVIEW;
        await this.updateCarpark(publishStatus);
        this.router.navigate(['/dashboard']);
        break;
      case ConfirmationStatus.CONFIRM_PROCEED: // case when confirmationProgress directive triggered and user confirmed
        await this.updateCarpark(PublishStatus.VERIFYING);
        this.router.navigate(['/dashboard']);
        break;
    }
  }

  edit(page: string) {
    const pageRouteMap: any = {
      location: ['/carpark', 'create', 'location'],
      entrances: ['/carpark', 'create', 'location'],
      features: ['/carpark', 'create', 'features'],
      prices: ['/carpark', 'create', 'prices'],
    };

    const navigateOnEdit = () =>
      this.router.navigate(pageRouteMap[page], {
        queryParams: {
          ...(page === 'entrances' && { configureEntrance: 1 }),
          ...this.queryParams,
        },
      });

    if (this.cpSrv.isSubmittedStatus(this.publishStatus)) {
      this.confirmationService.confirm({
        header: 'Edit carpark',
        message: [
          `Edits made to the carpark will be reviewed by Super Admin before publishing onto Breeze app.`,
        ],
        acceptLabel: 'Proceed',
        accept: navigateOnEdit,
      });
    } else {
      navigateOnEdit();
    }
  }

  async publish() {
    if (!this.accountService.isAdminUser) return;

    if (this.isPriceEditAndPreviewDisabled) {
      this.confirmationService.confirm({
        header: 'Publishing restricted',
        message: [
          'Publishing PARTIAL SEASON PARKING or SEASON PARKING carparks is disabled at the moment',
        ],
        acceptLabel: 'Okay',
        rejectVisible: false,
      });
      return;
    } else if (this.carparkDetails.disablePublish) {
      this.confirmationService.confirm({
        header: 'Publishing restricted',
        message: ['Publishing is disabled for this carpark at the moment'],
        acceptLabel: 'Okay',
        rejectVisible: false,
      });
      return;
    }
    let confirmationMessage = `Are you sure you want to approve <b>${
      this.carparkDetails.name || 'this'
    } carpark </b>?`;
    if (this.unApprovedReviewMessages.length > 0)
      confirmationMessage =
        `There are ${this.unApprovedReviewMessages.length} unresolved revisions. ` +
        confirmationMessage;

    this.confirmationService.confirm({
      header: 'Approval confirmation',
      message: [confirmationMessage],
      accept: () => {
        this.apiService
          .publishCarpark()
          .then((result) => {
            this.messageService.add({
              key: 'tc',
              severity: 'success',
              summary: 'Success',
              detail: 'Carpark published!',
            });
          })
          .catch((err) => {
            this.messageService.add({
              key: 'tc',
              severity: 'error',
              summary: 'Error',
              detail: 'Could not publish carpark!',
            });
          })
          .finally(() => {
            this.ngOnInit();
          });
      },
      rejectLabel: 'Cancel',
      acceptLabel: 'Approve',
    });
  }

  async reject() {
    if (!this.accountService.isAdminUser) return;

    let confirmationMessage = `Are you sure you want to reject <b>${
      this.carparkDetails.name || 'this'
    } carpark</b>?`;
    let acceptVisible = true;
    let rejectLabel = 'Cancel';
    if (this.unApprovedReviewMessages.length === 0) {
      confirmationMessage =
        'Please go to either Location Pins or Parking Charges section to add comments where necessary before rejecting a carpark.';
      rejectLabel = 'Ok';
      acceptVisible = false;
    }

    this.confirmationService.confirm({
      header: 'Rejection confirmation',
      message: [confirmationMessage],
      accept: () => {
        this.apiService
          .rejectCarpark()
          .then((result) => {
            this.messageService.add({
              key: 'tc',
              severity: 'success',
              summary: 'Success',
              detail: 'Carpark rejected!',
            });
          })
          .catch((err) => {
            this.messageService.add({
              key: 'tc',
              severity: 'error',
              summary: 'Error',
              detail: 'Could not reject carpark!',
            });
          })
          .finally(() => {
            this.ngOnInit();
          });
      },
      acceptVisible,
      acceptLabel: 'Reject',
      rejectLabel,
    });
  }

  toggleAllRevisionMessagesPopup(newBlockVisibility?: boolean) {
    if (newBlockVisibility === undefined)
      this.showAllRevisionMessagePopup = !this.showAllRevisionMessagePopup;
    else this.showAllRevisionMessagePopup = newBlockVisibility;

    if (this.showAllRevisionMessagePopup === false) {
      this.ngOnInit();
    }
  }

  async handleCLosurePopupClose() {
    await this.getCarparkDetails();
    this.showClosurePeriodPopup = false;
  }
}
