import { Injectable } from '@angular/core';

// #region  localstorage keys
export const LS_LOGGED_IN = 'prkesy_logged_in';
export const LS_CARPARK_NAME = 'prkesy_carpark_name';
export const LS_USER_NAME = 'prkesy_user_name';
export const LS_USER_EMAIL = 'prkesy_user_email';
export const LS_USER_ROLE = 'prkesy_user_role';
export const LS_OPERATOR_ID = 'prkesy_operator_id';
export const LS_OPERATOR_NAME = 'prkesy_operator_name';
export const LS_OPTED_INTEGRATION_TYPE = 'prkesy_opted_integration_type';
export const LS_LOCATION_ADDRESS_DETAILS = 'prkesy_location_address_details';
export const LS_LAST_CREATE_PAGE = 'prkesy_last_create_page'; // this variable is no really being used in logic
export const LS_SELECTED_CARPARK_ID = 'prkesy_selected_carpark_id';
export const LS_SELECTED_BROADCAST_ID = 'prkesy_selected_broadcast_id';
export const LS_ENABLE_CREATE_ACCOUNT = 'prkesy_enable_create_account';
export const LS_OPERATOR_STATUS = 'prkesy_operator_status';
export const LS_IS_ADMIN = 'prkesy_is_admin';
export const LS_IMPERSONATION_ACTIVE = 'prkesy_impersonation_active';
export const LS_ADMIN_DETAILS = 'prkesy_admin_details';
export const LS_ADMIN_AUTH_TOKEN = 'prkesy_admin_auth_token';
// export const LS_SAVED_URL = 'prkesy_saved_url'
export const LS_IS_CAR_CREATE_MODE = 'prkesy_is_car_create_mode';
export const LS_IS_MAIN_NAV_ENABLE_FORCED = 'prkesy_is_main_nav_enable_forced';
export const LS_NEW_PASS_USER_ATTRIBUTES = 'prkesy_new_pass_user_attributes';

// cognito
export const LS_COGNITO_ACCESS_TOKEN = 'prkesy_cognito_access_token';
export const LS_COGNITO_ID_TOKEN = 'prkesy_cognito_id_token';
export const LS_COGNITO_REFRESH_TOKEN = 'prkesy_cognito_refresh_token';
export const LS_COGNITO_USERNAME = 'prkesy_cognito_username';
// #endregion  localstorage keys

export const LSKeysToBeClearedOnForceLogout = [
  LS_COGNITO_ACCESS_TOKEN,
  LS_COGNITO_ID_TOKEN,
  LS_LOGGED_IN,
];

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  getRawItem(lsKey: string) {
    return localStorage.getItem(lsKey);
  }

  getItem(_lsKey: string) {
    try {
      const lsKey = btoa(_lsKey);
      const _lsValue = localStorage.getItem(lsKey);
      if (!_lsValue) return _lsValue;
      return atob(_lsValue);
    } catch (e) {
      // console.error(e);  // debug-log
      // Note: todo: is it safe to log someone out if code reaches here?
      return null;
    }
  }

  setRawItem(lsKey: string, lsValue: string) {
    return localStorage.setItem(lsKey, lsValue);
  }

  setItem(_lsKey: string, _lsValue: string) {
    const lsKey = btoa(_lsKey);
    const lsValue = btoa(_lsValue);
    return localStorage.setItem(lsKey, lsValue);
  }

  removeItem(_lsKey: string) {
    const lsKey = btoa(_lsKey);
    return localStorage.removeItem(lsKey);
  }

  removeRawItem(lsKey: string) {
    return localStorage.removeItem(lsKey);
  }

  clear() {
    localStorage.clear();
  }
}
