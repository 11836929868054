<div
  #svgContainer
  [ngStyle]="{
    transform: 'scale(' + scaleFactor + ')'
  }"
  [draggable]="draggable"
  [ngClass]="{
    'carpark-marker-icon': draggable
  }"
>
  <!-- load transformed SVG from backend here -->
  {{ iconDetails?.iconId }}
  <!-- the above iconName will not be printed, but is needed to trigger change detection?  -->
  <img [src]="imgSrc" *ngIf="imgSrc" />
</div>
