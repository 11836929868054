import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  CustomConfirmation,
  CustomConfirmService,
} from './custom-confirm.service';

@Component({
  selector: 'app-custom-confirm',
  templateUrl: './custom-confirm.component.html',
  styleUrls: ['./custom-confirm.component.scss'],
})
export class CustomConfirmComponent implements OnInit {
  subscription: Subscription;
  confirmData: CustomConfirmation;
  visible = false;
  header: string;
  message: string[] = [];
  acceptLabel = 'Okay';
  rejectLabel = 'Cancel';
  acceptVisible = true;
  rejectVisible = true;
  closeVisible = true;
  dismissableMask = false;
  btnReversed = false;

  constructor(private customConfirmService: CustomConfirmService) {
    this.subscription =
      this.customConfirmService.requireConfirmation$.subscribe(
        this.initConfirmValues.bind(this)
      );
  }

  initConfirmValues(confirm: CustomConfirmation) {
    this.confirmData = confirm;
    this.confirmData.header ??= this.header;
    this.confirmData.message ??= this.message;
    this.confirmData.acceptLabel ??= this.acceptLabel;
    this.confirmData.rejectLabel ??= this.rejectLabel;
    this.confirmData.acceptVisible ??= this.acceptVisible;
    this.confirmData.rejectVisible ??= this.rejectVisible;
    this.confirmData.closeVisible ??= this.closeVisible;
    this.confirmData.dismissableMask ??= this.dismissableMask;
    this.btnReversed = this.confirmData.btnReversed || false;
    this.visible = true;
  }

  ngOnInit(): void {}

  accept() {
    this.confirmData?.accept?.();
    this.visible = false;
  }

  reject() {
    this.confirmData?.reject?.();
    this.visible = false;
  }

  close() {
    this.confirmData?.reject?.();
    this.visible = false;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  option(name: string) {
    const source = this.confirmData || this;
    if (source.hasOwnProperty(name)) {
      return (source as any)[name];
    }
    return undefined;
  }
}
