import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthAdminGuard, AuthGuard } from './services/guards/auth.guard';
import { SupportComponent } from './support/support.component';

const routes: Routes = [
  {
    path: 'account',
    loadChildren: () =>
      import('./account/account.module').then((m) => m.AccountModule),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    component: DashboardComponent,
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthAdminGuard],
  },
  {
    path: 'carpark',
    loadChildren: () =>
      import('./carpark/carpark.module').then((m) => m.CarparkModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'broadcast',
    loadChildren: () =>
      import('./broadcast/broadcast.module').then((m) => m.BroadcastModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'vouchers',
    loadChildren: () =>
      import('./vouchers/vouchers.module').then((m) => m.VouchersModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'how-to',
    component: SupportComponent,
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
