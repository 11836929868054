<div class="app-toast" *ngIf="isShow===true">
  <div class="box-toast">
    <img
          src="assets/images/icons/icon-noti.png"
          alt=""
          class="icon-noti"
        />
    <div>
      <div class="title">Notification</div>
      <div class="content">All changes will be sent for review</div>
    </div>
  </div>
</div>
