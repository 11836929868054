export enum LoggedInStatus {
  LOGGED_IN = '1',
  LOGGED_OUT = '0',
}

export enum OperatorStatus {
  CREATED = 'created',
  INITIAL_LOCATION_ADDED = 'initial_location_added',
  CARPARK_ADDED = 'carpark_added',
}

export enum SORT_TYPE {
  ASCENDING = 1,
  UNSORTED = 0,
  DESCENDING = -1,
}
