<section
  class="new-createbroadcast-wrapper"
  [class.preview]="mode === 'preview'"
>
  <section class="form-section">
    <div class="header-broadcast">
      <h3>{{ headerText }}</h3>
      <button (click)="triggerEditMode()" class="edit-btn">Edit</button>
      <div
        *ngIf="fetchedBroadcastDetails?.status"
        [ngClass]="[
          'status-pill',
          fetchedBroadcastDetails?.status || 'pending'
        ]"
        [ngStyle]="{
          color: cmnSrv.getBroadcastStatusAttributes(
            fetchedBroadcastDetails?.status
          ).color,
          'background-color': cmnSrv.getBroadcastStatusAttributes(
            fetchedBroadcastDetails?.status
          ).backgroundColor
        }"
      >
        {{
          cmnSrv.getBroadcastStatusAttributes(fetchedBroadcastDetails?.status)
            .displayText
        }}
      </div>
    </div>
    <form action="" [formGroup]="broadcastFG">
      <div
        class="form-control"
        [class.error]="
          broadcastFG?.get('carparkId')?.touched &&
          broadcastFG?.get('carparkId')?.errors
        "
      >
        <label>Carpark</label>
        <span>
          <p-dropdown
            [options]="carparkList"
            formControlName="carparkId"
            optionLabel="name"
            optionValue="carparkId"
            [virtualScroll]="true"
            [filter]="true"
            [filterBy]="'carparkId,name'"
            [itemSize]="30"
            class="carpark-select--broadcast"
            emptyMessage="No published carparks found"
            emptyFilterMessage="No published carparks found"
          ></p-dropdown>
          <span class="error-text">Select carpark</span>
        </span>
      </div>
      <div
        class="form-control message-textarea-control"
        [class.error]="
          broadcastFG?.get('content')?.touched &&
          broadcastFG?.get('content')?.errors
        "
      >
        <label for="" class="bfont">Message</label>
        <textarea
          name="content-el"
          id="content-el"
          cols="30"
          rows="10"
          placeholder="Enter content for broadcast message  "
          formControlName="content"
          maxlength="{{ MAX_CHARACTER_LIMIT }}"
        ></textarea>
        <span class="characters-left" *ngIf="mode !== 'preview'"
          >{{ charactersLeft }} characters left</span
        >
        <span class="error-text">Enter content for broadcast message</span>
      </div>
      <div class="form-control">
        <label class="bfont">Show broadcast message when user is</label>
        <p-radioButton
          name="broadcastMode"
          [value]="'planning'"
          label="on the route planning search page"
          formControlName="broadcastMode"
          [ngStyle]="{
            'margin-right': '30px'
          }"
          [class.preview]="mode === 'preview'"
          [class.preview-hidden]="
            broadcastFG.get('broadcastMode')?.value !== 'planning'
          "
          class="prkesy-theme"
        ></p-radioButton>
        <p-radioButton
          name="broadcastMode"
          [value]="'navigation'"
          label="on the carpark arrival page"
          formControlName="broadcastMode"
          [class.preview]="mode === 'preview'"
          [class.preview-hidden]="
            broadcastFG.get('broadcastMode')?.value !== 'navigation'
          "
          class="prkesy-theme"
        ></p-radioButton>
      </div>
      <div
        class="form-control date-time-checkbox-control"
        *ngIf="mode !== 'preview'"
      >
        <p-checkbox
          name="isTimed"
          [binary]="true"
          formControlName="isTimed"
          styleClass="isTimed-checkbox prkesy-theme"
          id="isTimed"
          label="Set broadcast date and time"
        ></p-checkbox>
        <!-- <label for="isTimed" class="date-time-label"
          >Set broadcast date and time</label
        > -->
      </div>
      <ng-container *ngIf="broadcastFG.value?.isTimed || mode === 'preview'">
        <div class="divflex">
          <div
            class="form-control date-time-fields"
            [class.error]="
              broadcastFG?.get('startDate')?.touched &&
              broadcastFG?.get('startDate')?.errors
            "
          >
            <label for="">Start date</label>
            <ng-container *ngIf="broadcastFG.value?.isTimed; else emptyHyphen">
              <p-calendar
                formControlName="startDate"
                dateFormat="d M yy"
                [minDate]="minDate"
                inputId="startDate"
                placeholder="Select date"
              ></p-calendar>
              <i class="pi pi-calendar" *ngIf="mode !== 'preview'"></i>
              <span class="error-text">Select start date</span>
            </ng-container>
          </div>
          <div
            class="form-control date-time-fields"
            [class.error]="
              broadcastFG?.get('startTime')?.touched &&
              broadcastFG?.get('startTime')?.errors
            "
          >
            <label for="">Start time</label>
            <ng-container *ngIf="broadcastFG.value?.isTimed; else emptyHyphen">
              <p-calendar
                formControlName="startTime"
                [timeOnly]="true"
                inputId="startTime"
                placeholder="Select time"
              ></p-calendar>
              <i class="pi pi-clock" *ngIf="mode !== 'preview'"></i>
              <span class="error-text">Select start time</span>
            </ng-container>
          </div>
        </div>
        <div class="divflex">
          <div
            class="form-control date-time-fields"
            [class.error]="
              broadcastFG?.get('endDate')?.touched &&
              broadcastFG?.get('endDate')?.errors
            "
          >
            <label for="">End date</label>
            <ng-container *ngIf="broadcastFG.value?.isTimed; else emptyHyphen">
              <p-calendar
                formControlName="endDate"
                dateFormat="d M yy"
                inputId="endDate"
                [minDate]="minDate"
                placeholder="Select date"
              ></p-calendar>
              <i class="pi pi-calendar" *ngIf="mode !== 'preview'"></i>
              <span class="error-text">Select end date</span>
            </ng-container>
          </div>
          <div
            class="form-control date-time-fields"
            [class.error]="
              broadcastFG?.get('endTime')?.touched &&
              broadcastFG?.get('endTime')?.errors
            "
          >
            <label for="">End time</label>
            <ng-container *ngIf="broadcastFG.value?.isTimed; else emptyHyphen">
              <p-calendar
                formControlName="endTime"
                [timeOnly]="true"
                inputId="endTime"
                placeholder="Select time"
              ></p-calendar>
              <i class="pi pi-clock" *ngIf="mode !== 'preview'"></i>
              <span class="error-text">Select end time</span>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <ng-container
        *ngIf="!accountService.isAdminUser; else adminActionsTemplate"
      >
        <div class="actions">
          <div
            class="actions-overlay"
            [class.create-bc]="mode === 'create'"
          ></div>
          <ng-container *ngIf="mode !== 'preview'">
            <button (click)="cancel()" class="back-btn">Cancel</button>
            <button (click)="save()" class="submit-btn">Submit</button>
          </ng-container>
          <ng-container *ngIf="mode === 'preview'">
            <button (click)="cancel()" class="back-btn">Back</button>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #adminActionsTemplate>
        <div class="actions" *ngIf="fetchedBroadcastDetails?.isActive">
          <button (click)="cancel()" class="back-btn">Back</button>
          <button
            (click)="reject()"
            class="reject-btn"
            [disabled]="
              fetchedBroadcastDetails?.status === 'rejected' ||
              fetchedBroadcastDetails?.status === 'expired'
                ? true
                : null
            "
          >
            Reject
          </button>
          <button
            (click)="approve()"
            class="submit-btn"
            [disabled]="
              fetchedBroadcastDetails?.status === 'approved' ||
              fetchedBroadcastDetails?.status === 'paused' ||
              fetchedBroadcastDetails?.status === 'broadcasting'
                ? true
                : null
            "
            [ngStyle]="{ 'margin-left': '10px' }"
          >
            Approve
          </button>
        </div>
      </ng-template>
    </form>
  </section>
  <section
    class="revision-section"
    *ngIf="
      mode !== 'create' &&
      (revisionMessagesObj?.reviews?.length || accountService.isAdminUser)
    "
  >
    <ng-container>
      <div class="header-broadcast">
        <h4>Revision message</h4>
      </div>
      <div class="revision-message-container">
        <ng-container *ngFor="let review of revisionMessagesObj?.reviews">
          <app-revision-message
            *ngIf="
              accountService.isAdminUser ||
              (!accountService.isAdminUser && review.reviewState === 'pending')
            "
            [reviewMessage]="review"
            [isAdmin]="accountService.isAdminUser"
            (toggleReviewState)="
              toggleReviewMessageStatus(revisionMessagesObj, review, $event)
            "
          ></app-revision-message>
        </ng-container>
      </div>
      <div
        class="revision-input-container"
        *ngIf="
          fetchedBroadcastDetails?.revisionId && accountService?.isAdminUser
        "
      >
        <input
          type="text"
          pInputText
          [(ngModel)]="newRevisionMessage"
          placeholder="Enter message"
        />
        <button
          class="send-button"
          (click)="addRevisionMessage()"
          [disabled]="!newRevisionMessage?.trim()?.length"
          placeholder="Enter message"
        >
          <i class="pi pi-arrow-up"></i>
        </button>
      </div>
    </ng-container>
  </section>
</section>
<ng-template #emptyHyphen>
  <span class="empty-hyphen">-</span>
</ng-template>
