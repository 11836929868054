export class SlotVariable {
  entry_price?: number;
  min_duration?: number;
  max_duration?: number;
  min_rate?: number;
  pricing_order?: number;
  type?: string;
  display_charge_rate?: number;
  display_charge_duration?: number;
}

export class PricingSlot extends SlotVariable {
  constructor() {
    super();
    this.slot_variables = [];
  }
  start_time: string;
  end_time: string;
  slot_variables?: Array<SlotVariable>;
  charging_type: ChargingType;
  grace_period?: number;
  grace_period_type?: any; // note: tobe used only in FE side
  remarks?: string;
  parking_type?: string; // 'Season' | 'PARTIAL_SEASON_PARKING'
  per_minute_enabled?: boolean;
}

export enum ClosureStatus {
  SCHEDULED_TO_TEMPORARILY_CLOSE = 'SCHEDULED_TO_TEMPORARILY_CLOSE',
  SCHEDULED_TO_PERMANENTLY_CLOSE = 'SCHEDULED_TO_PERMANENTLY_CLOSE',
  TEMPORARILY_CLOSED = 'TEMPORARILY_CLOSED',
  PERMANENTLY_CLOSED = 'PERMANENTLY_CLOSED',
}

export enum ChargingType {
  SUBSEQUENT_CHARGE = 'subsequent_charge',
  MINUTE = 'minute',
  PER_ENTRY = 'fixed',
  FREE = 'free',
}

export type CappedChargeTypes = '24hrs' | 'timeslot' | 'perday' | 'none'; // note: update backend code to include none;  --ys

export class ParkingRate {
  constructor() {
    this.day = [];
    this.pricing_slots = [];
  }
  day: Array<DAYS>;
  pricing_slots?: Array<PricingSlot>;
  revisionId: string | null;
  formattedDays?: string; // formatter string returned by backend
  deleted: boolean;
  reviewState?: ReviewState;
  cappedCharge?: {
    type: CappedChargeTypes;
    slots: {
      // for 24hrs only one slot and no start/end
      start?: string;
      end?: string;
      charge: number;
    }[];
  };
}

export interface CarparkMapPoints {
  displayValue: string;
  lat: number;
  lng: number;
  description?: string;
}

export enum VehicleTypes {
  CARS = 'cars',
  LOADING_BAY = 'loadingbay',
  MOTORCYCLES = 'motorcycles',
}

export interface SeasonalDetails {
  isEnabled: boolean;
  duration: number;
  unit: 'Y' | 'Q' | 'M' | 'other';
  charges: number;
  durationDetails?: string;
  remarks: string;
  otherDetails: string;
  revisionId: string | null;
  reviewState: ReviewState;
}

export class Parking {
  // fields obtained from ezpark-admin
  id?: string;
  breezeId?: string;
  name?: string;
  address?: string;
  parkingType?: string;
  disablePublish?: boolean;
  parkingSystem?: string;
  lat?: number;
  long?: number;
  location?: {
    type: string; // this will always be 'Point'
    coordinates: [number, number];
  };
  grace_period?: {
    duration?: number;
    unit: string;
  };
  info?: {
    [key: string]: any;
    /* 
    eg: 
    {
      car: {
        "url":  {
          "revisionId"        // TODO: FOR REVISION MESSAGES TO BE ASSIGNED TO THIS BLOCK, THE STRUCTURE HAS TO BE UPDATED LIKE THIS
          "", // url from operator
        }
        "remarks": "",
        "contact": "contact@abccarpark.com"
      },
      motorcycle: same..
    }*/
  };
  rates?: {
    [key in VehicleTypes]: ParkingRate[];
  };
  seasonParking: {
    // [key in VehicleTypes]: SeasonalDetails; // Note: doing this causes issues with typing so have to manually list them here
    [VehicleTypes.CARS]: SeasonalDetails;
    [VehicleTypes.LOADING_BAY]: SeasonalDetails;
    [VehicleTypes.MOTORCYCLES]: SeasonalDetails;
    contactEmail: string;
  };

  features?: Features;
  // parkeasy specific fields
  operatorId?: string;
  source?: string;
  allDay?: string;
  uploadedFileDetails?: any;
  publishStatus?: PublishStatus;
  createdOn?: Date;
  createdBy?: string;
  updatedOn?: Date;
  updatedBy?: string;
  includesGST: boolean;
  openToPublic: boolean;
  availableLots: number;
  availableMCLots: number;
  liveInBreeze?: boolean;
  closureStatus?: ClosureStatus;
  closureStartDate: string;
  closureEndDate: string;
  isScheduleToPermanentlyClose: boolean;
}

export interface Features {
  // carpark_total: number;
  carpark_types: Array<string>;
  carpark_location: Array<string>;
  handicap_vehicle_lot: {
    isAvailable: boolean;
    no_of_lots: number;
    details: string;
  };
  carpark_total_lots?: {
    isAvailable: boolean;
    value: number;
    details: string;
  };
  carpark_height?: {
    isAvailable?: true;
    value: number;
    details: string;
  };
  ev_charging_lot: {
    isAvailable: boolean;
    no_of_lots: number;
    price_of_charging: number;
    types: Array<string>;
    details: string;
  };
  // old structure for operating_hours; not used anymore
  // operating_hours: {
  //   hours: string; // "24_hrs" or "limited"
  //   from: Date;
  //   to: Date;
  //   after_hour_contact_no: string;
  // };
  operating_hours: {
    dayTypeKey: DAYS;
    dayTypeDisplayValue: string;
    isOpen: boolean;
    startTime: string;
    endTime: string;
    is24Hrs: boolean;
  }[];
  cash_card_top_up: {
    isAvailable: boolean;
    no_of_points: number;
    details: string;
  };
  valet_service: {
    isAvailable: boolean;
    from: Date;
    to: Date;
    details: string;
  };
  motorcycle_lots: {
    isAvailable: boolean;
    no_of_lots: number;
    details: string;
  };
  payment_types: Array<string>;
  car_grooming_service: {
    isAvailable: boolean;
    from: Date;
    to: Date;
    details: string;
  };
  contact_details: {
    phone_no: number;
    email: number;
    details: string;
  };
  carpark_image_details: Array<any>;
}

export enum PublishStatus {
  SET_LOCATION = 'set_location',
  SET_ENTRANCES = 'set_entrances',
  SET_PRICES = 'set_prices',
  SET_FEATURES = 'set_features',
  PREVIEW = 'preview',
  VERIFYING = 'verifying',
  PUBLISHED = 'published',
  REJECTED = 'rejected',
  ARCHIVED = 'archived',
  IMPORTED = 'imported',
}

export enum DAYS {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  PUBLIC_HOLIDAY = 'PUBLIC_HOLIDAY',
  PUBLIC_HOLIDAY_EVE = 'PUBLIC_HOLIDAY_EVE',
}

export const CarparkKeyDisplayMap: {
  [param: string]: string;
} = {
  // charging types
  capped: 'Capped',
  sur_charge: 'Surcharge',
  max_charge: 'Max charge',

  // feature values
  open_air: 'Open air',
  sheltered: 'Sheltered Carpark',
  basement: 'Basement',
  surface: 'Surface',
  above_ground_level: 'Above ground level',
  full_ui: 'Full IU Detection',
  exit_card: 'Insert cash/ CashCard into kiosk for exit',
  exit_entry_card: 'Insert cash/ CashCard into kiosk for both exit and entry',
  limited: 'Limited',
  '24_hrs': '24 hrs',
  [DAYS.SUNDAY]: 'Sunday',
  [DAYS.MONDAY]: 'Monday',
  [DAYS.TUESDAY]: 'Tuesday',
  [DAYS.WEDNESDAY]: 'Wednesday',
  [DAYS.THURSDAY]: 'Thursday',
  [DAYS.FRIDAY]: 'Friday',
  [DAYS.SATURDAY]: 'Saturday',
  [DAYS.PUBLIC_HOLIDAY]: 'Public Holidays',
  [DAYS.PUBLIC_HOLIDAY_EVE]: 'Public Holiday eve',
};

export interface CarparkMapIcon {
  iconId: string;
  iconName: string;
  displayTitle: string;
  isOptional: boolean;
  iconUri?: string;
  iconBGColor?: string;
  iconSVG: string;
  iconStrokeColor?: string;
  iconBorderOpacity?: number;
  iconBorderColor?: string;
}

export interface CarparkDefaultLocationPin {
  isDefault: boolean;
  carparkId?: string; // only there for custom icon types created for a carpark
  iconId?: string;
  iconBGColor?: string;
  iconStrokeColor?: string;
  iconBorderOpacity?: number;
  iconBorderColor?: string;
  iconName?: string;
  displayValue: string;
  _id?: string;
}
type ReviewState = 'in-review' | 'pending' | 'resolved' | 'published';

export interface CarparkLocationMarkers extends CarparkDefaultLocationPin {
  markers: {
    id: number;
    lng: number;
    lat: number;
    reviewState?: ReviewState;
    revisionId: string | null;
    displayValue?: string;
    description?: string;
    deleted?: boolean;
  }[];
}
