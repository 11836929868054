<div class="carpark-entrence-component-wrapper">
  <section
    class="main-content"
    [class.nav-enabled]="cpSrv.isCarparkCreateNavEnabled"
  >
    <section class="main-content__map-area">
      <section
        class="main-content__map"
        id="ezpark-map"
        (dragover)="onDragOver($event)"
        (drop)="onDrop($event)"
      ></section>
      <section class="main-content__action-controls">
        <div class="main-footerblk">
          <button
            appConfirmProceed
            (confirm)="goBack($event)"
            [confirmProceedOptions]="backButtonOptions"
            [confirmationTriggerOn]="markersFormArray.dirty"
            type="button"
            class="bkbtn"
          >
            Back
          </button>
          <div class="box-btn-save">
            <ng-container *ngIf="isCreateMode">
              <button
                type="button"
                *ngIf="publishStatus"
                appConfirmProceed
                (confirm)="saveEntrance($event, 'saveExit')"
                [confirmProceedOptions]="cpSrv.SaveExitConfirmOptions"
                [confirmationTriggerOn]="false"
                class="save-exit-btn"
              >
                Save & Exit
              </button>
              <button
                type="submit"
                *ngIf="publishStatus"
                appConfirmProceed
                (confirm)="saveEntrance($event, 'continue')"
                [confirmProceedOptions]="confirmProceedOptions"
                [confirmationTriggerOn]="false"
              >
                Continue
              </button>
            </ng-container>
            <ng-container *ngIf="!isCreateMode">
              <button
                type="submit"
                *ngIf="publishStatus"
                appConfirmProceed
                (confirm)="saveEntrance($event, 'submitChanges')"
                [confirmProceedOptions]="confirmProceedOptions"
                [confirmationTriggerOn]="cpSrv.isSubmittedStatus(publishStatus)"
                [disabled]="!markersFormArray.dirty"
              >
                Submit Changes
              </button>
            </ng-container>
          </div>
        </div>
      </section>
    </section>
    <section class="main-content__sidebar">
      <ng-container *ngIf="!showRevisionMessage">
        <app-carpark-sidebar [visible]="true" [toggleEnabled]="false">
          <section class="sidebar-main">
            <h3>Location pins</h3>
            <ng-container
              *ngFor="let parentMarkerFG of markersFormArray.controls"
            >
              <section
                class="marker-group"
                *ngIf="isActiveMarkersPresent(parentMarkerFG.value)"
              >
                <app-generic-carpark-map-icon
                  [icon]="getMapPinById(parentMarkerFG.value.iconId)"
                  [scaleFactor]="1.3"
                ></app-generic-carpark-map-icon>
                <div class="marker-details">
                  <div class="header">
                    <p>{{ parentMarkerFG.value.displayValue }}</p>
                  </div>
                  <ng-container
                    *ngFor="
                      let marker of getAsFA(
                        getAsFG(parentMarkerFG).controls.markers
                      ).controls;
                      index as i
                    "
                  >
                    <div
                      [formGroup]="getAsFG(marker)"
                      class="marker-details-sub"
                    >
                      <div class="marker-details__inputs-section">
                        <!-- note: check scss code to hide border bottom behavior -->
                        <div
                          class="form-control"
                          [class.error]="
                            fUtils.getFromFGAsFC(
                              fUtils.getAsFG(marker),
                              'displayValue'
                            ).touched &&
                            fUtils.getFromFGAsFC(
                              fUtils.getAsFG(marker),
                              'displayValue'
                            ).errors
                          "
                        >
                          <input
                            type="text"
                            formControlName="displayValue"
                            placeholder="Enter name of point"
                            [id]="parentMarkerFG.value.iconId + '_marker_' + i"
                            class="title"
                            [maxlength]="LOCATIONPINNAME_MAXCOUNT"
                            [ngClass]="{
                              'border-bottom': !marker.value.displayValue
                            }"
                          />
                          <app-character-count
                            [count]="
                              LOCATIONPINNAME_MAXCOUNT -
                              (marker.value.displayValue?.length ?? 0)
                            "
                            [position]="{
                              top: '0',
                              bottom: 'initial'
                            }"
                          >
                          </app-character-count>
                          <span class="error-text">Enter name of point</span>
                        </div>
                        <input
                          type="text"
                          formControlName="description"
                          placeholder="Enter description (optional)"
                          class="description"
                          [ngClass]="{
                            'border-bottom': !marker.value.description
                          }"
                        />
                      </div>
                      <div class="marker-actions">
                        <button
                          class="revision-button revision-button--user"
                          (click)="
                            toggleRevisionMessageBlock(
                              true,
                              marker.value.revisionId
                            )
                          "
                          *ngIf="
                            marker.value.reviewState === 'pending' &&
                            !accountService.isAdminUser
                          "
                        >
                          <img
                            src="../../../../../assets/images/icons/comment-text.svg"
                          />
                        </button>
                        <i
                          class="pi pi-search-plus pi-icon--custom cursor-pointer"
                          title="find"
                          (click)="findMarker(parentMarkerFG.value.iconId, i)"
                        ></i>
                        <i
                          class="pi pi-times-circle pi-icon--custom cursor-pointer"
                          title="delete"
                          (click)="deleteMarker(parentMarkerFG.value.iconId, i)"
                        ></i>
                        <button
                          class="revision-button revision-button--admin image-popover"
                          (click)="
                            toggleRevisionMessageBlock(
                              true,
                              marker.value.revisionId,
                              marker.value.displayValue
                            )
                          "
                          *ngIf="
                            accountService.isAdminUser &&
                            marker.value.revisionId
                          "
                          [class.unresolved]="
                            marker.value.reviewState === 'pending'
                          "
                        >
                          <img
                            src="../../../../../assets/images/icons/revision-message-icon.png"
                          />
                          <app-help-popover
                            [helpTextArray]="['Add revision message']"
                          >
                          </app-help-popover>
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </section>
            </ng-container>
          </section>
          <section class="sidebar-footer">
            <p class="pinentry">
              Drag pins to their respective locations on the map
            </p>
            <!-- <p class="infoText">
              Please ensure that Entry and Exit pins should be on or near roads
            </p> -->
            <section class="action-icons-container">
              <div class="picpoint">
                <div
                  class="map-icon create-custom-icon"
                  (click)="openCustomPointModal()"
                >
                  <app-generic-carpark-map-icon
                    [draggable]="false"
                    [imgSrc]="'/assets/images/icons/create-custom-icon.svg'"
                  ></app-generic-carpark-map-icon>
                </div>
                <span>Create Custom Pin</span>
              </div>
              <div class="picpoint" *ngFor="let pin of defaultPins">
                <div
                  class="map-icon"
                  (dragstart)="onDragStart($event, pin.iconId!)"
                >
                  <app-generic-carpark-map-icon
                    [icon]="pin"
                    [draggable]="true"
                  ></app-generic-carpark-map-icon>
                </div>
                <span>{{ pin.displayValue }}</span>
              </div>
            </section>
            <p class="notefont">
              <em>
                Drag and drop the Entry, Exit, Pick-up, Taxi point, Drop point,
                etc. icons to the best exact location to direct drivers to their
                respective accurate locations.
              </em>
            </p>
          </section>
        </app-carpark-sidebar>
      </ng-container>
      <ng-container>
        <app-revision-messages-popup
          *ngIf="showRevisionMessage"
          (onToggleRevisionMessageBlock)="toggleRevisionMessageBlock($event)"
          header="Location pin revision messages"
          revisionParentType="carpark"
          [revisionParentId]="currentCarparkId || ''"
          revisionType="locationpin"
          [revisionDisplayValue]="revisionSelectedMarkerDisplayValue"
          [revisionBlockId]="revisionSelectedMarkerId"
          (onParentBlockReviewStateUpdate)="
            handleBlockReviewStateUpdate($event)
          "
        >
        </app-revision-messages-popup>
      </ng-container>
    </section>
  </section>
  <p-dialog
    [(visible)]="showCustomIconModal"
    styleClass="create-custom-pin-modal"
    ngClass="create-custom-pin-modal"
    [modal]="true"
    [closable]="false"
  >
    <ng-template pTemplate="header">
      <h2 class="modal-header">
        <span>Create a custom pin</span>
        <button (click)="cancelCreateCustomIcon()">x</button>
      </h2>
    </ng-template>
    <div class="modal-content">
      <div
        class="form-control"
        [class.error]="customPinNameFC.touched && customPinNameFC.errors"
      >
        <label for="">Name of custom pin</label>
        <input
          type="text"
          placeholder="Enter name of custom pin"
          [formControl]="customPinNameFC"
        />
        <div class="error-text">Enter name of custom pin</div>
      </div>
      <h3>Select color</h3>
      <div class="color-list">
        <div
          class="color-icon"
          *ngFor="let color of colorList"
          [ngStyle]="{
            background: color
          }"
          [ngClass]="{
            selected: color === selectedColor
          }"
          (click)="handleColorSelection(color)"
        ></div>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <div class="action-container">
        <button (click)="cancelCreateCustomIcon()" class="cancel-btn">
          Cancel
        </button>
        <!-- annoying issue with disabled attribute below (https://stackoverflow.com/questions/50130924/angular-disabled-myboolean-not-working) -->
        <button (click)="createCustomIcon()" class="create-btn">Create</button>
      </div>
    </ng-template>
  </p-dialog>
</div>
