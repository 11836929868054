<section class="sidebar-price-wrapper">
  <p-tabView styleClass="rates-preview-tabs" [activeIndex]="tabIndex || 0">
    <p-tabPanel
      *ngIf="normalRates?.cars && isOpenToPublic.value"
      [header]="'Car'"
    >
      <app-carpark-price-preview-tab
        [ratesDetails]="normalRates.cars!"
      ></app-carpark-price-preview-tab>
    </p-tabPanel>
    <p-tabPanel
      *ngIf="normalRates?.motorcycles && isOpenToPublic.value"
      [header]="'Motorcycle'"
    >
      <app-carpark-price-preview-tab
        [ratesDetails]="normalRates.motorcycles!"
      ></app-carpark-price-preview-tab>
    </p-tabPanel>
    <p-tabPanel *ngIf="normalRates?.loadingbay" [header]="'Loading Bay'">
      <app-carpark-price-preview-tab
        [ratesDetails]="normalRates.loadingbay!"
      ></app-carpark-price-preview-tab>
    </p-tabPanel>
    <p-tabPanel *ngIf="seasonalRates" [header]="'Season Parking'">
      <app-carpark-seasonal-details
        *ngIf="seasonalRates?.cars?.value?.isEnabled"
        [seasonDetailsFG]="seasonalRates.cars"
        [header]="'Car'"
      >
      </app-carpark-seasonal-details>
      <app-carpark-seasonal-details
        *ngIf="seasonalRates?.motorcycles?.value?.isEnabled"
        [seasonDetailsFG]="seasonalRates.motorcycles"
        [header]="'Motorcycle'"
      >
      </app-carpark-seasonal-details>
      <app-carpark-seasonal-details
        *ngIf="seasonalRates?.loadingbay?.value?.isEnabled"
        [seasonDetailsFG]="seasonalRates.loadingbay"
        [header]="'Loading Bay'"
      >
      </app-carpark-seasonal-details>
      <section
        class="seasonal-details-contact"
        *ngIf="seasonalRates.contactEmail.value"
      >
        <h4>Contact</h4>
        <span>{{ seasonalRates.contactEmail.value }}</span>
      </section>
    </p-tabPanel>
  </p-tabView>
</section>
