import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent implements OnInit, AfterViewInit, OnDestroy {
  selectedPageSection: any = null;
  pageSections = [
    // { hash: 'introduction', name: 'Introduction', selected: true },
    { hash: 'login', name: '1. Login', selected: true },
    { hash: 'account-creation', name: '2. Account Creation', selected: true },
    {
      hash: 'add-parking-facility',
      name: '3. Add Parking Facility',
      selected: false,
    },
    { hash: 'pin-location', name: '4. Pin Location', selected: false },
    { hash: 'dashboard', name: '5. Dashboard', selected: false },
    { hash: 'parking-details', name: '6. Parking Details', selected: false },
    { hash: 'parking-charges', name: '7. Parking Charges', selected: false },
    {
      hash: 'carpark-amenities',
      name: '8. Carpark Amenities',
      selected: false,
    },
    { hash: 'carpark-list', name: '9. Carpark List', selected: false },
    {
      hash: 'broadcast-messages',
      name: '10. Broadcast Messages',
      selected: false,
    },
    {
      hash: 'availability-integration',
      name: '11. Availability Integration',
      selected: false,
    },
    { hash: 'managing-account', name: '12. Managing Account', selected: false },
    {
      hash: 'frequently-asked-question',
      name: '13. Frequently Asked Question',
      selected: false,
    },
  ];

  @ViewChildren('accordionHeader') accordionHeaders: QueryList<ElementRef>;
  scrollSubscription: Subscription;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.navigateToSection();
  }

  ngAfterViewInit(): void {
    this.attachScrollListener();
  }

  ngOnDestroy(): void {
    this.scrollSubscription.unsubscribe();
  }

  attachScrollListener() {
    this.scrollSubscription = fromEvent(document, 'scroll').subscribe(
      (event) => {
        let i = 0;
        for (let e of this.accordionHeaders) {
          const headerEle = e.nativeElement;
          if (this.isInViewport(headerEle)) {
            this.selectedPageSection = this.pageSections[i];
            break;
          }
          i++;
        }
      }
    );
  }

  navigateToSection() {
    const hash = this.activatedRoute.snapshot.fragment;
    if (!hash) return;
    // remove default highlighted sections
    this.pageSections.forEach((e) => (e.selected = false));
    // mock event object to trigger sectionChange function
    const mockEventObj = {
      value: { hash },
    };
    this.sectionChange(mockEventObj);
  }

  sectionChange(event: any) {
    const hash = event?.value?.hash;
    this.router.navigate(['/how-to'], { fragment: hash });
    if (!hash) return;
    setTimeout(() => {
      document.querySelector(`#${hash}`)?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }, 100);

    const pageSection = this.pageSections.find((e) => hash == e.hash);
    if (!pageSection) return;
    pageSection.selected = true;
  }

  getPageSection(hash: string) {
    return this.pageSections.find((e) => e.hash == hash);
  }

  isInViewport(element: HTMLElement) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  onAccordionOpen(event: any) {
    this.pageSections[event.index].selected = true;
    const hash = this.pageSections[event.index].hash;
    this.router.navigate(['/how-to'], {
      fragment: hash,
    });
  }

  onAccordionClose(event: any) {
    this.pageSections[event.index].selected = false;
  }
}

@Component({
  selector: 'custom-support-img',
  template: `
    <div class="image-container">
      <p-image
        [src]="src"
        [alt]="alt"
        [styleClass]="'support-image-container'"
        [imageClass]="'support-image'"
        [preview]="preview"
      ></p-image>
      <label for="">{{ label }}</label>
    </div>
  `,
  styles: [
    `
      p-image {
        text-align: center;
        width: 100%;
      }
      .image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2vmin;
      }
    `,
  ],
})
export class CustomSupportComponent {
  @Input('src') src: string;
  @Input('preview') preview = true;
  @Input('alt') alt = 'Image';
  @Input('label') label = 'Image';

  constructor() {}
}
