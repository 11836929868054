<div
  class="wrapper"
  [ngStyle]="{ top: posTop, bottom: posBottom, left: posLeft, right: posRight }"
>
  <div class="character-count-text {{ styleClass || '' }}">
    <span *ngIf="prefix">{{ prefix }}</span>
    <span>{{ count }}</span>
    <span *ngIf="suffix">{{ suffix }}</span>
  </div>
</div>
