import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface CustomConfirmation {
  header?: string;
  message?: string[];
  key?: string;
  accept?: Function;
  reject?: Function;
  close?: Function;
  acceptLabel?: string;
  rejectLabel?: string;
  acceptVisible?: boolean;
  rejectVisible?: boolean;
  closeVisible?: boolean;
  dismissableMask?: boolean;
  btnReversed?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CustomConfirmService {
  private requireConfirmationSource = new Subject<CustomConfirmation>();
  requireConfirmation$ = this.requireConfirmationSource.asObservable();

  constructor() {}

  confirm(confirm: CustomConfirmation) {
    this.requireConfirmationSource.next(confirm);
  }
}
