<p-dialog
  [(visible)]="showModal"
  styleClass="generic-confirm-modal"
  [modal]="true"
  [closable]="false"
>
  <div class="generic-confirm-modal__body">

    <section class="header">
    
      <img
      src="assets/images/icons/check-green.svg"
      alt=""
      class="check-green"
      title="Play"
    />
      <i class="pi pi-plus icon-close " (click)="onCancel()"></i>
    </section>
      <h3 class="header">{{ header }}</h3>
    <section class="generic-confirm-modal__content">
      <p [innerHTML]="description"></p>
    </section>
    <section class="actions">
      <button class="generic-confirm-modal__confirm-btn" (click)="onConfirm()">
        {{ confirmText }}
      </button>
    </section>
  </div>
</p-dialog>
