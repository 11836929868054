import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { areIntervalsOverlapping } from 'date-fns';
import { SubmitTypes } from '../carpark/carpark-create-main/carpark-location/carpark-location.component';
import { SaveExitConfirmOptions } from '../carpark/carpark-create-main/carpark-preview-web/carpark-preview-web.component';
import { GracePeriodOptions } from '../carpark/carpark-create-main/carpark-price/carpark-price-entry/carpark-price-entry.component';
import { getFormattedDays } from '../carpark/carpark-create-main/carpark-price/carpark-price-preview/carpark-price-preview.component';
import { CombinedRatesDetails } from '../carpark/carpark-create-main/carpark-price/carpark-price.component';
import {
  OverlapError,
  OverlapErrorTypes,
} from '../carpark/carpark-create-main/carpark-price/carpark-validation-popup/carpark-validation-popup.component';
import {
  ChargingType,
  ClosureStatus,
  DAYS,
  Parking,
  ParkingRate,
  PricingSlot,
  PublishStatus,
  SeasonalDetails,
  VehicleTypes,
} from '../carpark/carpark-create-main/carpark.model';
import { CarparkStatusAttributesMap } from '../dashboard/new-carpark-list/new-carpark-list.component';
import { ConfirmationStatus } from '../shared/directives/confirm-proceed.directive';
import { AccountService } from './account.service';
import {
  LS_IS_CAR_CREATE_MODE,
  LocalStorageService,
} from './local-storage.service';

export enum CarparkCreationPages {
  LOCATION = 'LOCATION',
  ENTRANCES = 'ENTRANCES',
  PRICES = 'PRICES',
  FEATURES = 'FEATURES',
  PREVIEW = 'PREVIEW',
}

export const InprogressStatuses = [
  PublishStatus.SET_LOCATION,
  PublishStatus.SET_ENTRANCES,
  PublishStatus.SET_PRICES,
  PublishStatus.SET_FEATURES,
  PublishStatus.PREVIEW,
];
export const SubmittedStatuses = [
  PublishStatus.PUBLISHED,
  PublishStatus.VERIFYING,
  PublishStatus.REJECTED,
];

export const BackButtonConfigMap = {
  [CarparkCreationPages.LOCATION]: '/dashboard',
  [CarparkCreationPages.ENTRANCES]: '/carpark/create/location',
  [CarparkCreationPages.PRICES]: '/carpark/create/location?configureEntrance=1',
  [CarparkCreationPages.FEATURES]: '/carpark/create/prices',
  [CarparkCreationPages.PREVIEW]: '/carpark/create/features',
};

@Injectable({
  providedIn: 'root',
})
export class CarparkService {
  readonly SaveExitConfirmOptions = SaveExitConfirmOptions;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private lsSrv: LocalStorageService,
    private accountService: AccountService
  ) {}

  get isCarparkCreateNavEnabled() {
    return this.lsSrv.getItem(LS_IS_CAR_CREATE_MODE) === '1';
  }

  makeSeasonalDetailsFG(seasonalDetail: SeasonalDetails) {
    return this.fb.group({
      isEnabled: [seasonalDetail?.isEnabled || false],
      duration: [seasonalDetail?.duration || '1'],
      unit: [seasonalDetail?.unit || 'M'],
      charges: [seasonalDetail?.charges],
      durationDetails: [seasonalDetail?.durationDetails],
      remarks: [seasonalDetail?.remarks],
      revisionId: [seasonalDetail?.revisionId || null],
      reviewState: [seasonalDetail?.reviewState],
    });
  }

  handlePerMinuteDisplayValueChanges(pricingSlotFG: FormGroup) {
    const isPerMinEnabled = pricingSlotFG.get('per_minute_enabled')?.value;
    const displayChargeRate = pricingSlotFG.get('display_charge_rate')?.value!;
    const displayChargeDuration = pricingSlotFG.get('display_charge_duration')
      ?.value!;

    if (isPerMinEnabled) {
      pricingSlotFG.patchValue({
        min_rate: (displayChargeRate / displayChargeDuration).toFixed(3),
        min_duration: 1,
      });
    } else {
      pricingSlotFG.patchValue({
        min_rate: displayChargeRate,
        min_duration: displayChargeDuration,
      });
    }
  }

  generatePricingSlotFG(pricingSlot: PricingSlot) {
    let pricingSlotFG: FormGroup;
    pricingSlotFG = this.fb.group({
      ...pricingSlot,
      start_time: [pricingSlot.start_time, [Validators.required]],
      end_time: [pricingSlot.end_time, [Validators.required]],
      charging_type: [pricingSlot.charging_type, [Validators.required]],
    });
    switch (pricingSlot.charging_type) {
      case ChargingType.FREE:
        break;
      case ChargingType.MINUTE:
        pricingSlotFG.setControl(
          'per_minute_enabled',
          this.fb.control(pricingSlot.per_minute_enabled || false)
        );
        pricingSlotFG.setControl(
          'display_charge_rate',
          this.fb.control(
            pricingSlot.display_charge_rate || pricingSlot.min_rate,
            [Validators.required, Validators.min(0)]
          )
        );
        pricingSlotFG.setControl(
          'display_charge_duration',
          this.fb.control(
            pricingSlot.display_charge_duration || pricingSlot.min_duration,
            [Validators.required, Validators.min(0)]
          )
        );
        pricingSlotFG.setControl(
          'min_rate',
          this.fb.control(pricingSlot.min_rate, [
            Validators.required,
            Validators.min(0),
          ])
        );
        pricingSlotFG.setControl(
          'min_duration',
          this.fb.control(pricingSlot.min_duration, [
            Validators.required,
            Validators.min(0),
          ])
        );

        pricingSlotFG
          .get('display_charge_rate')
          ?.valueChanges.subscribe(
            this.handlePerMinuteDisplayValueChanges.bind(null, pricingSlotFG)
          );
        pricingSlotFG
          .get('display_charge_duration')
          ?.valueChanges.subscribe(
            this.handlePerMinuteDisplayValueChanges.bind(null, pricingSlotFG)
          );
        pricingSlotFG
          .get('per_minute_enabled')
          ?.valueChanges.subscribe(
            this.handlePerMinuteDisplayValueChanges.bind(null, pricingSlotFG)
          );
        pricingSlotFG.get('min_rate')?.disable();
        break;

      case ChargingType.PER_ENTRY:
        pricingSlotFG = this.fb.group({
          start_time: [pricingSlot.start_time, [Validators.required]],
          end_time: [pricingSlot.end_time, [Validators.required]],
          charging_type: [ChargingType.PER_ENTRY, [Validators.required]],
          entry_price: [
            pricingSlot.entry_price,
            [Validators.required, Validators.min(0)],
          ],
        });

        pricingSlotFG.setControl('slot_variables', this.fb.array([]));
        break;

      case ChargingType.SUBSEQUENT_CHARGE:
        const slotVariableFA = this.fb.array(
          pricingSlot.slot_variables?.map((slots) =>
            this.fb.group({
              pricing_order: [slots.pricing_order],
              type: [slots.type],
              min_duration: [
                slots.min_duration,
                [Validators.required, Validators.min(0)],
              ],
              min_rate: [
                slots.min_rate,
                [Validators.required, Validators.min(0)],
              ],
            })
          ) || []
        );
        pricingSlotFG.setControl('slot_variables', slotVariableFA);
        break;
    }
    pricingSlotFG.addControl(
      'grace_period',
      this.fb.control(pricingSlot.grace_period || 0)
    );
    pricingSlotFG.get('grace_period')?.addValidators(Validators.required);
    pricingSlotFG.addControl(
      'grace_period_type',
      this.fb.control(this.getGracePeriodType(pricingSlot.grace_period))
    );
    pricingSlotFG.addControl('remarks', this.fb.control(pricingSlot.remarks));
    return pricingSlotFG;
  }

  makeRatesFA(parkingRates?: ParkingRate[]) {
    if (!parkingRates) return this.fb.array([]);

    const parkingRatesFA = this.fb.array([]);
    parkingRates.forEach((rate) => {
      const pricingSlotFA = this.fb.array(
        rate.pricing_slots?.map(this.generatePricingSlotFG.bind(this)) || []
      );

      const rateFG = this.fb.group({
        day: [rate.day],
        pricing_slots: pricingSlotFA,
        revisionId: [rate.revisionId || null],
        formattedDays: [rate.formattedDays],
        deleted: [rate.deleted],
        reviewState: [rate.reviewState],
        cappedCharge: this.fb.group({
          type: [rate.cappedCharge?.type || 'none'],
          ...(rate.cappedCharge?.type &&
            rate.cappedCharge.type !== 'none' && {
              slots: this.fb.array(
                rate.cappedCharge?.slots?.map((slot) =>
                  this.fb.group({
                    ...(slot.start && {
                      start: [slot.start, [Validators.required]],
                    }),
                    ...(slot.start && {
                      end: [slot.end, [Validators.required]],
                    }),
                    charge: [
                      slot.charge,
                      [Validators.required, Validators.min(0)],
                    ],
                  })
                ) || []
              ),
            }),
        }),
      });
      parkingRatesFA.push(rateFG);
    });

    return parkingRatesFA;
  }

  getGracePeriodType(gracePeriod?: number) {
    const selectedGracePeriodType = GracePeriodOptions.find(
      (gracePeriodOption) => gracePeriodOption.value == gracePeriod
    )?.value;
    return isNaN(<number>selectedGracePeriodType)
      ? 'other'
      : selectedGracePeriodType;
  }

  makeCombinedRatesDetailsFormObj(
    parkingDetails: Parking
  ): CombinedRatesDetails {
    return {
      normalRates: {
        [VehicleTypes.CARS]: {
          ratesFA: this.makeRatesFA(parkingDetails?.rates?.[VehicleTypes.CARS]),
          remarks: this.fb.control(
            parkingDetails.info?.[VehicleTypes.CARS]?.remarks
          ),
          url: this.fb.control(parkingDetails.info?.[VehicleTypes.CARS]?.url),
        },
        [VehicleTypes.MOTORCYCLES]: {
          ratesFA: this.makeRatesFA(
            parkingDetails?.rates?.[VehicleTypes.MOTORCYCLES]
          ),
          remarks: this.fb.control(
            parkingDetails.info?.[VehicleTypes.MOTORCYCLES]?.remarks
          ),
          url: this.fb.control(
            parkingDetails.info?.[VehicleTypes.MOTORCYCLES]?.url
          ),
        },
        [VehicleTypes.LOADING_BAY]: {
          ratesFA: this.makeRatesFA(
            parkingDetails?.rates?.[VehicleTypes.LOADING_BAY]
          ),
          remarks: this.fb.control(
            parkingDetails.info?.[VehicleTypes.LOADING_BAY]?.remarks
          ),
          url: this.fb.control(
            parkingDetails.info?.[VehicleTypes.LOADING_BAY]?.url
          ),
        },
      },
      seasonalRates: {
        [VehicleTypes.CARS]: this.makeSeasonalDetailsFG(
          parkingDetails.seasonParking?.cars
        ),
        [VehicleTypes.MOTORCYCLES]: this.makeSeasonalDetailsFG(
          parkingDetails.seasonParking?.motorcycles
        ),
        [VehicleTypes.LOADING_BAY]: this.makeSeasonalDetailsFG(
          parkingDetails.seasonParking?.loadingbay
        ),
        contactEmail: this.fb.control(
          parkingDetails.seasonParking?.contactEmail
        ),
      },
    };
  }

  getRatesForBE(rates: any[]) {
    const ratesForBE = JSON.parse(JSON.stringify(rates));
    // mutating ratesForBE below
    for (const rate of ratesForBE) {
      for (let i = 0; i < rate.pricing_slots?.length; ++i) {
        const pricingSlot = rate.pricing_slots[i];

        if (!pricingSlot.slot_variables) {
          pricingSlot.slot_variables = [];
          continue;
        }
        if (
          pricingSlot.type == 'repeat' &&
          pricingSlot.slot_variables?.length
        ) {
          const {
            // following fields are to be removed from root level when slot variables are present
            entry_price,
            max_duration,
            min_duration,
            min_rate,
            pricing_order,
            type,
            ...newPricingSlotDetails
          } = pricingSlot;
          newPricingSlotDetails.slot_variables.unshift({
            entry_price,
            max_duration,
            min_duration,
            min_rate,
            pricing_order,
            type,
          });
          newPricingSlotDetails.slot_variables.forEach(
            (e: any, i: number) => (e.pricing_order = i + 1)
          );
          rate.pricing_slots[i] = newPricingSlotDetails;
        }
      }
    }
    return ratesForBE;
  }

  // common util function for carpark vehicle prices rate validation
  checkRateAreValid(
    rates: any,
    vehicleKey: OverlapErrorTypes
  ):
    | { isValid: true }
    | { isValid: false; message: string }
    | { isValid: false; overlapErrors: OverlapError } {
    const overLappedDays = [];

    for (let i = 0; i < rates.length; ++i) {
      const extraStartEndTimeArray: any[] = []; // for storing copy values of slots generated with previous day slot
      const startEndTimeArray = rates[i].pricing_slots.map((slot: any) => {
        const startTime = new Date(1970, 0, 1);
        const [startTimeHours, startTimeMins] = slot.start_time?.split(':');
        startTime.setHours(startTimeHours);
        startTime.setMinutes(startTimeMins);
        const endTime = new Date(1970, 0, 1);
        const [endTimeHours, endTimeMins] = slot.end_time?.split(':');
        endTime.setHours(endTimeHours);
        endTime.setMinutes(endTimeMins);
        if (endTime <= startTime) {
          // create previous day timeslots for a thorough check of overlapping
          const prevDaySlotStartTime = new Date(startTime);
          prevDaySlotStartTime.setDate(startTime.getDate() - 1);
          const prevDaySlotEndTime = new Date(endTime);
          extraStartEndTimeArray.push({
            startTime: prevDaySlotStartTime,
            endTime: prevDaySlotEndTime,
          });
          endTime.setDate(endTime.getDate() + 1);
        }
        return {
          startTime,
          endTime,
        };
      });
      const fullStartEndTimeArray = [
        ...startEndTimeArray,
        ...extraStartEndTimeArray,
      ];

      let isOverlapping = false;
      for (let j1 = 0; j1 < fullStartEndTimeArray.length; ++j1) {
        for (let j2 = j1 + 1; j2 < fullStartEndTimeArray.length; ++j2) {
          isOverlapping = areIntervalsOverlapping(
            {
              start: fullStartEndTimeArray[j1].startTime,
              end: fullStartEndTimeArray[j1].endTime,
            },
            {
              start: fullStartEndTimeArray[j2].startTime,
              end: fullStartEndTimeArray[j2].endTime,
            }
          );
          if (isOverlapping) break;
        }
        if (isOverlapping) break;
      }

      if (isOverlapping) {
        overLappedDays.push(rates[i].day);
      }
    }

    if (overLappedDays.length)
      return {
        isValid: false,
        overlapErrors: {
          overlappedDays: overLappedDays.map(getFormattedDays),
          type: vehicleKey,
        },
      };

    return {
      isValid: true,
    };
  }

  handleErrorInValidation: (e: any) => { isValid: false; message: string } = (
    error: any
  ) => {
    console.error('error in validation function ', error);
    if (
      error.message.includes('getHours') ||
      error.message.includes('setHours')
    )
      return {
        isValid: false,
        message: 'Please select a valid time slot',
      };
    return {
      isValid: false,
      message: error.message,
    };
  };

  getCarparkNames(carparks: string[]) {
    // todo: IMPORTANT: names of carparks are not displayed now --ys
    let slicedCarparks = carparks || [];
    let formattedCarparkIds = '';
    if (carparks?.length > 2) {
      slicedCarparks = carparks.slice(0, 2);
      formattedCarparkIds = slicedCarparks.join(', ') + '...';
    } else {
      formattedCarparkIds = slicedCarparks.join(', ') || '';
    }

    return formattedCarparkIds;
  }

  handleBackButtonClick(
    currentPage: CarparkCreationPages,
    isCreateMode?: boolean
  ) {
    isCreateMode ??= this.lsSrv.getItem(LS_IS_CAR_CREATE_MODE) === '1';
    if (isCreateMode)
      this.router.navigateByUrl(BackButtonConfigMap[currentPage]);
    else {
      if (currentPage === CarparkCreationPages.PREVIEW)
        this.router.navigateByUrl('/dashboard');
      else this.router.navigateByUrl('/carpark/create/preview?edit=1');
    }
  }

  handleSaveExitClick(confirmStatus: ConfirmationStatus) {
    if (
      confirmStatus !== ConfirmationStatus.CONFIRM_PROCEED &&
      confirmStatus !== ConfirmationStatus.PROCEED
    )
      return;
    this.router.navigateByUrl('/dashboard');
  }

  getNewPublishStatus(
    currentPublishStatus: PublishStatus,
    currentPage: CarparkCreationPages,
    submitType: SubmitTypes = 'continue'
  ) {
    let newPublishStatus = currentPublishStatus; // if saveExit then maintain current publishStatus
    if (submitType === 'submitChanges') {
      newPublishStatus = PublishStatus.VERIFYING;
    } else if (submitType === 'continue') {
      newPublishStatus =
        {
          [CarparkCreationPages.LOCATION]: PublishStatus.SET_ENTRANCES,
          [CarparkCreationPages.ENTRANCES]: PublishStatus.SET_PRICES,
          [CarparkCreationPages.PRICES]: PublishStatus.SET_FEATURES,
          [CarparkCreationPages.FEATURES]: PublishStatus.PREVIEW,
          [CarparkCreationPages.PREVIEW]: PublishStatus.VERIFYING,
        }[currentPage] ?? PublishStatus.VERIFYING;
    }
    return newPublishStatus;
  }

  formatDay(day: any) {
    return day[0].toUpperCase() + day.slice(1).toLowerCase();
  }

  getDaysRange(slotDays: DAYS[]) {
    const publicHolidayKey = DAYS.PUBLIC_HOLIDAY;
    const publicHolidayEveKey = DAYS.PUBLIC_HOLIDAY_EVE;
    const weekdaysObj: any = {
      [DAYS.MONDAY]: 0,
      [DAYS.TUESDAY]: 1,
      [DAYS.WEDNESDAY]: 2,
      [DAYS.THURSDAY]: 3,
      [DAYS.FRIDAY]: 4,
      [DAYS.SATURDAY]: 5,
      [DAYS.SUNDAY]: -1,
    };

    let range = '',
      daysRangeStr = '';
    const phs = [];
    const days = [...slotDays];
    const dayIndex = days.indexOf(publicHolidayKey);
    if (dayIndex > -1) {
      phs.push('Public Holiday');
      days.splice(dayIndex, 1);
    }

    let eveIndex = days.indexOf(publicHolidayEveKey);
    if (eveIndex > -1) {
      phs.push('Public Holiday Eve');
      days.splice(eveIndex, 1);
    }

    if (days.length > 0) {
      let daysArr = days.map((d) => {
        return {
          day: d,
          formattedDay: this.formatDay(d),
          order: weekdaysObj[d],
        };
      });
      daysArr.sort((a, b) => a.order - b.order);

      //console.log(daysArr);
      let daysRange = [],
        curRange = [],
        prev;
      do {
        let first = daysArr.shift();
        if (curRange.length === 0) {
          curRange.push(first);
        } else {
          if (first?.order - curRange[curRange.length - 1]?.order > 1) {
            if (curRange.length === 1) {
              daysRange.push(curRange[0]?.formattedDay);
            } else if (curRange.length === 2) {
              daysRange.push(curRange[0]?.formattedDay);
              daysRange.push(curRange[1]?.formattedDay);
            } else {
              daysRange.push(
                `${curRange[0]?.formattedDay} - ${
                  curRange[curRange.length - 1]?.formattedDay
                }`
              );
            }
            curRange = [];
          }
          curRange.push(first);
        }
      } while (daysArr.length > 0);
      if (curRange.length > 0) {
        if (curRange.length === 1) {
          daysRange.push(curRange[0]?.formattedDay);
        } else if (curRange.length === 2) {
          daysRange.push(curRange[0]?.formattedDay);
          daysRange.push(curRange[1]?.formattedDay);
        } else {
          daysRange.push(
            `${curRange[0]?.formattedDay} - ${
              curRange[curRange.length - 1]?.formattedDay
            }`
          );
        }
      }
      //daysRange.push(curRange.join(" - "));
      //console.log(daysRange);

      daysRangeStr = daysRange.length > 0 ? daysRange.join(', ') : '';
    }

    // console.log(phs);
    if (daysRangeStr && phs.length > 0) {
      range =
        daysRangeStr + (phs.length > 1 ? ', ' : ' and ') + phs.join(' and ');
    } else if (daysRangeStr) {
      range = daysRangeStr;
    } else if (phs.length > 0) {
      range = phs.join(' and ');
    }

    return range;
  }

  isSubmittedStatus(publishStatus: PublishStatus) {
    return SubmittedStatuses.includes(publishStatus);
  }

  isInProgressStatus(publishStatus: PublishStatus) {
    return InprogressStatuses.includes(publishStatus);
  }

  getCarparkStatusAttributes(status: PublishStatus = PublishStatus.IMPORTED) {
    if (this.accountService.isAdminUser) {
      if (status === PublishStatus.VERIFYING) {
        return {
          ...CarparkStatusAttributesMap[PublishStatus.REJECTED],
          displayText: 'Reviewing',
        };
      } else if (status === PublishStatus.REJECTED) {
        return {
          ...CarparkStatusAttributesMap[PublishStatus.IMPORTED],
          displayText: 'Rejected',
        };
      }
    }
    return CarparkStatusAttributesMap[status];
  }

  isDeleteEnabled(publishStatus: PublishStatus) {
    return InprogressStatuses.includes(publishStatus);
  }

  getCarparkClosureStatusText(
    closureStatus?: ClosureStatus,
    minimized = false
  ) {
    if (!closureStatus) return '';
    return (
      {
        [ClosureStatus.PERMANENTLY_CLOSED]: 'Permanently closed',
        [ClosureStatus.TEMPORARILY_CLOSED]: 'Temporarily closed',
        [ClosureStatus.SCHEDULED_TO_PERMANENTLY_CLOSE]: `Scheduled to ${
          !minimized ? 'permanently ' : ''
        }close`,
        [ClosureStatus.SCHEDULED_TO_TEMPORARILY_CLOSE]: `Scheduled to ${
          !minimized ? 'temporarily ' : ''
        }close`,
      }[closureStatus] || ''
    );
  }
}
