import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { CarparkDefaultLocationPin } from '../carpark-create-main/carpark.model';
import { svgTemplateMap } from './svgTemplateMap';

@Component({
  selector: 'app-generic-carpark-map-icon',
  templateUrl: './generic-carpark-map-icon.component.html',
  styleUrls: ['./generic-carpark-map-icon.component.scss'],
})
export class GenericCarparkMapIconComponent implements OnInit, AfterViewInit {
  @Input('icon') iconDetails: CarparkDefaultLocationPin;
  @Input('bgColor') iconBGColor: string | undefined;
  @Input('strokeColor') iconStrokeColor: string | undefined;
  @Input('borderColor') iconBorderColor: string | undefined;
  @Input('borderOpacity') iconBorderOpacity: number | undefined;
  @Input('scaleFactor') scaleFactor = 1;
  @Input('draggable') draggable = false;
  @ViewChild('svgContainer') svgContainer: ElementRef;
  @Input('imgSrc') imgSrc = '';

  iconSVG: string;
  constructor(private ref: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.imgSrc) return;
    // if imgSrc is provided, using that instead of iconDetails
    this.generateIcon();
  }

  generateIcon() {
    if (!this.iconDetails) return;
    const iconBGColor = this.iconBGColor || this.iconDetails.iconBGColor || undefined;
    const iconStrokeColor =
      this.iconStrokeColor || this.iconDetails.iconStrokeColor || undefined;
    const iconBorderColor =
      this.iconBorderColor || this.iconDetails.iconBorderColor || undefined;
    const iconBorderOpacity =
      this.iconBorderOpacity || this.iconDetails.iconBorderOpacity || undefined;
    const svgTemplate =
      (this.iconDetails.iconName &&
        svgTemplateMap[this.iconDetails.iconName]) ||
      svgTemplateMap._customIcon;

    const convertedSVG = makeConvertedSVG(svgTemplate, {
      iconBGColor,
      iconBorderColor,
      iconBorderOpacity,
      iconStrokeColor,
    });
    this.svgContainer.nativeElement.innerHTML = convertedSVG;
  }
}
export interface MarkerIconConfigObj {
  iconBGColor?: string;
  iconBorderColor?: string;
  iconBorderOpacity?: number;
  iconStrokeColor?: string;
  iconId?: string; // todo: make logic to create custom marker id..
  [param: string]: any;
}

// util function
export const makeConvertedSVG = (
  svg: string,
  svgConfigObj: MarkerIconConfigObj = {}
) => {
  const { iconBGColor, iconBorderColor, iconBorderOpacity, iconStrokeColor } =
    svgConfigObj;
  let convertedSVG = svg;

  if (iconBGColor)
    convertedSVG = convertedSVG?.replaceAll('{icon-bgColor}', iconBGColor);
  if (iconBorderColor)
    convertedSVG = convertedSVG?.replaceAll(
      '{icon-borderColor}',
      iconBorderColor
    );
  if (iconBorderOpacity)
    convertedSVG = convertedSVG?.replaceAll(
      '{icon-borderOpacity}',
      iconBorderOpacity.toString()
    );
  if (iconStrokeColor)
    convertedSVG = convertedSVG?.replaceAll(
      '{icon-strokeColor}',
      iconStrokeColor
    );
  return convertedSVG;
};
