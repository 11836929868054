import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-popover',
  templateUrl: './help-popover.component.html',
  styleUrls: ['./help-popover.component.scss'],
})
export class HelpPopoverComponent implements OnInit {
  @Input('helpTextArray') helpText: string[];
  @Input('displayType') displayType: 'title-display' | 'default' = 'default';
  @Input('styleClass') styleClass = '';
  constructor() {}

  ngOnInit(): void {}
}
