import { Component, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastService } from './toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {
  subscription: Subscription;
  @Input('isShow')
  isShow = false;
  @Input('title')
  title = 'Notification';
  @Input('content')
  content = '';

  constructor(private toastService: ToastService) {
    this.subscription = this.toastService.requireConfirmation$.subscribe(
      this.initConfirmValues.bind(this)
    );
  }

  initConfirmValues() {
    this.isShow = true;
    setTimeout(() => {
      this.isShow = false;
    }, 3000);
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
