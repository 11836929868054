import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-nocontent',
  templateUrl: './table-nocontent.component.html',
  styleUrls: ['./table-nocontent.component.scss'],
})
export class TableNocontentComponent implements OnInit {
  @Input('noContentText') noContentText: string | null = 'No search results';
  constructor() {}
  ngOnInit(): void {}
}
