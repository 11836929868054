import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-header-wrapper',
  templateUrl: './table-header-wrapper.component.html',
  styleUrls: ['./table-header-wrapper.component.scss']
})
export class TableHeaderWrapperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
