import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AccountService } from '../../../services/account.service';
import { EventService } from '../../../services/event.service';

@Component({
  selector: 'app-login-popup',
  templateUrl: './login-popup.component.html',
  styleUrls: ['./login-popup.component.scss'],
})
export class LoginPopupComponent implements OnInit {
  loginForm: FormGroup;
  loginErrors: any = {};
  isPasswordVisible = false;
  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private evtSvc: EventService
  ) {
    this.loginForm = this.fb.group({
      email: this.fb.control(['']),
      password: this.fb.control(['']),
    });
  }

  ngOnInit(): void {}
  async login(submitEvent?: any) {
    const { email, password } = this.loginForm.value;
    // add loader
    this.evtSvc.emit({
      name: 'add-loader',
    });

    const { isLoggedIn, isNewPassRequired } = await this.accountService.login(
      email,
      password
    );

    // remove loader
    this.evtSvc.emit({
      name: 'remove-loader',
    });

    if (!isLoggedIn) {
      this.loginErrors = {
        invalidCredentials: true,
      };
      return;
    }
    return this.ref.close(true);
  }

  cancel() {
    return this.ref.close(false);
  }
}
